import { ComponentType, useEffect, useMemo, ChangeEvent } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Card, Radio, Text, Cell } from '@hh.ru/magritte-ui';

import { PreferredContact as PreferredContactType } from 'src/models/applicant/resume/blocks/contacts/types';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';

const FIELD_NAME = 'preferredContact';

const TrlKeys = {
    text: 'resume.editor.contacts.preferredContact',
};

type Props = {
    value: string;
    fieldName: string;
};

const PreferredContact: ComponentType<Props> = translation(({ trls, value, fieldName }) => {
    const { value: preferredContact, onChange } = useResumeEditorField(FIELD_NAME);

    const handleRadioChanged = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value as PreferredContactType);
    };

    const fieldValue = useMemo(() => value.trim(), [value]);

    useEffect(() => {
        if (fieldValue === '') {
            onChange(PreferredContactType.CellPhone);
        }
    }, [fieldValue, onChange]);

    return (
        <Card borderRadius={12} padding={12} stretched showBorder>
            <Cell
                left={
                    <Radio
                        value={fieldName}
                        checked={preferredContact === fieldName}
                        onChange={handleRadioChanged}
                        disabled={!fieldValue}
                    />
                }
            >
                <Text typography="label-2-regular">{trls[TrlKeys.text]}</Text>
            </Cell>
        </Card>
    );
});

export default PreferredContact;
