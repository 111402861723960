import { DefaultRootState } from 'react-redux';

import { selectApplicantResumeRecommendation } from 'src/models/applicant/resume/blocks/recommendation/selectors';
import { NEW_ID } from 'src/models/applicant/resume/editor/lib';
import { selectResumeEditorPayload } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Recommendation } from 'src/models/resume/resumeCommon.types';

export const getNewRecomendation = (): Recommendation => ({
    id: NEW_ID,
    name: '',
    position: '',
    organization: '',
});

export const getSelctedRecomendationByIndex = (store: DefaultRootState): Recommendation => {
    const { index } = selectResumeEditorPayload(store) as PayloadType<{ index: number }>;
    const recommendation = selectApplicantResumeRecommendation(store);

    return recommendation[index] || getNewRecomendation();
};

export const isNewRecomendationChanged = ({ name, organization, position }: Recommendation): boolean =>
    name !== '' || organization !== '' || position !== '';
