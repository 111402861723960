import { selectResumeEditorWorkSchedule } from 'src/models/applicant/resume/blocks/additional/edit/selectors';
import { selectResumeWorkSchedule } from 'src/models/applicant/resume/blocks/additional/selectors';
import { isArrayChangedByFn } from 'src/models/applicant/resume/editor/form/change/lib';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types/fields';

type WorkScheduleBlock = EditorConfig<Pick<ApplicantResumeEditorFields, 'workSchedule'>>;

export const workScheduleConfig: WorkScheduleBlock = {
    init: (store) => ({
        workSchedule: selectResumeWorkSchedule(store),
    }),
    changed: (store) => {
        const editorWorkSchedule = selectResumeEditorWorkSchedule(store);
        const resumeWorkSchedule = selectResumeWorkSchedule(store);

        return isArrayChangedByFn(editorWorkSchedule, resumeWorkSchedule, (a, b) => a.string === b.string);
    },
    prepare: ({ workSchedule }) => ({
        workSchedule,
    }),
    validate: ({ workSchedule }, conditions) => ({
        ...validateEditorFieldByKey('workSchedule', workSchedule, conditions),
    }),
};
