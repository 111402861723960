import { useState } from 'react';
import PropTypes from 'prop-types';

import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import Tabs, { Tab } from 'bloko/blocks/tabs';

import { useHiringManagerCommentButton } from 'src/components/HiringManager/hooks/useHiringManagerCommentButton';
import ResumeComments from 'src/components/ResumeComments';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { TransitionType } from 'src/models/employerNegotiations/negotiationLinks';
import { UserType } from 'src/models/userType';
import { VIEW } from 'src/utils/constants/resumePermission';

import HiringManagerComment from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment';
import History from 'src/pages/ResumeView/components/View/ResumeSidebar/History';
import Interview from 'src/pages/ResumeView/components/View/ResumeSidebar/Interview';
import Shl from 'src/pages/ResumeView/components/View/ResumeSidebar/Tests/TestShl';
import VacancyTest from 'src/pages/ResumeView/components/View/ResumeSidebar/Tests/Vacancy';
import ResumeViewHistory from 'src/pages/ResumeView/components/View/ResumeSidebar/ViewHistory';

const TABS = {
    COMMENTS: 'comments',
    ASSESSMENTS: 'assessments',
    VACANCY_TEST: 'vacancyTest',
    HISTORY: 'history',
    RESUME_VIEW_HISTORY: 'resume-view-history',
    INTERVIEW: 'interview',
};

const ResumeTabs = ({ trls, topicId }) => {
    const { vacancyId } = useSelector((state) => state.router.location.query);
    const resumeHash = useSelector((state) => state.resume.hash);
    const hiringManagerCommentButton = useHiringManagerCommentButton();
    const userId = useSelector((state) => state.resume?.user);
    const currentInterview = useSelector((state) => state.scheduledInterviews?.[topicId]?.currentInterview);
    const percent = useSelector((state) => state.resume?.percent);
    const userType = useSelector((state) => state.userType);
    const assessmentsData = useSelector((state) => state.assessments);
    const topicList = useSelector((state) => state.employerNegotiations.topicList);
    const negotiationsHistory = useSelector((state) => state.negotiationsHistory);
    const resumeViewHistory = useSelector((state) => state.resumeViewHistory);
    const showEmpty = useSelector(
        (state) =>
            !!(
                state.resume?.permission === VIEW &&
                state.resume?.hash &&
                state.employerNegotiationLinks[state.resume?.hash]?.[TransitionType.InviteWithAssessements]
            )
    );
    const [active, setActive] = useState(TABS.COMMENTS);

    if (percent === 0 || userType !== UserType.Employer) {
        return null;
    }

    const current = topicList && topicList.find((topic) => topic.currentVacancy);

    const vacancyTestExists = Boolean(current && current.testSolution);
    const historyExists = !!negotiationsHistory?.length;
    const resumeViewHistoryExists = !!resumeViewHistory?.resumeView?.length;

    const TabsContent = [
        <Tab id={TABS.COMMENTS} defaultActive key={TABS.COMMENTS}>
            {trls[ResumeTabs.trls.comments]}
        </Tab>,
        currentInterview && (
            <Tab key={TABS.INTERVIEW} id={TABS.INTERVIEW}>
                {trls[ResumeTabs.trls.interview]}
            </Tab>
        ),
        (assessmentsData?.count > 0 || showEmpty) && (
            <Tab key={TABS.ASSESSMENTS} id={TABS.ASSESSMENTS}>
                {trls[ResumeTabs.trls.assessments]}
            </Tab>
        ),
        vacancyTestExists && (
            <Tab key={TABS.VACANCY_TEST} id={TABS.VACANCY_TEST}>
                {trls[ResumeTabs.trls.vacancyTest]}
            </Tab>
        ),
        historyExists && (
            <Tab key={TABS.HISTORY} id={TABS.HISTORY}>
                {trls[ResumeTabs.trls.history]}
            </Tab>
        ),
        resumeViewHistoryExists && (
            <Tab key={TABS.RESUME_VIEW_HISTORY} id={TABS.RESUME_VIEW_HISTORY}>
                {trls[ResumeTabs.trls.resumeView]}
            </Tab>
        ),
    ].filter(Boolean);

    return (
        <div className="resume-block-tabs">
            <Column xs="4" s="8" m="0" l="0">
                <Tabs onChange={setActive}>{TabsContent}</Tabs>
                <div className="substrate substrate_dashboard">
                    <Gap top bottom right left>
                        <ResumeComments
                            visible={active === TABS.COMMENTS}
                            user={userId}
                            {...(hiringManagerCommentButton ? { render: { add: HiringManagerComment } } : {})}
                            topicId={topicId}
                            vacancyId={vacancyId}
                            resumeHash={resumeHash}
                        />
                        {currentInterview && active === TABS.INTERVIEW && (
                            <Interview currentInterview={currentInterview} isTab />
                        )}
                        {(assessmentsData?.count > 0 || showEmpty) && <Shl visible={active === TABS.ASSESSMENTS} />}
                        {vacancyTestExists && <VacancyTest visible={active === TABS.VACANCY_TEST} />}
                        {historyExists && <History visible={active === TABS.HISTORY} />}
                        {resumeViewHistoryExists && (
                            <ResumeViewHistory
                                historyList={resumeViewHistory.resumeView}
                                visible={active === TABS.RESUME_VIEW_HISTORY}
                            />
                        )}
                    </Gap>
                </div>
            </Column>
        </div>
    );
};

ResumeTabs.propTypes = {
    topicId: PropTypes.string,
    trls: PropTypes.object,
};

ResumeTabs.trls = {
    interview: 'resume.interview.title',
    comments: 'resume.comments',
    assessments: 'resume.assessments',
    vacancyTest: 'employer.resume.testSolution.title',
    history: 'resume.employer.history.title',
    resumeView: 'resume.employer.viewhistory.views',
};

export default translation(ResumeTabs);
