import { useEffect, useRef } from 'react';

import resumeRsvCompetenceButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-25156/resume_rsv_competence_button_click';
import resumeRsvCompetenceElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-25156/resume_rsv_competence_element_shown';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonAppearance, ButtonKind, ButtonScale } from 'bloko/blocks/button';
import { H3Section, H4Section } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import urlParser from 'bloko/common/urlParser';

import scrollToElement from 'Utils/ScrollToElement';
import RsvVerificationBanner from 'src/components/ApplicantRsvVerification/RsvVerificationBanner';
import ElementShownAnchor from 'src/components/ElementShownAnchor';
import HumanDate from 'src/components/HumanDate';
import translation from 'src/components/translation';
import { useSelectorNonNullable, useSelector } from 'src/hooks/useSelector';
import { UserType } from 'src/models/userType';

import styles from './styles.less';

interface ApplicantRsvVerificationCardProps {
    resumeHash: string;
}

const TrlKeys = {
    title: 'rsvUserCompetencies.applicant.applicantRsvVerificationCard.title',
    updatedAt: 'rsvUserCompetencies.applicant.applicantRsvVerificationCard.updatedAt',
    show: 'rsvUserCompetencies.applicant.applicantRsvVerificationCard.show',

    employeerCannotSeeResults: {
        title: 'rsvUserCompetencies.applicant.employeesCannotSeeResults.title',
        text: 'rsvUserCompetencies.applicant.employeesCannotSeeResults.text',
        toSettings: 'rsvUserCompetencies.applicant.employeesCannotSeeResults.goToSettings',
    },
    applicantRsvAssessmentText: 'rsvUserCompetencies.applicant.rsvAssessmentText',
};

interface QueryParams {
    vacancyId: string | null;
}

const ApplicantRsvVerificationCard: TranslatedComponent<ApplicantRsvVerificationCardProps> = ({ resumeHash, trls }) => {
    const rsvCompetencyList = useSelector((state) => state.rsvCompetencyData?.list);
    const userType = useSelector(({ userType }) => userType);
    const isApplicant = UserType.Applicant === userType;
    const isEmployer = UserType.Employer === userType;
    const verificationCardRef = useRef<HTMLDivElement>(null);

    const { vacancyId = null } = useSelector((state) => state.router.location.query) as unknown as QueryParams;
    const resumeId = useSelectorNonNullable((state) => state?.resume?.id);

    const handleShowClick = () => {
        resumeRsvCompetenceButtonClick({ type: 'RESUME', resumeId });
    };

    useEffect(() => {
        if (verificationCardRef.current !== null) {
            if (typeof window === 'undefined') {
                return;
            }
            const urlParams = new URLSearchParams(window?.location.search);
            const scrollTo = urlParams.get('scrollTo');
            if (scrollTo === 'rsv') {
                scrollToElement(verificationCardRef.current, { centered: false, topOffset: 0 });
            }
        }
    }, []);

    if (!rsvCompetencyList) {
        return null;
    }
    return (
        <ElementShownAnchor
            className={styles.hidePrint}
            fn={resumeRsvCompetenceElementShown}
            type={'RESUME'}
            resumeId={resumeId}
        >
            <div ref={verificationCardRef} className={styles.applicantRsvVerificationCard}>
                <H3Section>{trls[TrlKeys.title]}</H3Section>
                <VSpacing base={1} />
                {isApplicant && (
                    <Text importance={TextImportance.Secondary}>
                        {trls[TrlKeys.updatedAt]} <HumanDate date={rsvCompetencyList.updatedAt} textify />
                    </Text>
                )}
                {isEmployer && (
                    <Text importance={TextImportance.Secondary}>{trls[TrlKeys.applicantRsvAssessmentText]}</Text>
                )}
                {isApplicant && !rsvCompetencyList?.visible && (
                    <>
                        <VSpacing base={4} />
                        <RsvVerificationBanner>
                            <H4Section>{trls[TrlKeys.employeerCannotSeeResults.title]}</H4Section>
                            <VSpacing base={2} />
                            <Text>{trls[TrlKeys.employeerCannotSeeResults.text]}</Text>
                            <VSpacing base={2} />
                            <BlokoLink Element={Link} to="/applicant/settings">
                                {trls[TrlKeys.employeerCannotSeeResults.toSettings]}
                            </BlokoLink>
                        </RsvVerificationBanner>
                    </>
                )}
                <VSpacing base={6} />
                <div className="resume-key-skills-verification-button-container">
                    <BlokoLink
                        Element={Link}
                        to={`/applicant/rsv_verifications/${resumeHash}?${urlParser.stringify({
                            vacancyId,
                        })}`}
                        onClick={handleShowClick}
                    >
                        <Button
                            kind={ButtonKind.Primary}
                            scale={ButtonScale.Small}
                            appearance={ButtonAppearance.Filled}
                        >
                            {trls[TrlKeys.show]}
                        </Button>
                    </BlokoLink>
                </div>
            </div>
        </ElementShownAnchor>
    );
};

export default translation(ApplicantRsvVerificationCard);
