import { ComponentType } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import PersonalSiteField from 'src/pages/ResumeView/redesign/contacts/Editor/PersonalSite/Field';
import { FIELD_NAME } from 'src/pages/ResumeView/redesign/contacts/Editor/PersonalSite/const';

const PersonalSiteFieldList: ComponentType<{ errorsMap: ErrorsMap }> = ({ errorsMap }) => {
    const { value: personalSite } = useResumeEditorField(FIELD_NAME);

    if (personalSite.length === 0) {
        return null;
    }

    return (
        <VSpacingContainer default={16}>
            {personalSite.map(({ type }, index) => (
                <PersonalSiteField key={type} type={type} fieldIndex={index} errorsMap={errorsMap} />
            ))}
        </VSpacingContainer>
    );
};

export default PersonalSiteFieldList;
