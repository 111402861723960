export const NEW_ID = -1;

type WithID = { id: number };

export const isNewArrayField = <T extends WithID>({ id }: T): boolean => {
    return id === NEW_ID;
};

export const updateItemById = <T extends WithID>(resumeRecommendation: T[], recommendation: T): T[] => {
    return resumeRecommendation.map((resumeRec) => {
        if (resumeRec.id === recommendation.id) {
            return recommendation;
        }
        return resumeRec;
    });
};

export const isChangedByKeys =
    <T extends object>(keys: (keyof T)[]) =>
    (a: T, b: T): boolean => {
        return keys.some((key) => {
            const valueA = a[key];
            const valueB = b[key];
            if (valueA === null) {
                return valueB !== '';
            }
            if (valueB === null) {
                return valueA !== '';
            }
            return a[key] !== b[key];
        });
    };
