import { DefaultRootState } from 'react-redux';

import {
    Employment,
    WorkSchedule,
    TravelTime,
    BusinessTripReadiness,
} from 'src/models/applicant/resume/blocks/additional/types';
import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';
import { StringFieldType } from 'src/models/applicant/resume/types/common';

export const selectResumeEditorOpenedWorkSchedule = selectResumeEditorOpenedType('workSchedule');
export const selectResumeEditorOpenedEmployment = selectResumeEditorOpenedType('employment');
export const selectResumeEditorOpenedTraveTime = selectResumeEditorOpenedType('travelTime');
export const selectResumeEditorOpenedBusinessTripReadiness = selectResumeEditorOpenedType('businessTripReadiness');

export const selectResumeEditorWorkSchedule = ({ resumeEditor }: DefaultRootState): StringFieldType<WorkSchedule>[] =>
    resumeEditor.fields.workSchedule || [];

export const selectResumeEditorEmployment = ({ resumeEditor }: DefaultRootState): StringFieldType<Employment>[] =>
    resumeEditor.fields.employment || [];

export const selectResumeEditorTravelTime = ({ resumeEditor }: DefaultRootState): TravelTime =>
    resumeEditor.fields.travelTime || 'any';

export const selectResumeEditorBusinessTripReadiness = ({ resumeEditor }: DefaultRootState): BusinessTripReadiness =>
    resumeEditor.fields.businessTripReadiness || 'never';
