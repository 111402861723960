import { Breakpoint, getBreakpoint } from 'bloko/common/media';

const addToCartAnimation = (target: EventTarget): void => {
    const totalCost = document.querySelector('.HH-AddItemToCartAnimation-TotalCost');

    if (!totalCost) {
        return;
    }

    if (getBreakpoint() !== Breakpoint.L) {
        const flyingElement = document.createElement('div');
        const playTotalCostUpdateAnimation = () => {
            flyingElement.remove();
            totalCost.classList.remove('price-cart-update-blink');
            setTimeout(() => {
                totalCost.classList.add('price-cart-update-blink');
            }, 0);
            flyingElement.removeEventListener('transitionend', playTotalCostUpdateAnimation);
        };
        flyingElement.addEventListener('transitionend', playTotalCostUpdateAnimation);
        flyingElement.classList.add('price-cart-flying-item');
        const targetRect = (target as HTMLElement).getBoundingClientRect();
        flyingElement.style.top = `${targetRect.top + window.scrollY}px`;
        flyingElement.style.left = `${targetRect.left + window.scrollX}px`;
        document.body.appendChild(flyingElement);
        const totalCostRect = totalCost.getBoundingClientRect();
        setTimeout(() => {
            flyingElement.style.top = `${totalCostRect.top + window.scrollY}px`;
            flyingElement.style.left = `${totalCostRect.left + window.scrollX}px`;
        }, 0);
        flyingElement.classList.add('price-cart-flying-item_animated');
    } else {
        totalCost.classList.remove('price-cart-update-blink');
        setTimeout(() => {
            totalCost.classList.add('price-cart-update-blink');
        }, 0);
    }
};

export default addToCartAnimation;
