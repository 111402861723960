import { PersonalSite, PersonalSiteTypes } from 'src/models/applicant/resume/blocks/contacts/types';
import { EditorFieldError } from 'src/models/applicant/resume/editor/types/error';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';

const VALIDATE_FIELD_BY_TYPE: { [key in PersonalSiteTypes]: RegExp } = {
    freelance: /^(http|https):\/\/(www\.*)?(free-lance|fl)\.ru\/users/,
    personal: /^(http|https):\/\/./,
    livejournal: /^(http|https):\/\/(www\.*)?([a-zA-Z0-9_-]*\.*)?livejournal\.com/,
    linkedin: /^(http|https):\/\/(www\.*)?linkedin\.com/,
    // eslint-disable-next-line no-useless-escape
    moi_krug: /^(http|https):\/\/(www\.*)?(career.\habr)\.(ru|com)/, // eslint-disable-line camelcase
    facebook: /^(http|https):\/\/(www\.*)?(facebook|fb|m.\facebook)\.(com|me)/,
    skype: /.*/,
};

export const validatePersonalSite = (
    field: 'personalSite',
    values: PersonalSite[]
): Record<string, EditorFieldError[]> => {
    const errors = values.reduce<EditorFieldError[]>((result, value, index) => {
        const { type, url } = value;
        if (type in VALIDATE_FIELD_BY_TYPE && url.trim()) {
            const maskValidation = VALIDATE_FIELD_BY_TYPE[type];
            if (!maskValidation.test(url.trim())) {
                return [
                    ...result,
                    {
                        field,
                        code: 'NOT_MATCH_REGEXP',
                        type: 'CONDITION',
                        value: url,
                        message: '',
                        fieldNamePath: `/${field}/${index}/url`,
                    },
                ];
            }
        }
        return result;
    }, []);
    if (isEmpty(errors)) {
        return {};
    }
    return {
        personalSite: errors,
    };
};
