import { FC } from 'react';

import { Tag } from '@hh.ru/magritte-ui';
import { format } from 'bloko/common/trl';

import { ResumeCreds } from 'src/models/resume/resumeCreds.types';

interface ResumeCredsItemProps {
    creds: ResumeCreds;
    questionId: string;
    answers: string[];
}

const ResumeCredsItem: FC<ResumeCredsItemProps> = ({ creds, questionId, answers }) => {
    const renderAnswers = () =>
        answers
            .filter((answerId) => !creds.answers[answerId]?.skipAtResult)
            .map((answerId) => creds.answers[answerId]?.positiveTitle)
            .join(', ');

    return <Tag>{format(creds.questions[questionId]?.viewTitle || '{answers}', { '{answers}': renderAnswers() })}</Tag>;
};

export default ResumeCredsItem;
