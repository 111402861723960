import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

export enum AuctionErrorType {
    AlreadyCreated = 'alreadyCreated',
}

const TrlKeys = {
    commonErrors: {
        [AuctionErrorType.AlreadyCreated]: 'employerAuction.error.alreadyCreated',
    },
};

const AuctionError: TranslatedComponent<{ errorType: AuctionErrorType }> = ({ trls, errorType }) => (
    <>{trls[TrlKeys.commonErrors[errorType]]}</>
);

export const auctionError = {
    Element: translation(AuctionError),
    kind: 'error',
    autoClose: true,
};
