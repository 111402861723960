import PropTypes from 'prop-types';

/**
 * @typedef {Object} HumanDatesRule
 * @property {Number} count
 * @property {String} translation
 * @property {Number} ending
 */

/**
 * @typedef {Object.<Number, HumanDatesRule>} HumanDatesRules
 */

export const humanDatesRulesType = PropTypes.objectOf(
    PropTypes.shape({
        count: PropTypes.number,
        translation: PropTypes.string,
        ending: PropTypes.number,
    })
);
