import Utils from 'Modules/Maps/Utils';
import ymapsPromise from 'Modules/Maps/ymapsPromise';

export const defaults = {
    mapZoom: 16,
    placemarkZIndex: 999,
    newEmployerVacancyMap: false,
};

const defaultPlacemark = {
    placemarkIcon: `${window.globalVars.staticHost}/images/Components/VacancyMap/hh-placemark.svg`,
    placemarkIconSize: [37, 53],
    placemarkOffset: [-7, -12],
};

const newEmployerVacancyMapPlacemark = {
    placemarkIcon: `${window.globalVars.staticHost}/images/Components/VacancyMap/hh-placemark-redesign.svg`,
    placemarkIconSize: [32, 32],
    placemarkOffset: [0, 0],
};

/**
 * Инициализация Яндекс.Карты на элементе.
 *
 * @param {Element} element
 * @param {Object} params
 * @param {Object} params.address
 * @param {Boolean} [params.disableScrollZoom]
 * @param {Boolean} [params.disableDrag]
 * @param {Boolean} [params.disableDblClickZoom]
 * @param {Number} [params.mapZoom]
 * @param {String} [params.placemarkIcon]
 * @param {Array} [params.placemarkIconSize]
 * @param {Array} [params.placemarkOffset]
 * @param {Number} [params.placemarkZIndex]
 * @param {Object} [params.zoomControlPosition]
 * @param {Number} [params.zoomControlPosition.left]
 * @param {Number} [params.zoomControlPosition.top]
 * @param {String} [params.zoomControlCustomTemplate]
 * @param {Object} [params.geolocationPosition]
 * @param {Number} [params.geolocationPosition.left]
 * @param {Number} [params.geolocationPosition.top]
 * @param {String} [params.geolocationCustomTemplate]
 * @param {Object} [params.autoFitToViewport]
 * @param {Boolean} [params.newEmployerVacancyMap]
 * @param {Object} [abortSignal]
 *
 * @returns {Promise} Резолвится с `mapInstance`, прокидывает реджект от `ymapsPromise`.
 */
const showMap = (element, params, abortSignal) => {
    const {
        address,
        disableScrollZoom,
        disableDrag,
        disableDblClickZoom,
        mapZoom,
        onPlacemarkClick,
        placemarkZIndex,
        zoomControlPosition,
        zoomControlCustomTemplate,
        geolocationPosition,
        geolocationCustomTemplate,
        autoFitToViewport,
        newEmployerVacancyMap,
    } = { ...defaults, ...params };

    const { placemarkIcon, placemarkIconSize, placemarkOffset } = {
        ...(newEmployerVacancyMap ? newEmployerVacancyMapPlacemark : defaultPlacemark),
        ...params,
    };

    return ymapsPromise().then((ymaps) => {
        if (abortSignal?.aborted) {
            return null;
        }
        const { center, marker, bounds } = address.mapData.points;
        let mapConfig = {};
        // init by bounds
        if (bounds) {
            mapConfig = { bounds };
        }
        // init by center
        if (center) {
            mapConfig = {
                center: [center.lat, center.lng],
                zoom: center.zoom,
            };
        }
        const mapInstance = new ymaps.Map(
            element,
            {
                controls: zoomControlPosition ? [] : ['zoomControl'],
                ...mapConfig,
            },
            {
                maxZoom: 20,
                // Отключить интерактивность POI (памятники, магазины) в подложке карты
                yandexMapDisablePoiInteractivity: true,
                // Отключить предложение открыть текущую карту в Яндекс.Картах
                suppressMapOpenBlock: true,
                // Автоматическое слежение за контейнером карты. По умолчанию карта перестроится автоматически в случае, когда она инициализируется из скрытого контейнера, либо мы программно поменяли его размеры, иначе необходимо вызвать map.container.fitToViewport.
                autoFitToViewport,
            }
        );
        if (zoomControlPosition) {
            const zoomSettings = {};
            if (zoomControlCustomTemplate) {
                const changeZoom = (amount) => {
                    mapInstance.setZoom(mapInstance.getZoom() + amount, {
                        checkZoomRange: true,
                        duration: 100,
                    });
                };
                const handleZoomIn = () => changeZoom(1);
                const handleZoomOut = () => changeZoom(-1);

                const ZoomLayout = ymaps.templateLayoutFactory.createClass(zoomControlCustomTemplate, {
                    build() {
                        ZoomLayout.superclass.build.call(this);
                        document.querySelector('#zoom-in').addEventListener('click', handleZoomIn);
                        document.querySelector('#zoom-out').addEventListener('click', handleZoomOut);
                    },
                    clear() {
                        document.querySelector('#zoom-in').removeEventListener('click', handleZoomIn);
                        document.querySelector('#zoom-out').removeEventListener('click', handleZoomOut);
                        ZoomLayout.superclass.clear.call(this);
                    },
                });
                zoomSettings.layout = ZoomLayout;
            }
            mapInstance.controls.add(
                new ymaps.control.ZoomControl({
                    options: {
                        position: zoomControlPosition,
                        ...zoomSettings,
                    },
                })
            );
        }
        if (geolocationPosition) {
            const geolocationSettings = {};
            if (geolocationCustomTemplate) {
                geolocationSettings.layout = ymaps.templateLayoutFactory.createClass(geolocationCustomTemplate);
            }
            mapInstance.controls.add(
                new ymaps.control.GeolocationControl({
                    options: {
                        position: geolocationPosition,
                        ...geolocationSettings,
                    },
                })
            );
        }

        const behaviorsDisableMap = {
            scrollZoom: disableScrollZoom,
            drag: disableDrag,
            dblClickZoom: disableDblClickZoom,
        };
        const behaviorsDisable = Object.keys(behaviorsDisableMap).filter((option) => behaviorsDisableMap[option]);

        if (behaviorsDisable.length) {
            mapInstance.behaviors.disable(behaviorsDisable);
        }

        if (marker) {
            const markerCoords = [marker.lat, marker.lng];
            let closeButton;
            let closeHandler;

            const BalloonLayout = ymaps.templateLayoutFactory.createClass(
                `<div class="vacancy-address-map-balloon">
                    <div>$[properties.balloonContent]</div>
                    <span class="vacancy-address-map-balloon-close"></span>
                </div>`,
                {
                    build() {
                        this.constructor.superclass.build.call(this);
                        this._element = this.getParentElement().querySelector('.vacancy-address-map-balloon');
                        this.applyElementOffset();
                        closeHandler = this.onCloseClick.bind(this);
                        closeButton = this._element.querySelector('.vacancy-address-map-balloon-close');
                        closeButton.addEventListener('click', closeHandler);
                    },
                    clear() {
                        closeButton.removeEventListener('click', closeHandler);
                        this.constructor.superclass.clear.call(this);
                    },
                    onSublayoutSizeChange(...args) {
                        BalloonLayout.superclass.onSublayoutSizeChange.apply(this, args);
                        if (!this._element) {
                            return;
                        }
                        this.applyElementOffset();
                        this.events.fire('shapechange');
                    },
                    applyElementOffset() {
                        this._element.style.left = `${-(this._element.offsetWidth / 2)}px`;
                        this._element.style.top = `${-this._element.offsetHeight - 45}px`;
                    },
                    onCloseClick(e) {
                        e.preventDefault();
                        e.stopPropagation();
                        this.events.fire('userclose');
                    },
                    getShape() {
                        if (!this._element) {
                            return BalloonLayout.superclass.getShape.call(this);
                        }
                        const position = this._element.getBoundingClientRect();
                        return new ymaps.shape.Rectangle(
                            new ymaps.geometry.pixel.Rectangle([
                                [position.left, position.top],
                                [position.left + this._element.offsetWidth, position.top + this._element.offsetHeight],
                            ])
                        );
                    },
                }
            );

            const newEmployerVacancyMapAdditionalLayoutOptions = newEmployerVacancyMap
                ? {
                      balloonLayout: BalloonLayout,
                      hideIconOnBalloonOpen: false,
                      balloonPanelMaxMapArea: 0,
                      openBalloonOnClick: false,
                  }
                : {};

            const addressPlacemark = new ymaps.Placemark(
                markerCoords,
                {},
                {
                    iconLayout: 'default#image',
                    iconImageHref: placemarkIcon,
                    iconImageSize: placemarkIconSize,
                    iconOffset: placemarkOffset,
                    zIndex: placemarkZIndex,
                    ...newEmployerVacancyMapAdditionalLayoutOptions,
                }
            );
            if (onPlacemarkClick) {
                addressPlacemark.events.add('click', (e) => {
                    onPlacemarkClick();
                    e.preventDefault();
                });
            }
            mapInstance.geoObjects.add(addressPlacemark);

            if (!newEmployerVacancyMap) {
                addressPlacemark.properties.set('balloonContent', Utils.prettyPrint(address));
                // Коррекция центра (на случай если его координаты в XML не совпадают с координатами объекта)
                mapInstance.setCenter(markerCoords, mapZoom);
            } else {
                const balloonText = address.displayName.split(', ').slice(1).join(', ');
                addressPlacemark.properties.set('balloonContent', balloonText || address.displayName);
                addressPlacemark.balloon.events.add('click', (e) => {
                    onPlacemarkClick();
                    e.preventDefault();
                });
                addressPlacemark.balloon.open().then(
                    () => {
                        mapInstance.setCenter(markerCoords, mapZoom);
                    },
                    (err) => {
                        console.error(err);
                    }
                );
            }
        }

        mapInstance.container.fitToViewport();

        return { mapInstance, ymaps };
    });
};

export default showMap;
