import { FC } from 'react';

import { Tag } from '@hh.ru/magritte-ui';
import { VerifiedBadgeFilledSize16 } from '@hh.ru/magritte-ui/icon';

import KeySkillsTagInner from 'src/components/KeySkills/KeySkillsTagInner';
import { useSelector } from 'src/hooks/useSelector';
import { KeySkill } from 'src/models/resume/resumeCommon.types';
import { VerifiedBy, VSkillValidityStatus } from 'src/models/resume/resumeVerifiedSkills.types';
import { getAppropriateSkill } from 'src/utils/skills';

interface ResumeSkillsItemProps {
    skill: KeySkill;
}

const ResumeSkillsItem: FC<ResumeSkillsItemProps> = ({ skill }) => {
    const resumeApplicantSkills = useSelector((state) => state.resume?.resumeApplicantSkills);
    const currentSkill = resumeApplicantSkills?.find((vSkill) => getAppropriateSkill(vSkill, skill.id));
    const isVerified = [VSkillValidityStatus.Effective, VSkillValidityStatus.Grace].includes(
        (currentSkill?.theory || currentSkill?.practice)?.validity.state
    );
    const isVerificationIconVisible =
        isVerified && [VerifiedBy.TheoryAndPractice, VerifiedBy.Practice].includes(currentSkill?.verifiedBy);

    return (
        <Tag
            style={isVerified ? 'positive' : 'neutral'}
            icon={isVerificationIconVisible ? VerifiedBadgeFilledSize16 : undefined}
        >
            <KeySkillsTagInner skillName={skill.name} rank={currentSkill?.level?.rank} />
        </Tag>
    );
};

export default ResumeSkillsItem;
