import { Fragment, useRef, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import resumeDownloadButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_download_button_click';
import { Link } from '@hh.ru/redux-spa-middleware';
import Button from 'bloko/blocks/button';
import Menu, { MenuLayer, MenuItem } from 'bloko/blocks/drop/Menu';
import HoverTip, { TipLayer } from 'bloko/blocks/drop/Tip/HoverTip';
import { ArrowLineScaleSmall, IconColor } from 'bloko/blocks/icon';
import TranslateGuard from 'bloko/blocks/translateGuard';

import loadContacts from 'src/components/ResumeView/LoadContacts';
import translation from 'src/components/translation';
import useIsClient from 'src/hooks/useIsClient';

const ResumeButtonDownload = ({ resumeExportTypes, validate, resumeId, vacancyId, trls }) => {
    const isClient = useIsClient();
    const [menuVisible, setMenuVisible] = useState(false);
    const hideMenu = () => setMenuVisible(false);
    const onButtonClick = (event) => {
        resumeDownloadButtonClick({ resumeId, vacancyId });
        validate(event, 'Download').then(() => {
            setMenuVisible(!menuVisible);
        }, console.error);
    };
    const activatorRef = useRef(null);

    return (
        <Fragment>
            <Menu
                onClose={hideMenu}
                show={menuVisible}
                layer={MenuLayer.AboveContent}
                render={() =>
                    resumeExportTypes.map((exportType) => {
                        const { link, name, description, postprocessing } = exportType;
                        const targetAttr = postprocessing ? {} : { target: '_blank' };

                        return (
                            <MenuItem Element={Link} to={link} {...targetAttr} onClick={() => {}} key={name}>
                                <span className="list-params-content">
                                    <TranslateGuard useSpan>
                                        {trls[ResumeButtonDownload.trls[description]]}
                                    </TranslateGuard>
                                    <TranslateGuard useSpan>
                                        <span className="list-params-link-note">
                                            {trls[ResumeButtonDownload.trls[name]]}
                                        </span>
                                    </TranslateGuard>
                                </span>
                            </MenuItem>
                        );
                    })
                }
                activatorRef={activatorRef}
            >
                <HoverTip
                    host={isClient ? document.body : null}
                    layer={TipLayer.Overlay}
                    render={() => trls[ResumeButtonDownload.trls.download]}
                    activatorRef={activatorRef}
                >
                    <Button
                        icon={<ArrowLineScaleSmall initial={IconColor.Gray50} />}
                        onClick={onButtonClick}
                        data-qa="resume-download-button"
                        aria-label={trls[ResumeButtonDownload.trls.download]}
                        innerRef={activatorRef}
                    />
                </HoverTip>
            </Menu>
        </Fragment>
    );
};

ResumeButtonDownload.propTypes = {
    resumeExportTypes: PropTypes.arrayOf(
        PropTypes.shape({
            className: PropTypes.string,
            link: PropTypes.string,
            name: PropTypes.string,
            description: PropTypes.string,
            postprocessing: PropTypes.bool,
        })
    ).isRequired,
    trls: PropTypes.object,
    resumeId: PropTypes.string,
    vacancyId: PropTypes.string,
    validate: PropTypes.func.isRequired,
};

ResumeButtonDownload.trls = {
    download: 'topbuttons.resume.download',
    doc: 'resume_converter.doc',
    rtf: 'resume_converter.rtf',
    pdf: 'resume_converter.pdf',
    txt: 'resume_converter.txt',
    MSWord: 'resume_converter.MSWord',
    AdobeReader: 'resume_converter.AdobeReader',
    Notepad: 'resume_converter.Notepad',
};

export default connect((state) => ({
    resumeExportTypes: state.resumeExportTypes,
    resumeId: state.resume?.id,
    vacancyId: state.router.location.query.vacancyId,
}))(loadContacts(translation(ResumeButtonDownload)));
