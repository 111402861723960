import { useContext, useEffect, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import { TranslationLangContext } from 'bloko/common/hooks/useTranslations';

import { Lang } from 'src/models/langs';
import professionalRoleTree from 'src/models/professionalRoleTree';
import fetcher from 'src/utils/fetcher';

const URL = '/shards/professional_role';

declare global {
    interface FetcherGetApi {
        [URL]: {
            queryParams: {
                lang: Lang;
            };
            response: professionalRoleTree;
        };
    }
}

type TreeCacheType = Record<string, professionalRoleTree>;

const professionalRoleTreeAction = makeSetStoreField('professionalRoleTree');

export const fetchProfessionalRoleTree = (lang: Lang): Promise<professionalRoleTree> =>
    fetcher.get(URL, {
        params: { lang },
    });

export default function useFetchProfessionalRoleTree(): void {
    const treeCache = useRef<TreeCacheType>({});
    const dispatch = useDispatch();
    const lang = useContext(TranslationLangContext) as Lang;

    useEffect(() => {
        if (!(lang in treeCache.current)) {
            fetchProfessionalRoleTree(lang)
                .then((data) => {
                    treeCache.current[lang] = data;
                    dispatch(professionalRoleTreeAction(data));
                })
                .catch(console.error);
        } else {
            dispatch(professionalRoleTreeAction(treeCache.current[lang]));
        }
    }, [lang, dispatch]);
}
