import { aboutConfig } from 'src/models/applicant/resume/blocks/about/edit/config';
import { businessTripReadinessConfig } from 'src/models/applicant/resume/blocks/additional/edit/config/businessTripReadiness';
import { employmentConfig } from 'src/models/applicant/resume/blocks/additional/edit/config/employment';
import { travelTimeConfig } from 'src/models/applicant/resume/blocks/additional/edit/config/travelTime';
import { workScheduleConfig } from 'src/models/applicant/resume/blocks/additional/edit/config/workSchedule';
import { additionalEducationConfig } from 'src/models/applicant/resume/blocks/additionalEducation/editor/config';
import { attestationEducationConfig } from 'src/models/applicant/resume/blocks/attestationEducation/editor/config';
import { contactsConfig } from 'src/models/applicant/resume/blocks/contacts/editor/config';
import { positionConfig } from 'src/models/applicant/resume/blocks/position/edit/config';
import { recommendationConfig } from 'src/models/applicant/resume/blocks/recommendation/editor/config';

export const editorConfigMap = {
    about: aboutConfig,
    position: positionConfig,
    recommendation: recommendationConfig,
    additionalEducation: additionalEducationConfig,
    attestationEducation: attestationEducationConfig,
    contacts: contactsConfig,
    travelTime: travelTimeConfig,
    employment: employmentConfig,
    workSchedule: workScheduleConfig,
    businessTripReadiness: businessTripReadinessConfig,
};
