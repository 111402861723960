import { ReactNode } from 'react';

import Conversion from 'bloko/blocks/conversion';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';

interface DateOnlyProps {
    /** Количество дней */
    value: number;
    /** Флаг выводить ли со склоняемым словом число */
    hasValue?: boolean;
    /** Функция обработки числа */
    formatValue?: (value: number) => ReactNode;
    /** Выводить количество дней в часах */
    shouldShowInHours?: boolean;
}

const TrlKeys = {
    days: {
        one: 'lang.day.1', // 1 день
        some: 'lang.day.2', // 2 дня
        many: 'lang.day.5', // 5 дней
        zero: 'lang.day.5', // 0 дней
    },
    hours: {
        one: 'hour.2', // 24 часа
        some: 'hour.5', // 48 часов
        many: 'hour.5', // 120 часов
        zero: 'hour.5', // 0 часов
    },
};

const DateOnly: TranslatedComponent<DateOnlyProps> = ({
    hasValue = true,
    formatValue,
    value,
    trls,
    shouldShowInHours,
}) => {
    const translations = shouldShowInHours ? TrlKeys.hours : TrlKeys.days;

    const getFormatter = () => {
        if (shouldShowInHours) {
            // eslint-disable-next-line react/display-name
            return (value: number) => <span suppressHydrationWarning>{formatValue?.(value * 24) ?? value * 24}</span>;
        }
        return formatValue;
    };

    return (
        <Conversion
            many={trls[translations.many]}
            some={trls[translations.some]}
            one={trls[translations.one]}
            zero={trls[translations.zero]}
            formatValue={getFormatter()}
            hasValue={hasValue}
            value={value}
        />
    );
};

export default translation(DateOnly);
