import { ComponentType, ChangeEvent } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Input, VSpacingContainer } from '@hh.ru/magritte-ui';

import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';
import PreferredContact from 'src/pages/ResumeView/redesign/contacts/Editor/PreferredContact';

const FIELD_NAME = 'email';

const TrlKeys = {
    label: 'resume.editor.contacts.label.email',
    placeholder: 'resume.editor.contacts.placeholder.email',
};

const EmailField: ComponentType<{ errorsMap: ErrorsMap }> = translation(({ trls, errorsMap }) => {
    const { value, onChange, invalid, errorMessage } = useResumeEditorField(FIELD_NAME, errorsMap);

    const handleEmailChanged = (email: string) => {
        onChange(email);
    };

    const handleEmailBlur = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.target.value.replace(/\s/g, '');
        if (value !== event.target.value) {
            onChange(value);
        }
    };

    return (
        <EditorField htmlFor="email" label={trls[TrlKeys.label]}>
            <VSpacingContainer default={12}>
                <PreferredContact fieldName="email" value={value} />
                <Input
                    size="medium"
                    inputMode="email"
                    placeholder={trls[TrlKeys.placeholder]}
                    value={value}
                    invalid={invalid}
                    onChange={handleEmailChanged}
                    onBlur={handleEmailBlur}
                    errorMessage={errorMessage}
                />
            </VSpacingContainer>
        </EditorField>
    );
});

export default EmailField;
