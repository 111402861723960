export enum SkillCategory {
    Skill = 'SKILL',
    Lang = 'LANG',
}

export enum SkillsLevelsKeys {
    NONE = '0',
    BASIC = '1',
    MEDIUM = '2',
    ADVANCED = '3',
}
