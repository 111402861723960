import { DefaultRootState } from 'react-redux';

import { selectResumeStringField } from 'src/models/applicant/resume/selectors';

import {
    Phone,
    PreferredContact,
    PersonalSite,
    PersonalSiteTypes,
    PhoneType,
} from 'src/models/applicant/resume/blocks/contacts/types';

export const selectResumeEmail = selectResumeStringField('email', '');

export const selectResumePreferredContact = selectResumeStringField<PreferredContact>(
    'preferredContact',
    PreferredContact.CellPhone
);

export const selectResumePhone = ({ applicantResume }: DefaultRootState): Phone[] => applicantResume?.phone ?? [];

export const selectResumePersonalSite = ({ applicantResume }: DefaultRootState): PersonalSite[] =>
    applicantResume?.personalSite ?? [];

export const selectResumePersonalSiteType = ({ applicantResume }: DefaultRootState): PersonalSiteTypes[] =>
    applicantResume?._conditions.personalSite?.parts[0].type.enum as PersonalSiteTypes[];

export const selectResumePhoneType = ({ applicantResume }: DefaultRootState): PhoneType[] =>
    applicantResume._conditions.phone?.parts[0].type.enum as PhoneType[];
