import { ComponentType, PropsWithChildren } from 'react';

import { Button, useBreakpoint } from '@hh.ru/magritte-ui';

interface Props extends PropsWithChildren {
    onClick: VoidFunction;
}

const EditButton: ComponentType<Props> = ({ onClick, children }) => {
    const { isMobile } = useBreakpoint();
    if (!isMobile) {
        return null;
    }

    return (
        <Button stretched mode="secondary" style="neutral" size="medium" onClick={onClick}>
            {children}
        </Button>
    );
};

export default EditButton;
