import { connect } from 'react-redux';
import PropTypes from 'prop-types';

// tempexp_23996_next-line
import { Breakpoint, Divider, HSpacing, useBreakpoint, VSpacing } from '@hh.ru/magritte-ui';
import Column from 'bloko/blocks/column';
import Gap from 'bloko/blocks/gap';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';

import { CONTENT_ID } from 'src/components/A11y/A11yConstants';
import ContactFeedbackBanner from 'src/components/EmployerSearch/ContactFeedbackBanner';
import { useBannerProps } from 'src/components/EmployerSearch/ContactFeedbackBanner/hooks/useBannerProps';
import { useBannerSettings } from 'src/components/EmployerSearch/ContactFeedbackBanner/hooks/useBannerSettings';
// tempexp_23996_next-line
import HrlinkBanner from 'src/components/HrlinkBanner';
import useExperiment from 'src/hooks/useExperiment';
import { usePrint } from 'src/hooks/usePrint';
import { useProfile } from 'src/hooks/useProfile';
import { useSelector } from 'src/hooks/useSelector';
// tempexp_23996_next-line
import { useIsHhru } from 'src/hooks/useSites';
import { UserType } from 'src/models/userType';
import ResumeAnonymousWarning from 'src/pages/ResumeView/components/ResumeAnonymous/Warning';
import ResumeEmployerTransitions from 'src/pages/ResumeView/components/ResumeTransitions';
import ResumePosition from 'src/pages/ResumeView/components/View/ResumePosition';
import useGetMColumnSize from 'src/pages/ResumeView/hooks/useGetMColumnSize';
import { EDIT, resumePermissionType, VIEW } from 'src/utils/constants/resumePermission';

import EmployerBlackList from 'src/pages/ResumeView/components/ResumeHeader/BlackList';
import CandidateForVacancy from 'src/pages/ResumeView/components/ResumeHeader/CandidateForVacancy';
import ResumeContacts from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumeContacts';
import FindJobHhPro from 'src/pages/ResumeView/components/ResumeHeader/FindJobHhPro';
import Map from 'src/pages/ResumeView/components/ResumeHeader/Map';
import ResumeButtons from 'src/pages/ResumeView/components/ResumeHeader/ResumeButtons';
import ResumeCrosspostedBadge from 'src/pages/ResumeView/components/ResumeHeader/ResumeCrosspostedBadge';
import ResumeJobSearchStatus from 'src/pages/ResumeView/components/ResumeHeader/ResumeJobSearchStatus';
import ResumeLastActivityTime from 'src/pages/ResumeView/components/ResumeHeader/ResumeLastActivityTime';
import ResumeMedia from 'src/pages/ResumeView/components/ResumeHeader/ResumeMedia';
import ResumeName from 'src/pages/ResumeView/components/ResumeHeader/ResumeName';
import ResumePersonalGender from 'src/pages/ResumeView/components/ResumeHeader/ResumePersonalGender';
import ResumePersonalLocation from 'src/pages/ResumeView/components/ResumeHeader/ResumePersonalLocation';
import ResumeUpdateDate from 'src/pages/ResumeView/components/ResumeHeader/ResumeUpdateDate';
import WantWork from 'src/pages/ResumeView/components/ResumeHeader/WantWork';

// tempexp_23996_next-line
import styles from './index.less';

const ResumeHeader = ({ isEmptyResume, permission, userType }) => {
    const canEdit = permission === EDIT;
    const isEmployer = userType === UserType.Employer;
    const isAnonymous = userType === UserType.Anonymous;
    const isProfile = useProfile();
    const isPrint = usePrint();

    // tempexp_23996_start
    const { breakpoint, isMobile } = useBreakpoint();
    const isHhru = useIsHhru();
    const isHrlinkAdvVisible = useExperiment('tempexp_23996-adv', isHhru);
    const isHrlinkNativeVisible = useExperiment('tempexp_23996-native', isHhru, !isHrlinkAdvVisible);
    // tempexp_23996_end
    const { mMain, mSidebar } = useGetMColumnSize();
    const isAvailableHhPro = useSelector(({ isAvailableHhPro }) => isAvailableHhPro);
    const resume = useSelector(({ resume }) => resume);

    const [isFeedbackBannerExpAvailable, feedbackBannerAppearanceDelay, feedbackBannerAppearanceChance] =
        useBannerSettings(true);
    const [shouldRenderBanner, bannerAppearanceDelay, handleContactClick] = useBannerProps(
        resume.id,
        isFeedbackBannerExpAvailable,
        feedbackBannerAppearanceDelay,
        feedbackBannerAppearanceChance
    );

    const renderAdditional = () => {
        let content;
        if (!isEmptyResume && (canEdit || isEmployer)) {
            content = (
                <>
                    <div className="resume-header-separator" />
                    <ResumeButtons />
                    <div className="resume-header-additional__update-date">
                        <Text size={TextSize.Small} importance={TextImportance.Tertiary}>
                            <ResumeUpdateDate />
                        </Text>
                    </div>
                    {isAvailableHhPro && <FindJobHhPro />}
                </>
            );
        } else if (permission === VIEW && !isAnonymous) {
            content = (
                <>
                    <div className="resume-header-separator" />
                    <ResumeButtons />
                </>
            );
        }
        return (
            <div className="resume-header-additional">
                <Column xs="4" s="8" m={mSidebar} l="4">
                    {content}
                </Column>
                <CandidateForVacancy />
                {/* tempexp_23996_start */}
                {isEmployer && (isHrlinkAdvVisible || isHrlinkNativeVisible) && (
                    <Column xs="4" s="8" m={mSidebar} l="4">
                        {isHrlinkNativeVisible && (
                            <>
                                <VSpacing default={0} gteM={32} />
                                {![Breakpoint.S, Breakpoint.XS].includes(breakpoint) && <Divider />}
                            </>
                        )}
                        <VSpacing default={0} gteM={32} />
                        <div className={styles.container}>
                            <HSpacing default={0} gteM={16} />
                            <HrlinkBanner type={isHrlinkAdvVisible ? 'adv' : 'native'} utmContent="resume" />
                        </div>
                        <VSpacing default={32} />
                    </Column>
                )}
                {/* tempexp_23996_end */}
            </div>
        );
    };

    return (
        <Column xs="4" s="8" m="12" l="16" container>
            <div className="resume-header-wrapper">
                <div className="resume-header">
                    <Column xs="4" s="8" m={mMain} l="12">
                        <Gap bottom={!isProfile || isMobile}>
                            <ResumeEmployerTransitions onContactsClick={handleContactClick} />
                            <div className="resume-header-main" id={CONTENT_ID}>
                                <div className="resume-header-title">
                                    <div className="resume-header-photo-mobile" data-qa="resume-photo-mobile">
                                        <ResumeMedia />
                                        <ResumeCrosspostedBadge />
                                    </div>
                                    {isProfile && <ResumePosition />}
                                    {!isProfile && !isPrint && <ResumeLastActivityTime />}

                                    {!isProfile && <ResumeName />}

                                    <ResumeAnonymousWarning />
                                    {isEmployer && <EmployerBlackList />}
                                    <ResumePersonalGender />
                                    <ResumeJobSearchStatus />
                                    <ResumePersonalLocation />
                                    {shouldRenderBanner && (
                                        <ContactFeedbackBanner
                                            paddingSize="large"
                                            renderDelay={bannerAppearanceDelay}
                                            resumeId={resume.id}
                                            limitedWidth
                                            isPage
                                        />
                                    )}
                                    <ResumeContacts onContactsClick={handleContactClick} />
                                    {isEmployer && <WantWork />}
                                    {(canEdit || isEmployer) && (
                                        <div className="resume-header-print-update-date">
                                            <ResumeUpdateDate />
                                        </div>
                                    )}
                                </div>
                                <div className="resume-header-photo-desktop" data-qa="resume-photo-desktop">
                                    <ResumeMedia />
                                    <ResumeCrosspostedBadge />
                                </div>
                            </div>
                            <Map />
                        </Gap>
                    </Column>
                    {renderAdditional()}
                </div>
            </div>
        </Column>
    );
};

ResumeHeader.propTypes = {
    permission: resumePermissionType.isRequired,
    /** Признак незаполненного резюме */
    isEmptyResume: PropTypes.bool.isRequired,
    userType: PropTypes.string,
};

export default connect((state) => ({
    permission: state.resume?.permission,
    isEmptyResume: state.resume?.percent === 0,
    userType: state.userType,
}))(ResumeHeader);
