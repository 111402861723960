import { DefaultRootState } from 'react-redux';

import { selectResumeStringField } from 'src/models/applicant/resume/selectors';
import { StringFieldType } from 'src/models/applicant/resume/types/common';

import {
    WorkSchedule,
    Employment,
    TravelTime,
    BusinessTripReadiness,
} from 'src/models/applicant/resume/blocks/additional/types';

export const selectResumeWorkSchedule = ({ applicantResume }: DefaultRootState): StringFieldType<WorkSchedule>[] =>
    applicantResume.workSchedule;

export const selectResumeEmployment = ({ applicantResume }: DefaultRootState): StringFieldType<Employment>[] =>
    applicantResume.employment;

export const selectResumeTravelTime = selectResumeStringField<TravelTime>('travelTime', 'any');

export const selectResumeBusinessTripReadiness = selectResumeStringField<BusinessTripReadiness>(
    'businessTripReadiness',
    'never'
);
