import { ComponentType, useState } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Card, VSpacingContainer, Text } from '@hh.ru/magritte-ui';

import AdditionalModal from 'src/pages/ResumeView/redesign/additional/BottomSheet';
import BusinessTripReadinessEditor from 'src/pages/ResumeView/redesign/additional/Edit/BusinessTripReadinessEditor';
import EmploymentEditor from 'src/pages/ResumeView/redesign/additional/Edit/EmploymentEditor';
import TravelTimeEditor from 'src/pages/ResumeView/redesign/additional/Edit/TravelTimeEditor';
import WorkScheduleEditor from 'src/pages/ResumeView/redesign/additional/Edit/WorkScheduleEditor';
import EditButton from 'src/pages/ResumeView/redesign/common/EditButton';

import BusinessTripReadiness from 'src/pages/ResumeView/redesign/additional/Card/BusinessTripReadiness';
import Employment from 'src/pages/ResumeView/redesign/additional/Card/Employment';
import TravelTime from 'src/pages/ResumeView/redesign/additional/Card/TravelTime';
import WorkSchedule from 'src/pages/ResumeView/redesign/additional/Card/WorkSchedule';

import styles from './styles.less';

const TrlKeys = {
    label: 'resume.editor.additional.title',
    button: 'resume.editor.additional.button',
};

const ApplicantResumeAdditional: ComponentType = translation(({ trls }) => {
    const [editorMobileVisible, setEditorMobileVisible] = useState(false);

    const handleOpenEditorMobile = () => setEditorMobileVisible(true);
    const handleCloseEditorMobile = () => setEditorMobileVisible(false);

    return (
        <>
            <VSpacingContainer default={12}>
                <Text typography="label-2-regular" style="secondary">
                    {trls[TrlKeys.label]}
                </Text>
                <Card stretched padding={12} borderRadius={24} showBorder>
                    <VSpacingContainer default={24}>
                        <div className={styles.cardContent}>
                            <WorkSchedule />
                            <Employment />
                            <TravelTime />
                            <BusinessTripReadiness />
                        </div>
                        <EditButton onClick={handleOpenEditorMobile}>{trls[TrlKeys.button]}</EditButton>
                    </VSpacingContainer>
                </Card>
            </VSpacingContainer>
            <AdditionalModal visible={editorMobileVisible} onClose={handleCloseEditorMobile} />
            <WorkScheduleEditor />
            <EmploymentEditor />
            <TravelTimeEditor />
            <BusinessTripReadinessEditor />
        </>
    );
});

export default ApplicantResumeAdditional;
