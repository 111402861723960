import { useCallback } from 'react';

import { usePush } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import { H3 } from 'bloko/blocks/header';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { updateUrl } from 'Modules/url';
import paths from 'src/app/routePaths';
import translation from 'src/components/translation';
import { TariffProductType } from 'src/models/price/product.types';
import { isResumeAccessProduct } from 'src/utils/price/products';

const TrlKeys = {
    titleSnippetZp: 'employer.zp.snippet.title',
    buyAccessResumeZp: 'employer.zp.buy.contacts',
};

interface BuyContactsZpProps {
    currentProduct: TariffProductType;
}

const BuyContactsZp: TranslatedComponent<BuyContactsZpProps> = ({ trls, currentProduct }) => {
    const push = usePush();

    const handleClick = useCallback(() => {
        const urlRedirect = isResumeAccessProduct(currentProduct) ? paths.priceZpResumeAccess : paths.priceZpBundles;

        push(updateUrl(urlRedirect, { priceRegionId: currentProduct?.region }));
    }, [currentProduct, push]);

    return (
        <>
            <H3>{trls[TrlKeys.titleSnippetZp]}</H3>
            <VSpacing base={4} />
            <Button kind={ButtonKind.Inversed} data-qa="buy-contacts-zp" onClick={handleClick}>
                {trls[TrlKeys.buyAccessResumeZp]}
            </Button>
        </>
    );
};

export default translation(BuyContactsZp);
