import { Text } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Markup from 'src/components/Markup';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';

const TrlKeys = {
    title: 'rb.title.skills',
};

const ResumeAbout: TranslatedComponent = ({ trls }) => {
    const about = useSelector((state) => state.resume?.skills.value);
    if (!about) {
        return null;
    }

    return (
        <ResumeBlock title={trls[TrlKeys.title]}>
            <Text typography="paragraph-2-regular">
                <Markup>{about}</Markup>
            </Text>
        </ResumeBlock>
    );
};

export default translation(ResumeAbout);
