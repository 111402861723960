import { Banner, Text, Button, VSpacing, useBreakpoint } from '@hh.ru/magritte-ui';
import { Link as SpaLink } from '@hh.ru/redux-spa-middleware';
import BlokoButton, { ButtonAppearance } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import { H3 } from 'bloko/blocks/header';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import { additionalCheckArticleLink } from 'src/components/Employer/AdditionalCheck';
import translation from 'src/components/translation';

const TrlKeys = {
    checkIsRunning: 'additionalCheck.checkIsRunning',
    restrictions: 'additionalCheck.restrictions',
    dueDate: 'additionalCheck.dueDate',
    infoButton: 'additionalCheck.infoButton',
    title: 'search.resume.banner.additionalCheck.title',
    desc: 'search.resume.banner.additionalCheck.desc',
    desc2: 'search.resume.banner.additionalCheck.desc2',
    button: 'search.resume.banner.additionalCheck.button',
};

const AdditionalCheckSnippet: TranslatedComponent<{ isMagritte?: boolean }> = ({ isMagritte, trls }) => {
    const { isMobile } = useBreakpoint();

    return isMagritte ? (
        <Banner
            stretched
            visible
            showBorder
            showClose={false}
            style="warning"
            buttonBase={
                <Button
                    size="small"
                    mode="primary"
                    style="accent"
                    stretched={isMobile}
                    Element={SpaLink}
                    to={additionalCheckArticleLink}
                    data-qa="Additional-check-snippet-button"
                >
                    {trls[TrlKeys.button]}
                </Button>
            }
            title={<Text typography="title-5-semibold">{trls[TrlKeys.title]}</Text>}
            content={
                <>
                    <Text typography="paragraph-2-regular">{trls[TrlKeys.desc]}</Text>
                    <VSpacing default={8} />
                    <Text typography="paragraph-2-regular">{trls[TrlKeys.desc2]}</Text>
                </>
            }
        />
    ) : (
        <>
            <H3>{trls[TrlKeys.checkIsRunning]}</H3>
            <VSpacing default={16} />
            <p>{trls[TrlKeys.restrictions]}</p>
            <VSpacing default={8} />
            <p>{trls[TrlKeys.dueDate]}</p>
            <VSpacing default={16} />
            <BlokoButton
                to={additionalCheckArticleLink}
                Element={SpaLink}
                appearance={ButtonAppearance.Outlined}
                data-qa="Additional-check-snippet-button"
            >
                {trls[TrlKeys.infoButton]}
            </BlokoButton>
            <HSpacing base={7} />
        </>
    );
};

export default translation(AdditionalCheckSnippet);
