import { useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import ResumeEducationItem from 'src/pages/ResumeView/employerRedesign/components/ResumeEducation/ResumeEducationItem';

const TrlKeys = {
    title: 'resume.block.education.courses.title',
};

const ResumeEducationCourses: TranslatedComponent = ({ trls }) => {
    const additionalEducation = useSelector((state) => state.resume?.additionalEducation.value);
    if (!additionalEducation?.length) {
        return null;
    }

    return (
        <ResumeBlock title={trls[TrlKeys.title]}>
            <VSpacingContainer default={16}>
                {additionalEducation &&
                    additionalEducation.map(({ id, name, year, organization, result }) => (
                        <ResumeEducationItem
                            key={id}
                            name={name}
                            year={year}
                            organization={organization}
                            result={result}
                        />
                    ))}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeEducationCourses);
