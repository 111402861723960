import { selectResumeEditorBusinessTripReadiness } from 'src/models/applicant/resume/blocks/additional/edit/selectors';
import { selectResumeBusinessTripReadiness } from 'src/models/applicant/resume/blocks/additional/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

type BusinessTripReadinessBlock = EditorConfig<Pick<ApplicantResumeEditorFields, 'businessTripReadiness'>>;

export const businessTripReadinessConfig: BusinessTripReadinessBlock = {
    init: (store) => ({
        businessTripReadiness: selectResumeBusinessTripReadiness(store),
    }),
    changed: (store) => {
        const editorBusinessTripReadiness = selectResumeEditorBusinessTripReadiness(store);
        const resumeBusinessTripReadiness = selectResumeBusinessTripReadiness(store);

        return editorBusinessTripReadiness !== resumeBusinessTripReadiness;
    },
    prepare: ({ businessTripReadiness }) => ({
        businessTripReadiness: [{ string: businessTripReadiness }],
    }),
    validate: ({ businessTripReadiness }, conditions) => ({
        ...validateEditorFieldByKey('businessTripReadiness', businessTripReadiness, conditions),
    }),
};
