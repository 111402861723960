import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Alert, Button } from '@hh.ru/magritte-ui';
import { ExclamationTriangleOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import { forceDeleteFieldResumeEditor } from 'src/models/applicant/resume/editor/store/actions/delete';
import {
    selectResumeEditorConfirmTitle,
    selectResumeEditorDeleteConfirm,
} from 'src/models/applicant/resume/editor/store/selectors';
import { hideResumeEditorConfirm } from 'src/models/applicant/resume/editor/store/slice';

const TrlKeys = {
    cancel: 'resume.editor.question.delete.cancel',
    approve: 'resume.editor.question.delete.approve',
};

const EditorDeleteConfirm = translation(({ trls }) => {
    const dispatch = useDispatch();
    const title = useSelector(selectResumeEditorConfirmTitle);
    const visible = useSelector(selectResumeEditorDeleteConfirm);

    const handleCancel = () => {
        dispatch(hideResumeEditorConfirm());
    };

    const handleApprove = () => {
        dispatch(hideResumeEditorConfirm());
        void dispatch(forceDeleteFieldResumeEditor());
    };

    const handleClose = () => dispatch(hideResumeEditorConfirm());

    return (
        <Alert
            title={title}
            visible={visible}
            onClose={handleClose}
            layout="vertical"
            iconStyle="warning"
            icon={<ExclamationTriangleOutlinedSize24 />}
            buttons={
                <>
                    <Button stretched mode="primary" style="negative" onClick={handleApprove}>
                        {trls[TrlKeys.approve]}
                    </Button>
                    <Button stretched mode="secondary" style="neutral" onClick={handleCancel}>
                        {trls[TrlKeys.cancel]}
                    </Button>
                </>
            }
        />
    );
});

export default EditorDeleteConfirm;
