import { ResumeCardData } from 'src/models/resumeCard';
import { Criteria } from 'src/models/resumeSearchCriteria';
import fetcher from 'src/utils/fetcher';

const GET_RELATED_RESUMES_URL = '/related_resumes';

export interface RelatedResumes {
    resumes: ResumeCardData[];
    totalUngroupedResults: number;
    searchCriteria: Criteria;
}

interface FetchRelatedResumesParams {
    resume: string;
}

declare global {
    interface FetcherGetApi {
        [GET_RELATED_RESUMES_URL]: {
            queryParams: FetchRelatedResumesParams;
            response: RelatedResumes;
        };
    }
}

interface FetchRelatedResumesArgs {
    resumeHash: string;
}

const fetchRelatedResumes = async ({ resumeHash }: FetchRelatedResumesArgs): Promise<RelatedResumes | null> => {
    try {
        const response: RelatedResumes = await fetcher.get(GET_RELATED_RESUMES_URL, {
            params: {
                resume: resumeHash,
            },
        });
        return response || null;
    } catch (error) {
        console.error(error);
    }

    return null;
};

export default fetchRelatedResumes;
