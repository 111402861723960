import { UpdateBodyType } from 'src/models/applicant/resume/editor/types';
import { EditorFieldError } from 'src/models/applicant/resume/editor/types/error';
import { ApplicantResumeDTO } from 'src/models/applicant/resume/types/resume';
import fetcher from 'src/utils/fetcher';

export interface ApiSuccess {
    resume: ApplicantResumeDTO;
}
export interface ApiConditionsErrors {
    errors: EditorFieldError[];
}

declare global {
    interface FetcherPostApi {
        '/applicant/resume/edit': {
            body: UpdateBodyType;
            response: ApiSuccess | ApiConditionsErrors;
            queryParams: { resume: string; hhtmSource: string };
        };
    }
}

export const ResumeApi = {
    update(resume: string, hhtmSource: string, body: UpdateBodyType): Promise<ApiSuccess | ApiConditionsErrors> {
        return fetcher
            .post('/applicant/resume/edit', body, { params: { resume, hhtmSource } })
            .then(({ data }) => data);
    },
};
