import { FC, PropsWithChildren } from 'react';

import { ActionList, BottomSheet, Drop, DropProps } from '@hh.ru/magritte-ui';

import styles from './styles.less';

export type MenuType = 'drop' | 'actionList';

interface MenuProps extends DropProps {
    type?: MenuType;
    onClose: () => void;
    minimalPaddings?: boolean;
}

const MinimalPaddingsWrapper: FC<PropsWithChildren<{ visible?: boolean }>> = ({ children, visible }) =>
    visible ? <div className={styles.minimalPaddingsWrapper}>{children}</div> : <>{children}</>;

const Menu: FC<MenuProps> = ({
    type = 'actionList',
    visible = false,
    onClose,
    children,
    space,
    minimalPaddings,
    ...dropProps
}) => {
    if (type === 'drop') {
        return (
            <>
                <Drop
                    {...dropProps}
                    visible={visible}
                    onClose={onClose}
                    space={minimalPaddings ? 0 : space}
                    keyboardNavigationMode="list"
                    autoFocusWhenOpened
                >
                    <MinimalPaddingsWrapper visible={minimalPaddings}>{children}</MinimalPaddingsWrapper>
                </Drop>
                <BottomSheet height="content" visible={visible} onClose={onClose}>
                    {children}
                </BottomSheet>
            </>
        );
    }

    return (
        <ActionList visible={visible} onClose={onClose} dropProps={dropProps}>
            {children}
        </ActionList>
    );
};

export default Menu;
