import { DefaultRootState } from 'react-redux';
import { Dispatch } from 'redux';

import { ResumeApi } from 'src/models/applicant/resume/api';
import { isSuccess } from 'src/models/applicant/resume/api/lib';
import { showNotification } from 'src/models/applicant/resume/editor/store/slice';
import { Thunk } from 'src/models/applicant/resume/editor/types/actions';
import { selectApplicantResume, selectHhtmSource, selectResumeHash } from 'src/models/applicant/resume/selectors';
import { setApplicantResumeFields } from 'src/models/applicant/resume/slice';

import { selectApplicantResumeProftest } from 'src/models/applicant/resume/blocks/proftest/selector';

export const saveResumeProftest = (attached: boolean): Thunk => {
    return async (dispatch: Dispatch, getState: () => DefaultRootState): Promise<void> => {
        const resume = selectApplicantResume(getState());
        const currentProftest = selectApplicantResumeProftest(getState());

        // если мы хотим открепить от резюме, мы должны послать пустой массив.
        // Don't ask why
        const proftest = attached ? [{ ...currentProftest, attached }] : [{}];

        dispatch(setApplicantResumeFields({ ...resume, proftest: [{ ...currentProftest, attached }] }));

        try {
            const resumeHash = selectResumeHash(getState());
            const hhtmSource = selectHhtmSource(getState());

            const response = await ResumeApi.update(resumeHash, hhtmSource, { proftest });

            if (isSuccess(response)) {
                dispatch(setApplicantResumeFields(response.resume));
                return;
            }

            dispatch(setApplicantResumeFields(resume));
            dispatch(showNotification('error-api'));
            // mb sentry log
        } catch (_) {
            dispatch(setApplicantResumeFields(resume));
            dispatch(showNotification('error-api'));
            // mb sentry log
        }
    };
};
