import { ComponentType } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import PhoneAdd from 'src/pages/ResumeView/redesign/contacts/Editor/Phone/Add';
import PhoneFieldList from 'src/pages/ResumeView/redesign/contacts/Editor/Phone/FieldList';

const Phone: ComponentType = () => {
    return (
        <VSpacingContainer default={24}>
            <PhoneFieldList />
            <PhoneAdd />
        </VSpacingContainer>
    );
};

export default Phone;
