import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorFieldError } from 'src/models/applicant/resume/editor/types/error';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { keys } from 'src/models/applicant/resume/lib/common/object';
import { Condition } from 'src/models/applicant/resume/types/conditions';
import { ResumeConditions } from 'src/models/applicant/resume/types/resume';

import { validateCount, validatePart } from 'src/models/applicant/resume/editor/form/validate/validators';

export const validateEditorField = (
    field: keyof ApplicantResumeEditorFields,
    // тут нам все равно какой тип
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: Array<Record<string, any>>,
    condition?: Condition
): EditorFieldError[] => {
    if (!condition) {
        return [];
    }

    const countErrors = validateCount(field, values, condition);
    if (countErrors) {
        return countErrors;
    }

    const parts = condition?.parts;

    if (!parts) {
        return [];
    }

    return values.reduce<EditorFieldError[]>((errors, value, index) => {
        const elementErrors = parts.reduce<EditorFieldError[]>((result, part) => {
            const key = keys(part)[0];

            // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
            const code = validatePart(value[key], part[key]);

            if (code) {
                return [
                    ...result,
                    {
                        field,
                        code,
                        type: 'CONDITION',
                        // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
                        value: value[key],
                        message: '',
                        fieldNamePath: `/${field}/${index}/${key}`,
                    },
                ];
            }
            return result;
        }, []);

        return [...errors, ...elementErrors];
    }, []);
};

export const validateEditorFieldByKey = <Key extends keyof ApplicantResumeEditorFields>(
    field: Key,
    // тут нам все равно какой тип
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    values: Array<Record<string, any>>,
    conditions: ResumeConditions
): Record<string, EditorFieldError[]> => {
    const condition = conditions[field];
    const errors = validateEditorField(field, values, condition);

    if (isEmpty(errors)) {
        return {};
    }

    return {
        [field]: errors,
    };
};
