import { FC, PropsWithChildren } from 'react';

import { FormLabel, VSpacing } from '@hh.ru/magritte-ui';

type Props = { label: string; htmlFor: string };

const EditorField: FC<PropsWithChildren<Props>> = ({ children, label, htmlFor }) => (
    <div>
        <FormLabel htmlFor={htmlFor}>{label}</FormLabel>
        <VSpacing default={12} />
        {children}
    </div>
);

export default EditorField;
