import { DefaultRootState } from 'react-redux';

import { selectApplicantResumeAttestationEducation } from 'src/models/applicant/resume/blocks/attestationEducation/selectors';
import { NEW_ID } from 'src/models/applicant/resume/editor/lib';
import { selectResumeEditorPayload } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { AdditionalEducation } from 'src/models/resume/resumeCommon.types';

export const getNewAttestationEducation = (): AdditionalEducation => ({
    id: NEW_ID,
    name: '',
    year: 0,
    result: '',
    organization: '',
});

export const isNewAttestationEducationChanged = ({ name, organization, result, year }: AdditionalEducation): boolean =>
    name !== '' || organization !== '' || result !== '' || year !== 0;

export const getSelctedAttestationEducationByIndex = (store: DefaultRootState): AdditionalEducation => {
    const { index } = selectResumeEditorPayload(store) as PayloadType<{ index: number }>;
    const attestationEducation = selectApplicantResumeAttestationEducation(store);

    const education = attestationEducation[index];
    if (education) {
        return {
            id: education.id,
            name: education.name === null ? '' : education.name,
            year: education.year === null ? 0 : education.year,
            result: education.result === null ? '' : education.result,
            organization: education.organization === null ? '' : education.organization,
        };
    }
    return attestationEducation[index] || getNewAttestationEducation();
};
