import { FC } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import ResumeAbout from 'src/pages/ResumeView/employerRedesign/components/ResumeAbout';
import ResumeAdditionalInfo from 'src/pages/ResumeView/employerRedesign/components/ResumeAdditionalInfo';
import ResumeCreds from 'src/pages/ResumeView/employerRedesign/components/ResumeCreds';
import ResumeEducation from 'src/pages/ResumeView/employerRedesign/components/ResumeEducation';
import ResumeEducationCourses from 'src/pages/ResumeView/employerRedesign/components/ResumeEducationCourses';
import ResumeEducationTests from 'src/pages/ResumeView/employerRedesign/components/ResumeEducationTests';
import ResumeLanguages from 'src/pages/ResumeView/employerRedesign/components/ResumeLanguages';
import ResumeRelated from 'src/pages/ResumeView/employerRedesign/components/ResumeRelated';
import ResumeSkills from 'src/pages/ResumeView/employerRedesign/components/ResumeSkills';

const ResumeBody: FC = () => {
    return (
        <VSpacingContainer default={24}>
            <ResumeSkills />
            <ResumeLanguages />
            <ResumeAbout />
            <ResumeEducation />
            <ResumeCreds />
            <ResumeEducationCourses />
            <ResumeEducationTests />
            <ResumeAdditionalInfo />
            <ResumeRelated />
        </VSpacingContainer>
    );
};

export default ResumeBody;
