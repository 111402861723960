import { isObject } from 'Utils/Utils';

export const flatten = (metaData) => {
    const result = {};
    Object.keys(metaData).forEach((item) => {
        if (isObject(metaData[item])) {
            const child = flatten(metaData[item]);
            Object.keys(child).forEach((key) => {
                if (result[key]) {
                    result[key] += `, ${child[key]}`;
                } else {
                    result[key] = child[key];
                }
            });
        } else {
            result[item] = metaData[item];
        }
    });
    return result;
};

const getMetaData = (geoObject) => {
    return geoObject.properties.get('metaDataProperty.GeocoderMetaData');
};

const getPart = (part, geoObject) => {
    return flatten(getMetaData(geoObject))[part];
};

const isPartEqual = (part, a, b) => {
    return getPart(part, a) === getPart(part, b);
};

const convertGeoObject = (object) => {
    return flatten(getMetaData(object));
};

const join = (array) => {
    return array
        .filter((item) => {
            return item;
        })
        .join(', ');
};

const geoObjectFromAddress = (address, ymaps) => {
    let marker;
    if (address.marker) {
        marker = [address.marker['@lat'], address.marker['@lng']];
    } else {
        marker = [ymaps.geolocation.latitude, ymaps.geolocation.longitude];
    }
    return new ymaps.GeoObject({
        geometry: {
            type: 'Point',
            coordinates: marker,
        },
        properties: {
            metaDataProperty: {
                GeocoderMetaData: {
                    AddressLine: join([address.street, address.building]) || address.rawAddress,
                    LocalityName: address.city,
                    ThoroughfareName: address.street,
                    PremiseNumber: address.building,
                },
            },
            name: address.displayName,
        },
    });
};

const getStreet = (converted) => {
    return join([
        converted.DependentLocalityName,
        converted.ThoroughfareName,
        converted.ThoroughfareNumber,
        converted.DependentThoroughfareName,
        converted.DependentThoroughfareNumber,
    ]);
};

const getMetro = (parts) => {
    if (!Array.isArray(parts)) {
        return {
            line: '',
            metro: '',
            address: '',
        };
    }

    const line = parts.find((item) => item.kind === 'route');
    const metro = parts.find((item) => item.kind === 'metro');

    return {
        line: line?.name || '',
        metro: metro?.name || '',
        address: line && metro ? `${line.name}, ${metro.name}` : '',
    };
};

const getBuilding = (converted) => {
    return join([converted.PremiseName, converted.PremiseNumber, converted.SubPremiseName, converted.SubPremiseNumber]);
};

const addressFromYandex = (geoObject, map, metroStations, description, id) => {
    const converted = convertGeoObject(geoObject);
    const isMetroOnlyAddress = converted.kind?.split(',').includes('metro') && metroStations.length;
    const coordinates = geoObject.geometry.getCoordinates();
    const mapCenter = map.getCenter();
    const street = getStreet(converted);
    const building = getBuilding(converted);
    let addressLine = getStreet(converted);
    const { address, line, metro } = getMetro(
        geoObject.properties.get('metaDataProperty.GeocoderMetaData.Address.Components')
    );

    if (!isMetroOnlyAddress) {
        if (addressLine.length !== 0) {
            addressLine += `, ${building}`;
        } else {
            addressLine = building;
        }
    }

    if (!addressLine.length) {
        addressLine = address;
    }

    const fullAddress = join([converted.LocalityName, addressLine]) || converted.AddressLine;
    const mapData = {
        points: {
            center: {
                lat: mapCenter[0],
                lng: mapCenter[1],
                zoom: map.getZoom(),
            },
        },
    };
    mapData.points.marker = {
        lat: coordinates[0],
        lng: coordinates[1],
    };
    return {
        fullAddress,
        renderedAddress: join([fullAddress].concat(metroStations.map((item) => item.renderedName))),
        addressLine,
        city: converted.LocalityName || converted.SubAdministrativeAreaName || converted.AdministrativeAreaName,
        street: isMetroOnlyAddress || !street ? line : street,
        building: isMetroOnlyAddress || !building ? metro : building,
        description,
        mapData: JSON.stringify(mapData),
        metroStationId: metroStations.map((item) => item.id),
        metroStations,
        marker: coordinates.join(' '),
        id,
    };
};

const prettyPrint = (address) => {
    const prettyAddress = [];
    if (address.city) {
        prettyAddress.push(address.city);
    }
    if (address.street) {
        prettyAddress.push(address.street);
    }
    if (address.building) {
        prettyAddress.push(address.building);
    }
    if (address.metro) {
        prettyAddress.push(address.metro);
    }
    if (!address.city && !address.street) {
        prettyAddress.push(address.rawAddress);
    }
    return prettyAddress.join(', ');
};

export default {
    isPartEqual,
    convertGeoObject,
    geoObjectFromAddress,
    join,
    getStreet,
    getBuilding,
    addressFromYandex,
    prettyPrint,
};
