import { ComponentType } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import PhoneField from 'src/pages/ResumeView/redesign/contacts/Editor/Phone/Field';
import { FIELD_NAME } from 'src/pages/ResumeView/redesign/contacts/Editor/Phone/const';

const PhoneFieldList: ComponentType = () => {
    const { value: phone } = useResumeEditorField(FIELD_NAME);
    return (
        <VSpacingContainer default={16}>
            {phone.map(({ type, comment, formatted }) => (
                <PhoneField key={type} type={type} comment={comment} value={formatted} />
            ))}
        </VSpacingContainer>
    );
};

export default PhoneFieldList;
