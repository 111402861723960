import { fetchProfessionalRoleTree } from 'src/hooks/useFetchProfessionalRoleTree';
import { Lang } from 'src/models/langs';
import fetcher from 'src/utils/fetcher';

import { RolesCatalogDTO } from 'src/models/applicant/resume/api/dto';

const ROLES_CATALOG_URL = '/shards/roles_catalog';

declare global {
    interface FetcherGetApi {
        [ROLES_CATALOG_URL]: {
            queryParams: {
                lang: Lang;
            };
            response: RolesCatalogDTO;
        };
    }
}

export const fetchRolesCatalog = (lang: Lang): Promise<RolesCatalogDTO> =>
    fetcher.get(ROLES_CATALOG_URL, {
        params: { lang },
    });

export const fetchProfessionalRole = fetchProfessionalRoleTree;
