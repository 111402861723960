import { Component, Fragment, createRef } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import Analytics from '@hh.ru/analytics-js';
import Button, { ButtonKind, ButtonType } from 'bloko/blocks/button';
import { FormSpacer } from 'bloko/blocks/form';
import { H2 } from 'bloko/blocks/header';
import Modal, { ModalFooter } from 'bloko/blocks/modal';
import VSpacing from 'bloko/blocks/vSpacing';

import sendHHAnalytics from 'HH/SendHHAnalytics';
import Form from 'src/components/Form';
import translation from 'src/components/translation';
import fetcher from 'src/utils/fetcher';

class ResumeDelete extends Component {
    static propTypes = {
        hash: PropTypes.string.isRequired,
        backUrl: PropTypes.string.isRequired,
        hhtmSource: PropTypes.string,
        from: PropTypes.string.isRequired,
        render: PropTypes.func.isRequired,
        trls: PropTypes.object.isRequired,
    };

    static trls = {
        deleteAction: 'resumeList.actionline.delete',
        modalHeader: 'resume.delete.modal.header',
        modalDeleteAttention: 'resume.delete.modal.text.1',
        modalHiddenHint: 'resume.delete.modal.text.2',
        modalHideAction: 'resume.delete.modal.button.hide',
        modalDeleteAction: 'resume.delete.modal.button.confirm',
        modalCancelAction: 'resume.delete.modal.button.cancel',
    };

    state = {
        showModal: false,
    };

    formDeleteResume = createRef();

    onClick = () => {
        this.setState({
            showModal: true,
        });

        Analytics.sendEvent('applicant', 'resume-delete-attempt', 'try_to_delete');
        sendHHAnalytics({
            toStorage: 'hh',
            data: {
                from: this.props.from,
                goal: 'resume-delete',
            },
        });
    };

    onClose = () => {
        this.setState({ showModal: false });
        Analytics.sendEvent('applicant', 'resume-delete-attempt', 'cancel');
    };

    onSubmit = (event) => event.stopPropagation();

    onDelete = async (event) => {
        event.stopPropagation();
        event.preventDefault();
        const formData = new FormData(this.formDeleteResume.current);

        await fetcher
            .postFormData('/applicant/deleteresume?from=resume-delete&hhtmFromLabel=resume-delete', formData)
            .then(({ data }) => {
                Analytics.sendEvent('applicant', 'resume-delete-complete-beacon', 'complete_delete', '', true);
                window.location.assign(data.url);
            })
            .catch(console.error);
    };

    renderModal() {
        const { hash, backUrl, trls, hhtmSource } = this.props;
        return (
            <Modal visible={this.state.showModal} onClose={this.onClose}>
                <div className="resume-delete-modal">
                    <H2>{trls[ResumeDelete.trls.modalHeader]}</H2>
                    <VSpacing base={6} />
                    {trls[ResumeDelete.trls.modalDeleteAttention]}
                    <VSpacing base={2} />
                    {trls[ResumeDelete.trls.modalHiddenHint]}
                </div>

                <ModalFooter>
                    <div className="resume-delete-modal-buttons">
                        <Form
                            action="/applicant/resumes/edit/visibility?from=resume-delete&hhtmFromLabel=resume-delete"
                            method="POST"
                            onSubmit={this.onSubmit}
                        >
                            <Button kind={ButtonKind.Primary} type={ButtonType.Submit} data-qa="resume-delete-hide">
                                {trls[ResumeDelete.trls.modalHideAction]}
                            </Button>
                            <input type="hidden" name="resume" value={hash} />
                            <input type="hidden" name="accessType.string" value="no_one" />
                            <input type="hidden" name="backUrl" value={backUrl} />
                            <input type="hidden" name="nativeForm" />
                        </Form>

                        <FormSpacer>
                            <Form ref={this.formDeleteResume} onSubmit={this.onDelete}>
                                <Button type={ButtonType.Submit} data-qa="resume-delete-confirm">
                                    {trls[ResumeDelete.trls.modalDeleteAction]}
                                </Button>

                                <input type="hidden" name="hash" value={hash} />
                                {hhtmSource && <input type="hidden" name="hhtmSource" value={hhtmSource} />}
                                <input type="hidden" name="hhtmFrom" value="resume-delete" />
                            </Form>
                        </FormSpacer>

                        <div className="resume-delete-modal-cancel">
                            <Button type={ButtonType.Button} data-qa="resume-delete-confirm" onClick={this.onClose}>
                                {trls[ResumeDelete.trls.modalCancelAction]}
                            </Button>
                        </div>
                    </div>
                </ModalFooter>
            </Modal>
        );
    }

    render = () => (
        <Fragment>
            {this.props.render(this.onClick)}
            {this.renderModal()}
        </Fragment>
    );
}

export default connect((state) => ({
    backUrl: state.request.url,
    hhtmSource: state.analyticsParams.hhtmSource,
}))(translation(ResumeDelete));
