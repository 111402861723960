import { DefaultRootState } from 'react-redux';

import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';
import { selectResumeStringField } from 'src/models/applicant/resume/selectors';

export const selectResumeEditorOpenedAbout = selectResumeEditorOpenedType('about');

export const selectResumeEditorAbout = ({ resumeEditor }: DefaultRootState): string => resumeEditor.fields.skills || '';

export const selectApplicantResumeAbout = selectResumeStringField('skills', '');
