import { FC, PropsWithChildren } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import { Text, VSpacingContainer } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import LanguageTagInner from 'src/components/Languages/LanguageTagInner';
import translation from 'src/components/translation';
import { LanguageLevel } from 'src/models/resume/resumeCommon.types';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import ResumeTagsContainer from 'src/pages/ResumeView/employerRedesign/components/ResumeTagsContainer';

const TrlKeys = {
    title: 'resume.block.languages.title',
    levels: {
        native: 'resumeBuilder.languages.level.l1',
        others: 'resume.block.languages.others',
    },
};

const ResumeLanguagesBlock: FC<PropsWithChildren & { title: string }> = ({ title, children }) => {
    return (
        <VSpacingContainer default={8}>
            <Text style="secondary" typography="label-3-regular">
                {title}
            </Text>
            <ResumeTagsContainer>{children}</ResumeTagsContainer>
        </VSpacingContainer>
    );
};

const ResumeLanguages: TranslatedComponent = ({ trls }) => {
    const languages = useSelector((state) => state.resume?.language.value);
    const verifiedSkills = useSelector((state) => state.resume?.verifiedSkills);

    if (!languages?.length) {
        return null;
    }

    const nativeLanguage = languages.find((language) => language.degree === LanguageLevel.L1);
    const otherLanguages = languages.filter((language) => language.degree !== LanguageLevel.L1);

    if (!nativeLanguage) {
        return null;
    }

    return (
        <ResumeBlock title={trls[TrlKeys.title]}>
            <VSpacingContainer default={24}>
                <ResumeLanguagesBlock title={trls[TrlKeys.levels.native]}>
                    <LanguageTagInner isMagritte language={nativeLanguage} isVerified={false} />
                </ResumeLanguagesBlock>

                {otherLanguages?.length > 0 && (
                    <ResumeLanguagesBlock title={trls[TrlKeys.levels.others]}>
                        {otherLanguages.map((language) => {
                            const isVerified = !!verifiedSkills?.some(
                                (skill) => skill.id === language.id && skill.level?.internalId === language.degree
                            );

                            return (
                                <LanguageTagInner
                                    isMagritte
                                    language={language}
                                    isVerified={isVerified}
                                    key={language.id}
                                />
                            );
                        })}
                    </ResumeLanguagesBlock>
                )}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeLanguages);
