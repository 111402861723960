import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

import {
    selectApplicantResumeAbout,
    selectResumeEditorAbout,
} from 'src/models/applicant/resume/blocks/about/edit/selectors';

type AboutFields = 'skills';

export type AboutBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, AboutFields>>;

export const aboutConfig: AboutBlockConfig = {
    init: (store) => {
        return {
            skills: selectApplicantResumeAbout(store),
        };
    },

    changed: (store) => {
        const editorAbout = selectResumeEditorAbout(store);
        const resumeAbout = selectApplicantResumeAbout(store);

        return editorAbout !== resumeAbout;
    },

    prepare: ({ skills }) => ({
        skills: skills ? [{ string: `${skills}` }] : [],
    }),

    validate: ({ skills }, conditions) => {
        return {
            ...validateEditorFieldByKey('skills', skills, conditions),
        };
    },
};
