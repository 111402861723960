import { DefaultRootState } from 'react-redux';

import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { Recommendation } from 'src/models/resume/resumeCommon.types';

export const selectResumeEditorOpenedRecomendation = selectResumeEditorOpenedType('recommendation');

export const selectResumeEditorRecommendation = ({ resumeEditor }: DefaultRootState): Recommendation | undefined =>
    resumeEditor.fields.recommendation;

export const selectResumeEditorRecommendationIndex = ({ resumeEditor, applicantResume }: DefaultRootState): number => {
    const { index } = resumeEditor.payload as PayloadType<{ index: number }>;
    if (index === undefined) {
        return applicantResume.recommendation.length;
    }
    return index;
};
