import { FC, memo, PropsWithChildren } from 'react';
import classnames from 'classnames';

export enum AttentionKind {
    Bad = 'bad',
    Good = 'good',
}

interface AttentionProps {
    kind?: AttentionKind;
    corrected?: boolean;
}

const Attention: FC<AttentionProps & PropsWithChildren> = ({ kind, children, corrected }) => (
    <div
        className={classnames('attention', {
            [`attention_${kind || ''}`]: kind,
            'attention_corrected-width': corrected,
        })}
    >
        {children}
    </div>
);

export default memo(Attention);
