import LocalStorageWrapper from 'bloko/common/storage/LocalStorageWrapper';

import axios from 'HHC/Axios';

const CHECK_INTERVAL = 1000;
const _getStorageKey = (hash) => `nextTouchTimestampV2_${hash}`;

/**
 * @typedef {Object} TouchModule
 * @property {Function} send
 * @property {Function} destroyHandlers
 */

/**
 * @param {Object} params
 * @param {String} params.hash                 Хеш резюме.
 * @param {Number} params.timeLeft             Время до следующего обновления, мс.
 * @param {Number} params.updateInterval       Минимальный интервал обновления, мс.
 * @param {Function} params.onSuccess          Вызывается после успешного обновления резюме.
 * @param {Function} params.onError            Вызывается при ошибке обновления резюме.
 * @param {Function} params.onTimeLeftChange   Вызывается по таймеру с параметром `timeLeft`.
 * @param {Number} [params.checkInterval]      Интервал проверки оставшегося времени, мс.
 * @param {Function} [params.getStorageKey]    Получение ключа для хранения времени обновления
 *                                             в LocalStorage, вызывается с параметром `hash`.
 * @returns {TouchModule}
 */
function TouchModule({
    hash,
    timeLeft,
    updateInterval,
    onSuccess,
    onError,
    onTimeLeftChange,
    checkInterval = CHECK_INTERVAL,
    getStorageKey = _getStorageKey,
}) {
    const storageKey = getStorageKey(hash);
    let currentTimeLeft = Math.max(timeLeft, 0);
    let nextTouchTime;
    let timeout;

    function setNextTouchTime(value) {
        nextTouchTime = value;
        checkTimeLeft();
    }

    function checkTimeLeft() {
        const previousTimeLeft = currentTimeLeft;
        currentTimeLeft = Math.max(nextTouchTime - Date.now(), 0);
        window.clearTimeout(timeout);
        if (currentTimeLeft > 0) {
            timeout = window.setTimeout(checkTimeLeft, checkInterval);
        }
        if (currentTimeLeft !== previousTimeLeft) {
            onTimeLeftChange(currentTimeLeft);
        }
    }

    let requestIsSent = false;
    const send = function () {
        if (currentTimeLeft > 0 || requestIsSent) {
            return;
        }
        requestIsSent = true;
        axios
            .postFormData('/applicant/resumes/touch', {
                resume: hash,
                undirectable: true,
            })
            .then(() => {
                requestIsSent = false;
                setNextTouchTime(Date.now() + updateInterval);
                LocalStorageWrapper.setItem(storageKey, nextTouchTime);
                onSuccess();
            })
            .catch((error) => {
                requestIsSent = false;
                onError(error);
            });
    };

    const onStoreChange = (event) => {
        if (event.key === storageKey) {
            setNextTouchTime(event.newValue);
        }
    };

    window.addEventListener('storage', onStoreChange);
    const destroyHandlers = () => {
        window.clearTimeout(timeout);
        window.removeEventListener('storage', onStoreChange);
    };

    setNextTouchTime(Date.now() + currentTimeLeft);

    return { send, destroyHandlers };
}

export default TouchModule;
