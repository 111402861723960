import { ComponentType } from 'react';

import resumeEmailButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/resume/resume_email_button_click';
import { useSelector } from '@hh.ru/front-static-app';
import { Link, Text, VSpacingContainer } from '@hh.ru/magritte-ui';

import translation from 'src/components/translation';
import { getPreferredContactType, EMAIL } from 'src/models/applicant/resume/blocks/contacts/lib';
import { selectResumeEmail, selectResumePreferredContact } from 'src/models/applicant/resume/blocks/contacts/selectors';
import { selectResumeId, selectVacancyId } from 'src/models/applicant/resume/selectors';
import { EM_DASH, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    email: 'applicant.resume.contacts.field.email',
    preferredContact: 'applicant.resume.contacts.preferredContact.hint',
};

const ContactsEmail: ComponentType = translation(({ trls }) => {
    const email = useSelector(selectResumeEmail);
    const preferredContact = useSelector(selectResumePreferredContact);
    const resumeId = useSelector(selectResumeId);
    const vacancyId = useSelector(selectVacancyId);

    const handleSendAnalytics = () => {
        resumeEmailButtonClick({ vacancyId, resumeId });
    };

    const isPreferred = getPreferredContactType(preferredContact) === EMAIL;

    return email ? (
        <div data-qa="resume-contact-email">
            <VSpacingContainer default={4}>
                <Text style="secondary" typography="label-3-regular">
                    {trls[TrlKeys.email]}
                    {isPreferred && (
                        <>
                            {`${NON_BREAKING_SPACE}${EM_DASH}${NON_BREAKING_SPACE}`}
                            {trls[TrlKeys.preferredContact]}
                        </>
                    )}
                </Text>
                <Link
                    style="neutral"
                    typography="label-2-regular"
                    href={`mailto:${email}`}
                    data-qa={isPreferred ? 'resume-contact-preferred' : undefined}
                    onClick={handleSendAnalytics}
                >
                    {email}
                </Link>
            </VSpacingContainer>
        </div>
    ) : null;
});

export default ContactsEmail;
