import { ChangeEvent } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { CheckableChip, NumberInput, VSpacing, HSpacingContainer } from '@hh.ru/magritte-ui';

import { getCurrencyLabel } from 'src/components/Currency';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import { capitalized } from 'src/models/applicant/resume/lib/common/string';
import { CurrencyType } from 'src/models/currencies.types';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';

const FIELD_NAME = 'salary';

const TrlKeys = {
    hint: 'resume.editor.salary.hint',
    label: 'resume.editor.salary.label',
};

const diffCountErrorKeys = ['LENGTH_GREATER_THAN_MAXIMUM'];

const SalaryField = translation<{ commonErrors: ErrorsMap }>(({ trls, commonErrors }) => {
    const { value, onChange, invalid, errorMessage } = useResumeEditorField(
        FIELD_NAME,
        commonErrors,
        diffCountErrorKeys
    );

    const currencyPostfix = getCurrencyLabel({ trls, value: value.currency });

    const rurLable = capitalized(getCurrencyLabel({ trls, value: CurrencyType.RUR, fullLabel: true }));
    const usdLable = capitalized(getCurrencyLabel({ trls, value: CurrencyType.USD, fullLabel: true }));
    const eurLable = capitalized(getCurrencyLabel({ trls, value: CurrencyType.EUR, fullLabel: true }));

    const handleAmountChanged = (amount: string) => {
        onChange({ ...value, amount: parseInt(amount || '0', 10) });
    };
    const handleCurrencyChanged = (event: ChangeEvent<HTMLInputElement>) => {
        onChange({ ...value, currency: event.target.value as CurrencyType });
    };

    return (
        <EditorField htmlFor="salary" label={trls[TrlKeys.label]}>
            <NumberInput
                size="medium"
                hint={trls[TrlKeys.hint]}
                value={`${value.amount}`}
                invalid={invalid}
                postfix={currencyPostfix}
                maxLength={10}
                onChange={handleAmountChanged}
                errorMessage={errorMessage}
            />
            <VSpacing default={12} />
            <HSpacingContainer default={8}>
                <CheckableChip
                    type="radio"
                    name="currency"
                    value="RUR"
                    checked={value.currency === CurrencyType.RUR}
                    onChange={handleCurrencyChanged}
                >
                    {rurLable}
                </CheckableChip>
                <CheckableChip
                    type="radio"
                    name="currency"
                    value="USD"
                    checked={value.currency === CurrencyType.USD}
                    onChange={handleCurrencyChanged}
                >
                    {usdLable}
                </CheckableChip>
                <CheckableChip
                    type="radio"
                    name="currency"
                    value="EUR"
                    checked={value.currency === CurrencyType.EUR}
                    onChange={handleCurrencyChanged}
                >
                    {eurLable}
                </CheckableChip>
            </HSpacingContainer>
        </EditorField>
    );
});

export default SalaryField;
