import { DefaultRootState } from 'react-redux';

import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';
import { PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { AdditionalEducation } from 'src/models/resume/resumeCommon.types';

export const selectResumeEditorOpenedAdditionalEducation = selectResumeEditorOpenedType('additionalEducation');

export const selectResumeEditorAdditionalEducation = ({
    resumeEditor,
}: DefaultRootState): AdditionalEducation | undefined => resumeEditor.fields.additionalEducation;

export const selectResumeEditorAdditionalEducationIndex = ({
    resumeEditor,
    applicantResume,
}: DefaultRootState): number => {
    const { index } = resumeEditor.payload as PayloadType<{ index: number }>;
    if (index === undefined) {
        return applicantResume.additionalEducation.length;
    }
    return index;
};
