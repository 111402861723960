import { useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import Analytics from '@hh.ru/analytics-js';
import { Link, usePush } from '@hh.ru/redux-spa-middleware';
import Button, { ButtonKind } from 'bloko/blocks/button';
import HSpacing from 'bloko/blocks/hSpacing';
import { H3 } from 'bloko/blocks/header';
import BlokoLink from 'bloko/blocks/link';
import VSpacing from 'bloko/blocks/vSpacing';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { formatToReactComponent } from 'bloko/common/trl';

import { createCart } from 'src/api/price/cart';
import paths from 'src/app/routePaths';
import ConversionDate from 'src/components/Conversion/DateOnly';
import FormatMoney from 'src/components/FormatMoney';
import { useNotification } from 'src/components/Notifications/Provider';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { DBAccessProductType } from 'src/models/price/product.types';
import { getApiCount, getContactsCount } from 'src/utils/price/products';

import getAnalyticsParams from 'src/components/ResumeContacts/BuyContacts/getAnalyticsParams';

import styles from './styles.less';

const TrlKeys = {
    demo: 'employer.demoResumeViewsExceeded',
    buyAccess: 'employer.buyResumeViewsAccess',
    another: 'employer.buyResumeViewsAccess.another',
    profAreas: 'employer.profareas.all',
    apiCount: 'employer.price.resume-access.api.count',
    contactView: 'employer.price.resume-access.contact.information.view',
};

interface BuyContactsResumeAccessProps {
    currentProduct: DBAccessProductType;
}

const BuyContactsResumeAccess: TranslatedComponent<BuyContactsResumeAccessProps> = ({ trls, currentProduct }) => {
    const dispatch = useDispatch();
    const push = usePush();
    const area = useSelector(({ snippetAccessResume }) => snippetAccessResume.area);
    const { addNotification } = useNotification();

    useEffect(() => {
        Analytics.sendHHEvent('element_shown', {
            elementName: 'minResumeAccessPrice',
            ...dispatch(getAnalyticsParams(currentProduct)),
        });
    }, [currentProduct, dispatch]);

    const sendAnalyticsBuyAccess = useCallback(() => {
        Analytics.sendHHEventButtonClick('resume-buy-access', dispatch(getAnalyticsParams(currentProduct)));
    }, [currentProduct, dispatch]);

    const sendAnalyticsGetAnother = useCallback(() => {
        Analytics.sendHHEventButtonClick('resume-get-another-access', dispatch(getAnalyticsParams(currentProduct)));
    }, [currentProduct, dispatch]);

    const handleClick = useCallback(() => {
        sendAnalyticsBuyAccess();
        void createCart([currentProduct], addNotification, push);
    }, [addNotification, currentProduct, push, sendAnalyticsBuyAccess]);

    const contactsCount = String(getContactsCount(currentProduct));
    const apiCount = getApiCount(currentProduct);

    return (
        <>
            <H3>{trls[TrlKeys.demo]}</H3>
            <VSpacing base={4} />
            <p>
                <ConversionDate
                    value={Number(currentProduct.period)}
                    shouldShowInHours={Number(currentProduct.period) === 1}
                />
                {Boolean(area) && `, ${area as string}, ${trls[TrlKeys.profAreas]}`}
            </p>
            <p>
                {Boolean(contactsCount) && `${contactsCount} ${trls[TrlKeys.contactView]}`}
                {Boolean(apiCount) && `, ${trls[TrlKeys.apiCount]}`}
            </p>
            <VSpacing base={4} />
            <Button kind={ButtonKind.Primary} data-qa="show-contacts-resume-buy-access" onClick={handleClick}>
                {formatToReactComponent(trls[TrlKeys.buyAccess], {
                    '{0}': (
                        <FormatMoney currency={currentProduct.currency} inCents={true}>
                            {currentProduct.price}
                        </FormatMoney>
                    ),
                })}
            </Button>
            <HSpacing base={7} />
            <div className={styles.controlWrapper}>
                <VSpacing base={4} />
                <BlokoLink Element={Link} disableVisited to={paths.priceDBAccess} onClick={sendAnalyticsGetAnother}>
                    {trls[TrlKeys.another]}
                </BlokoLink>
            </div>
        </>
    );
};

export default translation(BuyContactsResumeAccess);
