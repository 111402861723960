import { Fragment, useState, useCallback, useRef } from 'react';
import PropTypes from 'prop-types';

import { Link } from '@hh.ru/redux-spa-middleware';
import Info, { InfoTheme, InfoPlacement } from 'bloko/blocks/drop/Info';
import HoverTip from 'bloko/blocks/drop/Tip/HoverTip';
import {
    ExclamationScaleSmallShapeTriangle,
    CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedFalse,
    IconLink,
    IconColor,
} from 'bloko/blocks/icon';
import BlokoLink from 'bloko/blocks/link';

import Notices from 'Modules/Notices';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { EDIT } from 'src/utils/constants/resumePermission';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const NOTIFICATION_KEY = 'APPLICANT_PHONE_VERIFICATION';

const ResumePhoneVerified = ({ verified, trls, needVerification }) => {
    const infotip = useSelector((state) => state.infoTip);
    const printVersion = useSelector((state) => state.printVersion);
    const [infotipVisible, setInfotipVisible] = useState(infotip?.name === NOTIFICATION_KEY);
    const openInfotip = useCallback(() => setInfotipVisible(true), []);
    const closeInfotip = useCallback(() => {
        setInfotipVisible(false);
        Notices.markAsViewed(NOTIFICATION_KEY);
    }, []);
    const resume = useSelector(({ resume }) => resume);
    const canEdit = resume.permission === EDIT;
    const activatorVerifiedRef = useRef(null);
    const activatorVerifiedEditRef = useRef(null);
    const activatorNotVerifiedRef = useRef(null);

    if ((!canEdit && !verified) || printVersion.isPrintVersion) {
        return null;
    }

    if (!canEdit && verified) {
        const tooltipMessage = trls[ResumePhoneVerified.trls.verified];
        return (
            <Fragment>
                <HoverTip
                    render={() => tooltipMessage}
                    host={process.env.SSR ? null : document.body}
                    activatorRef={activatorVerifiedRef}
                >
                    <span ref={activatorVerifiedRef}>
                        <CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedFalse
                            data-qa="mark-valid-icon"
                            initial={IconColor.Green60}
                        />
                    </span>
                </HoverTip>
                {NON_BREAKING_SPACE}
            </Fragment>
        );
    }

    if (canEdit && verified) {
        return (
            <Fragment>
                <HoverTip
                    render={() => trls[ResumePhoneVerified.trls.verifiedApplicant]}
                    activatorRef={activatorVerifiedEditRef}
                >
                    <span ref={activatorVerifiedEditRef}>
                        <CheckmarkScaleSmallKindSingleAppearanceOutlinedEnclosedFalse
                            data-qa="mark-valid-icon"
                            initial={IconColor.Green60}
                        />
                    </span>
                </HoverTip>
                {NON_BREAKING_SPACE}
            </Fragment>
        );
    }

    if (!needVerification) {
        return null;
    }

    if (canEdit && !verified) {
        const canVerify = resume?.phone?.value?.[0].canVerify;
        const url = canVerify ? `/applicant/phone_verification?resume=${resume?.hash}` : '/applicant/settings#phone';
        return (
            <Fragment>
                <Info
                    show={infotipVisible}
                    onClose={closeInfotip}
                    closeByClickOutside={false}
                    theme={InfoTheme.Light}
                    placement={InfoPlacement.Top}
                    render={() => (
                        <div className="verify-phone-infotip">
                            {trls[ResumePhoneVerified.trls.notVerifiedApplicant]}
                            <p className="infotip-link-wrapper">
                                <BlokoLink disableVisited Element={Link} to={url}>
                                    {trls[ResumePhoneVerified.trls.verifyLink]}
                                </BlokoLink>
                            </p>
                        </div>
                    )}
                    activatorRef={activatorNotVerifiedRef}
                >
                    <IconLink onClick={openInfotip} innerRef={activatorNotVerifiedRef}>
                        <ExclamationScaleSmallShapeTriangle initial={IconColor.Gray50} />
                    </IconLink>
                </Info>
                {NON_BREAKING_SPACE}
            </Fragment>
        );
    }

    return null;
};

ResumePhoneVerified.propTypes = {
    verified: PropTypes.bool,
    needVerification: PropTypes.bool,
    trls: PropTypes.object.isRequired,
};

ResumePhoneVerified.trls = {
    verified: 'rb.view.phone.verified',
    verifiedApplicant: 'rb.view.phone.verified.applicant',
    notVerifiedApplicant: 'rb.view.phone.notVerified.applicant',
    verifyLink: 'rb.view.phone.notVerified.applicant.verify',
};

export default translation(ResumePhoneVerified);
