import { keys } from 'src/models/applicant/resume/lib/common/object';

export const EMPTY_ARRAY = [];
export const EMPTY_OBJECT = {};

/**
 * Returns `true` if the given value is empty; `false` otherwise.
 */
// тут нам все равно какой тип
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isEmpty(value: Array<any> | Record<any, any> | string): boolean {
    if (Array.isArray(value) || isString(value)) {
        return value.length === 0;
    }

    if (isObject(value)) {
        return keys(value).length === 0;
    }

    return false;
}

// тут нам все равно какой тип
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isObject(x: any | Record<any, any>): x is Record<any, any> {
    return Object.prototype.toString.call(x) === '[object Object]';
}

// тут нам все равно какой тип
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isArray<T>(x: any | Array<T>): x is Array<T> {
    return Object.prototype.toString.call(x) === '[object Array]';
}

// тут нам все равно какой тип
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isString(x: any | string): x is string {
    return Object.prototype.toString.call(x) === '[object String]';
}
// тут нам все равно какой тип
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function isNumber(x: any | number): x is number {
    return Object.prototype.toString.call(x) === '[object Number]';
}
