import { DefaultRootState } from 'react-redux';

import { ProfessionalRoleTreeDTO, RoleCatalogDTO, RolesCatalogDTO } from 'src/models/applicant/resume/api/dto';
import { Salary } from 'src/models/applicant/resume/blocks/position/types';
import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';

export const selectResumeEditorOpenedPosition = selectResumeEditorOpenedType('position');

export const selectResumeEditorTitle = ({ resumeEditor }: DefaultRootState): string => resumeEditor.fields.title || '';

export const selectResumeEditorSalary = ({ resumeEditor }: DefaultRootState): Salary | undefined =>
    resumeEditor.fields.salary;

export const selectResumeEditorRrofessionalRole = ({ resumeEditor }: DefaultRootState): RoleCatalogDTO[] | undefined =>
    resumeEditor.fields.professionalRole;

export const selectRolesCatalog = ({ resumeEditor }: DefaultRootState): RolesCatalogDTO | undefined =>
    resumeEditor.dictionaries.rolesCatalog;

export const selectProfessionalRoleTree = ({ resumeEditor }: DefaultRootState): ProfessionalRoleTreeDTO | undefined =>
    resumeEditor.dictionaries.professionalRoleTree;
