import { memo } from 'react';
import PropTypes from 'prop-types';

import Link, { LinkKind } from 'bloko/blocks/link';
import Text, { TextImportance, TextSize } from 'bloko/blocks/text';
import BlokoTranslateGuard from 'bloko/blocks/translateGuard';

import { getPreferredPhoneType, CELL_PHONE_TYPE } from 'src/components/ResumeContacts/preferredContacts';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { PHONES } from 'src/utils/constants/resumeHiddenFields';
import { EDIT } from 'src/utils/constants/resumePermission';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

import ResumePhoneVerified from 'src/pages/ResumeView/components/ResumeHeader/Contacts/ResumePhoneVerified';

const ResumeContactsPhones = ({ onContactsClick, trls }) => {
    const canEdit = useSelector(({ resume }) => resume.permission === EDIT);
    const isHidden = useSelector(({ resume }) => resume.hiddenFields.includes(PHONES));
    const phones = useSelector(({ resume }) => resume.phone.value) || [];
    const preferredType = useSelector(({ resume }) => getPreferredPhoneType(resume.preferredContact.value));

    if (isHidden && !canEdit) {
        return (
            <div className="resume-hidden-field-wrapper">
                <div className="resume-hidden-field">
                    <Text Element="span" importance={TextImportance.Tertiary}>
                        {trls[ResumeContactsPhones.trls.isHidden]}
                    </Text>
                </div>
            </div>
        );
    }

    return (
        <>
            {phones.map((phone) => {
                const isPreferred = preferredType && phone.type === preferredType;
                return (
                    <div key={phone.type} data-qa="resume-serp_resume-item-content">
                        <div data-qa="resume-contacts-phone">
                            {phone.type === CELL_PHONE_TYPE && (
                                <ResumePhoneVerified
                                    verified={phone.verified}
                                    needVerification={phone.needVerification}
                                />
                            )}
                            <Link
                                kind={LinkKind.Tertiary}
                                data-qa={isPreferred ? 'resume-contact-preferred' : null}
                                href={`tel:+${phone.raw}`}
                                onClick={() => onContactsClick('phone')}
                            >
                                {phone.formatted}
                            </Link>

                            <BlokoTranslateGuard>
                                {phone.comment && (
                                    <span>
                                        {NON_BREAKING_SPACE}
                                        {phone.comment}
                                    </span>
                                )}
                                {isPreferred && (
                                    <BlokoTranslateGuard>
                                        {`${NON_BREAKING_SPACE}— ${trls[ResumeContactsPhones.trls.isPreferred]}`}
                                    </BlokoTranslateGuard>
                                )}
                            </BlokoTranslateGuard>

                            {phone.verified && (
                                <div className="resume-search-item-phone-verification-status">
                                    <Text size={TextSize.Small}>{trls[ResumeContactsPhones.trls.phoneVerified]}</Text>
                                </div>
                            )}
                        </div>
                    </div>
                );
            })}
        </>
    );
};

ResumeContactsPhones.trls = {
    isHidden: 'resume.hiddenFields.phones',
    isPreferred: 'rb.view.preferredcontact',
    phoneVerified: 'resume.phoneVerified',
};

ResumeContactsPhones.propTypes = {
    onContactsClick: PropTypes.func,
    trls: PropTypes.object.isRequired,
};

export default translation(memo(ResumeContactsPhones));
