import { useDispatch } from 'react-redux';

import { useSelector, translation } from '@hh.ru/front-static-app';

import { selectResumeBusinessTripReadiness } from 'src/models/applicant/resume/blocks/additional/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import Card from 'src/pages/ResumeView/redesign/additional/BottomSheet/Card';

const TrlKeys = {
    title: 'resume.editor.businessTripReadiness.title',
    type: {
        never: 'resume.editor.businessTripReadiness.never',
        ready: 'resume.editor.businessTripReadiness.ready',
        sometimes: 'resume.editor.businessTripReadiness.sometimes',
    },
};

const BusinessTripReadiness = translation(({ trls }) => {
    const dispatch = useDispatch();
    const businessTripReadiness = useSelector(selectResumeBusinessTripReadiness);

    const handleEdit = () => dispatch(openResumeEditorModal('businessTripReadiness'));

    return <Card title={trls[TrlKeys.title]} text={trls[TrlKeys.type[businessTripReadiness]]} onClick={handleEdit} />;
});

export default BusinessTripReadiness;
