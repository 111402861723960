import { useMemo } from 'react';

import { LangTrls, useSelector } from '@hh.ru/front-static-app';

import { selectResumeEditorErrors } from 'src/models/applicant/resume/editor/store/selectors';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { keys } from 'src/models/applicant/resume/lib/common/object';
import { Condition } from 'src/models/applicant/resume/types/conditions';

import { makeErrorMessage } from 'src/models/applicant/resume/editor/form/errors/lib';

export interface PluralError {
    one: string;
    some: string;
    many: string;
}

type ErrorValue = string | PluralError;

export type ErrorsMap = Record<string, ErrorValue>;

export const useEditorError = <Key extends keyof ApplicantResumeEditorFields>(
    key: Key,
    errorsMap: ErrorsMap = {},
    value?: Required<ApplicantResumeEditorFields>[Key],
    condition?: Condition
): {
    invalid: boolean;
    errorMessage: string | undefined | null;
} => {
    const formErrors = useSelector(selectResumeEditorErrors);

    const errorMessage = useMemo(() => {
        const fieldFormErrors = formErrors?.[key] || [];
        const errors = makeErrorMessage(fieldFormErrors, errorsMap, condition, value);
        const error = errors?.[0];
        if (!error) {
            return undefined;
        }
        return error.message;
    }, [formErrors, key, errorsMap, condition, value]);

    return { invalid: !!errorMessage, errorMessage };
};

export const useTranslatedErrorMap = (keysMap: ErrorsMap, trls: LangTrls): ErrorsMap => {
    return useMemo(
        () =>
            keys(keysMap).reduce((prev, key) => {
                if (typeof keysMap[key] === 'string') {
                    return { ...prev, [key]: trls[keysMap[key]] };
                }
                return {
                    ...prev,
                    [key]: {
                        one: trls[keysMap[key].one],
                        some: trls[keysMap[key].some],
                        many: trls[keysMap[key].many],
                    },
                };
            }, {}),
        [keysMap, trls]
    );
};
