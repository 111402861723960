import { FC } from 'react';

import { GridColumn, GridLayout, GridRow, VSpacing } from '@hh.ru/magritte-ui';
import useBreakpoint from '@hh.ru/magritte-ui-breakpoint/useBreakpoint';

import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import MagritteWrapper from 'src/components/MagritteWrapper/MagritteWrapper';
import { useSelectorNonNullable } from 'src/hooks/useSelector';
import ResumeEmployerTransitions from 'src/pages/ResumeView/components/ResumeTransitions';

import EmployerResumeViewBreadcrumbs from 'src/pages/ResumeView/employerRedesign/EmployerResumeViewBreadcrumbs';
import ResumeBody from 'src/pages/ResumeView/employerRedesign/components/ResumeBody';

const EmployerResumeView: FC = () => {
    const title = useSelectorNonNullable((state) => state.resume.title.value) || '';
    const { isMobile } = useBreakpoint();

    return (
        <PageLayout title={title} layout={EmptyLayout}>
            <MagritteWrapper isEnabled>
                <GridLayout>
                    <GridRow>
                        <GridColumn xs={4} s={8} m={12} l={12} xl={12} xxl={12}>
                            <EmployerResumeViewBreadcrumbs />
                        </GridColumn>
                        <VSpacing default={24} xs={8} />
                    </GridRow>
                    <GridRow>
                        <GridColumn xs={4} s={8} m={8} l={8} xl={8} xxl={8}>
                            <ResumeEmployerTransitions />
                            <div>заголовок</div>
                            {isMobile && <div>контент из сайдбара</div>}
                            <ResumeBody />
                        </GridColumn>
                        <GridColumn xs={0} s={0} m={4} l={4} xl={4} xxl={4}>
                            <div>сайдбар</div>
                        </GridColumn>
                    </GridRow>
                </GridLayout>
            </MagritteWrapper>
        </PageLayout>
    );
};

export default EmployerResumeView;
