import { useContext, useState } from 'react';
import PropTypes from 'prop-types';

import chatEntryResumeContactsButtonClick from '@hh.ru/analytics-js-events/build/xhh/employer/chat/chat_entry_resume_contacts_button_click';
import chatEntryResumeContactsShow from '@hh.ru/analytics-js-events/build/xhh/employer/chat/chat_entry_resume_contacts_button_show';
import { ChatikContext } from '@hh.ru/chatik-integration';
import { useExperiment } from '@hh.ru/front-static-app';
import Button, { ButtonAppearance, ButtonIconPosition } from 'bloko/blocks/button';
import { BubbleScaleSmallKindSingleAppearanceOutlinedShapeRound } from 'bloko/blocks/icon';
import Loading, { LoadingScale } from 'bloko/blocks/loading';
import urlParser from 'bloko/common/urlParser';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import { selectorCanCommunicateWithApplicant } from 'src/components/HiringManager/utils/selectorCanCommunicateWithApplicant';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import fetcher from 'src/utils/fetcher';

const ResumeContactsChatButton = ({ onContactsClick, trls }) => {
    const openChatik = useContext(ChatikContext)?.openChatik;
    const chatikIntegration = useSelector((state) => state.chatikIntegration);
    const location = useSelector((state) => state.router.location);
    const vacancyId = urlParser(location.search)?.params?.vacancyId?.[0];
    const resumeId = urlParser(location.search)?.params?.resumeId?.[0];
    const topicList = useSelector((state) => state.employerNegotiations.topicList);
    const canUseChat = useSelector(selectorCanCommunicateWithApplicant);

    const chatIdFromTopic =
        topicList && topicList.length
            ? topicList?.find((item) => item.vacancyId.toString() === vacancyId)?.chatId
            : null;

    // tempexp_PORTFOLIO-36442_start
    const resumeHash = useSelector(({ resume }) => resume?.hash);

    const [commonChatId, setCommonChatId] = useState(null);
    const [commonChatIdLoading, setCommonChatIdLoading] = useState(false);

    const isChatWithoutVacancyExp = useExperiment(
        'chat_without_vacancy',
        canUseChat && chatikIntegration && Boolean(resumeHash) && !chatIdFromTopic
    );
    // tempexp_PORTFOLIO-36442_end

    if (!isChatWithoutVacancyExp && (!canUseChat || !chatIdFromTopic || !chatikIntegration)) {
        return null;
    }

    const handleChatFromTopicOpen = () => {
        openChatik?.({
            chatId: chatIdFromTopic,
            view: 'widget',
            hhtmFromLabel: 'resume',
        });
        onContactsClick?.('chat');
        chatEntryResumeContactsButtonClick({ vacancyId, resumeId });
    };

    // tempexp_PORTFOLIO-36442_start
    const handleCommonChatOpen = () => {
        chatEntryResumeContactsButtonClick({ vacancyId, resumeId });

        if (commonChatId) {
            openChatik?.({
                chatId: commonChatId,
                view: 'widget',
            });

            return;
        }

        setCommonChatIdLoading(true);

        fetcher
            .put('/shards/resume/chat_without_vacancy', { resume: resumeHash ?? '' })
            .then((data) => {
                setCommonChatId(data.data.chat_id);
                openChatik({ chatId: data.data.chat_id });
            })
            .finally(() => setCommonChatIdLoading(false));
    };
    // tempexp_PORTFOLIO-36442_end

    return (
        <ElementShownAnchor fn={chatEntryResumeContactsShow} vacancyId={vacancyId} resumeId={resumeId}>
            <Button
                // tempexp_PORTFOLIO-36442_next_line
                onClick={isChatWithoutVacancyExp ? handleCommonChatOpen : handleChatFromTopicOpen}
                loading={commonChatIdLoading && <Loading scale={LoadingScale.Small} />}
                icon={<BubbleScaleSmallKindSingleAppearanceOutlinedShapeRound />}
                iconPosition={ButtonIconPosition.Right}
                appearance={ButtonAppearance.Outlined}
            >
                {trls[ResumeContactsChatButton.trls.text]}
            </Button>
        </ElementShownAnchor>
    );
};

ResumeContactsChatButton.trls = {
    text: 'chatik.activator.resume',
};

ResumeContactsChatButton.propTypes = {
    onContactsClick: PropTypes.func,
    trls: PropTypes.object,
};

export default translation(ResumeContactsChatButton);
