import { ComponentType } from 'react';

import { translation } from '@hh.ru/front-static-app';
import { Input, VSpacingContainer } from '@hh.ru/magritte-ui';

import { PhoneType } from 'src/models/applicant/resume/blocks/contacts/types';
import EditorField from 'src/pages/ResumeView/redesign/common/Form/EditorField';
import PreferredContact from 'src/pages/ResumeView/redesign/contacts/Editor/PreferredContact';

type Props = {
    type: PhoneType;
    value?: string;
    comment?: string;
};

const TrlKeys = {
    hint: 'resume.editor.contacts.phone.verified',
    label: {
        cell: 'resume.editor.contacts.label.phone.cell',
        work: 'resume.editor.contacts.label.phone.work',
        home: 'resume.editor.contacts.label.phone.home',
    },
    placeholder: {
        phone: 'resume.editor.contacts.placeholder.phone',
        comment: 'resume.editor.contacts.placeholder.comment',
    },
};

const PhoneField: ComponentType<Props> = translation(({ trls, value, comment, type }) => {
    return (
        <EditorField htmlFor={type} label={trls[TrlKeys.label[type]]}>
            <VSpacingContainer default={12}>
                <PreferredContact fieldName={`${type}_phone`} value={value ?? ''} />
                <Input
                    onChange={() => undefined}
                    size="medium"
                    inputMode="email"
                    placeholder={trls[TrlKeys.placeholder.phone]}
                    value={value ?? ''}
                />
                <Input
                    onChange={() => undefined}
                    size="medium"
                    inputMode="email"
                    placeholder={trls[TrlKeys.placeholder.comment]}
                    value={comment ?? ''}
                />
            </VSpacingContainer>
        </EditorField>
    );
});

export default PhoneField;
