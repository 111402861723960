import { selectApplicantResumeAttestationEducation } from 'src/models/applicant/resume/blocks/attestationEducation/selectors';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate';
import { isChangedByKeys, isNewArrayField, updateItemById } from 'src/models/applicant/resume/editor/lib';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig, PayloadType } from 'src/models/applicant/resume/editor/types/config';
import { AdditionalEducation } from 'src/models/resume/resumeCommon.types';

import {
    getSelctedAttestationEducationByIndex,
    isNewAttestationEducationChanged,
} from 'src/models/applicant/resume/blocks/attestationEducation/editor/lib';
import { selectResumeEditorAttestationEducation } from 'src/models/applicant/resume/blocks/attestationEducation/editor/selectors';

export type AttestationEducationBlockConfig = EditorConfig<Pick<ApplicantResumeEditorFields, 'attestationEducation'>>;

const isAttestationEducationChanges = isChangedByKeys<AdditionalEducation>(['name', 'organization', 'result', 'year']);

export const attestationEducationConfig: AttestationEducationBlockConfig = {
    init: (store) => {
        return {
            attestationEducation: getSelctedAttestationEducationByIndex(store),
        };
    },

    changed: (store) => {
        const editorAttestationEducation = selectResumeEditorAttestationEducation(store);
        const resumeAttestationEducations = selectApplicantResumeAttestationEducation(store);

        if (!editorAttestationEducation) {
            return false;
        }

        if (isNewArrayField(editorAttestationEducation)) {
            return isNewAttestationEducationChanged(editorAttestationEducation);
        }

        const resumeAttestationEducation = resumeAttestationEducations.find(
            ({ id }) => id === editorAttestationEducation.id
        );
        if (!resumeAttestationEducation) {
            return false;
        }

        return isAttestationEducationChanges(resumeAttestationEducation, editorAttestationEducation);
    },

    prepare: ({ attestationEducation }, store) => {
        const resumeAttestationEducation = selectApplicantResumeAttestationEducation(store);

        if (isNewArrayField(attestationEducation)) {
            return { attestationEducation: [...resumeAttestationEducation, attestationEducation] };
        }

        return {
            attestationEducation: updateItemById(resumeAttestationEducation, attestationEducation),
        };
    },

    validate: ({ attestationEducation }, conditions) => ({
        ...validateEditorFieldByKey('attestationEducation', attestationEducation, conditions),
    }),

    delete: (store, payload) => {
        const attestationEducation = selectApplicantResumeAttestationEducation(store);
        const { index } = payload as PayloadType<{ index: number }>;
        return { attestationEducation: attestationEducation.filter((_, i) => i !== index) };
    },
};
