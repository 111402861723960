import { TranslateGuard } from '@hh.ru/magritte-ui-translate-guard';
import { DateIsoString } from 'bloko/blocks/calendar/datesHelper';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import { formatStringDate } from 'Utils/LastActivityTime';
import translation from 'src/components/translation';
import { Gender } from 'src/models/resume/resumeCommon.types';

const TrlKeys = {
    'lastActivity.online': 'lastActivity.online',
    'lastActivity.were.male': 'lastActivity.were.male',
    'lastActivity.were.male.lower': 'lastActivity.were.male.lower',
    'lastActivity.were.female': 'lastActivity.were.female',
    'lastActivity.were.female.lower': 'lastActivity.were.female.lower',
    'lastActivity.were.unknown': 'lastActivity.were.male',
    'lastActivity.were.unknown.lower': 'lastActivity.were.male.lower',
    'lastActivity.withinMinutes.one': 'lastActivity.withinMinutes.one',
    'lastActivity.withinMinutes.some': 'lastActivity.withinMinutes.some',
    'lastActivity.withinMinutes.many': 'lastActivity.withinMinutes.many',
    'lastActivity.today': 'lastActivity.today',
    'lastActivity.yesterday': 'lastActivity.yesterday',
    withinWeekSunday: 'search.resume.lastActivity.withinWeek.sunday',
    withinWeekMonday: 'search.resume.lastActivity.withinWeek.monday',
    withinWeekTuesday: 'search.resume.lastActivity.withinWeek.tuesday',
    withinWeekWednesday: 'search.resume.lastActivity.withinWeek.wednesday',
    withinWeekThursday: 'search.resume.lastActivity.withinWeek.thursday',
    withinWeekFriday: 'search.resume.lastActivity.withinWeek.friday',
    withinWeekSaturday: 'search.resume.lastActivity.withinWeek.saturday',
    'lastActivity.weekAgo': 'lastActivity.weekAgo',
    'lastActivity.withinTwoWeeks': 'lastActivity.withinTwoWeeks',
    'lastActivity.moreThanTwoWeeks': 'lastActivity.moreThanTwoWeeks',
    fromZP: 'search.resume.crossPostedLabel.fromZP',
};

interface ApplicantActivityTimeProps {
    online?: boolean;
    gender?: Gender;
    lastActivityTime?: DateIsoString;
    isCrossPosted?: boolean;
}

const ApplicantActivityTime: TranslatedComponent<ApplicantActivityTimeProps> = ({
    trls,
    online,
    gender,
    lastActivityTime = '',
}) => {
    if (online) {
        return <TranslateGuard useSpan>{trls[TrlKeys['lastActivity.online']]}</TranslateGuard>;
    }
    if (!gender) {
        return null;
    }
    const text = trls[TrlKeys[`lastActivity.were.${gender}`]];
    const withinWeekTrls = [
        trls[TrlKeys.withinWeekSunday],
        trls[TrlKeys.withinWeekMonday],
        trls[TrlKeys.withinWeekTuesday],
        trls[TrlKeys.withinWeekWednesday],
        trls[TrlKeys.withinWeekThursday],
        trls[TrlKeys.withinWeekFriday],
        trls[TrlKeys.withinWeekSaturday],
    ];

    return (
        <span suppressHydrationWarning>
            {format(text, {
                '{0}': formatStringDate(lastActivityTime, {
                    online: trls[TrlKeys['lastActivity.online']],
                    withinMinutes: [
                        trls[TrlKeys['lastActivity.withinMinutes.one']],
                        trls[TrlKeys['lastActivity.withinMinutes.some']],
                        trls[TrlKeys['lastActivity.withinMinutes.many']],
                    ],
                    today: trls[TrlKeys['lastActivity.today']],
                    yesterday: trls[TrlKeys['lastActivity.yesterday']],
                    withinWeek: withinWeekTrls,
                    weekAgo: trls[TrlKeys['lastActivity.weekAgo']],
                    withinTwoWeeks: trls[TrlKeys['lastActivity.withinTwoWeeks']],
                    moreThanTwoWeeks: trls[TrlKeys['lastActivity.moreThanTwoWeeks']],
                }),
            })}
        </span>
    );
};

export default translation(ApplicantActivityTime);
