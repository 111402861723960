import useExperiment from 'src/hooks/useExperiment';

import EmployerResumeView from 'src/pages/ResumeView/employerRedesign/ResumeView';
import ResumeViewOld from 'src/pages/ResumeView/old/ResumeView';
import ResumeViewNew from 'src/pages/ResumeView/redesign/ResumeView';

const ResumeView = (props) => {
    const isEmployerMagritteExp = useExperiment('magritte_employer_resume_view');
    const isMagritteExp = useExperiment('magritte_applicant_resume_view');
    if (isEmployerMagritteExp) {
        return <EmployerResumeView />;
    }
    if (isMagritteExp) {
        return <ResumeViewNew />;
    }
    return <ResumeViewOld {...props} />;
};

export default ResumeView;
