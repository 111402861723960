import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer, Radio, CheckableCard, Cell, CellText, useBreakpoint } from '@hh.ru/magritte-ui';

import { defaultTravelTimeOrder } from 'src/models/applicant/resume/blocks/additional/const';
import { selectResumeEditorOpenedTraveTime } from 'src/models/applicant/resume/blocks/additional/edit/selectors';
import { TravelTime } from 'src/models/applicant/resume/blocks/additional/types';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

const FIELD_NAME = 'travelTime';

const TrlKeys = {
    title: 'resume.editor.travelTime.title',
    type: {
        any: 'resume.editor.travelTime.any',
        less_than_hour: 'resume.editor.travelTime.less_than_hour', // eslint-disable-line camelcase
        from_hour_to_one_and_half: 'resume.editor.travelTime.from_hour_to_one_and_half', // eslint-disable-line camelcase
    },
};

const TravelTimeEditor = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedTraveTime);
    const { value, onChange } = useResumeEditorField(FIELD_NAME);

    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleSave = () => dispatch(submitResumeEditorData());

    const handleChangedTravelTime = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value as TravelTime);
    };

    const { isMobile } = useBreakpoint();

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            size="standard"
            showDivider={false}
        >
            <VSpacingContainer default={12}>
                {defaultTravelTimeOrder.map((travelTimeTypeItem) => {
                    const checked = travelTimeTypeItem === value;

                    const radioElement = <Radio checked={checked} onChange={() => undefined} />;

                    return (
                        <CheckableCard
                            key={travelTimeTypeItem}
                            flexible
                            borderRadius={12}
                            padding={16}
                            type="radio"
                            name={FIELD_NAME}
                            checked={checked}
                            value={travelTimeTypeItem}
                            onChange={handleChangedTravelTime}
                        >
                            <Cell
                                left={!isMobile ? radioElement : undefined}
                                right={isMobile ? radioElement : undefined}
                            >
                                <CellText>{trls[TrlKeys.type[travelTimeTypeItem]]}</CellText>
                            </Cell>
                        </CheckableCard>
                    );
                })}
            </VSpacingContainer>
        </EditorModal>
    );
});

export default TravelTimeEditor;
