import { useState, useCallback, useRef } from 'react';
import { useDispatch } from 'react-redux';

import { ElementShownAnchor } from '@hh.ru/analytics-js';
import webCallBtnClick from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/web_call_button_click';
import webCallTipShown from '@hh.ru/analytics-js-events/build/xhh/employer/calls/web_call/web_call_tip_element_shown';
import { useBreakpoint, TooltipHover } from '@hh.ru/magritte-ui';
import { makeSetStoreField } from '@hh.ru/redux-create-reducer';
import Button, { ButtonAppearance, ButtonKind, ButtonIconPosition } from 'bloko/blocks/button';
import { PhoneScaleSmallKindDefault, LockScaleSmallKindLocked } from 'bloko/blocks/icon';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import Notices, { MarkAsViewedEvent } from 'Modules/Notices';
import { selectorCanCommunicateWithApplicant } from 'src/components/HiringManager/utils/selectorCanCommunicateWithApplicant';
import MagritteInfotip from 'src/components/Infotip/MagritteInfotip';
import WebcallWrapper from 'src/components/Webcall/WebcallWrapper';
import translation from 'src/components/translation';
import useIsClient from 'src/hooks/useIsClient';
import { useSelector } from 'src/hooks/useSelector';

import styles from './index.less';

const infoTipAction = makeSetStoreField('infoTip');

const TrlKeys = {
    call: 'webcall.button.call.explained',
    title: 'webcall.banner.title',
    description: 'webcall.banner.description',
    disabledStatus: 'webcall.status.disabled.title',
};

const INFO_TIP_NAME = 'webcall-resume-button';

const WebcallButton: TranslatedComponent<{ onContactsClick?: (triggerName: string) => void }> = ({
    onContactsClick,
    trls,
}) => {
    const dispatch = useDispatch();
    const [webcallShown, setWebcallShown] = useState(false);
    const { isXS } = useBreakpoint();
    const resumeId = useSelector(({ resume }) => resume?.id || 0);
    const topicId = useSelector(({ router }) => router.location.query?.t || undefined);
    const isSudo = useSelector(({ actualAccount }) => actualAccount !== null);
    const isClient = useIsClient();
    const hoverTipRef = useRef(null);

    const resumeIdNumber = Number(resumeId);
    const widgetId = 'webcall-front';

    const canCommunicate = useSelector(selectorCanCommunicateWithApplicant);
    const isWebcallMounted = useSelector(({ microFrontends }) => Boolean(microFrontends['webcall-front']));
    const isWebcallLocked = useSelector(({ webcall }) => !webcall.stable);
    const isOpenedInIframe = isClient && window.top !== window;
    const isWebcallAvailable = isWebcallMounted && canCommunicate && !isOpenedInIframe && !isXS;

    const isInfotipVisible = useSelector(({ infoTip }) => infoTip.name === INFO_TIP_NAME);
    const tooltipText = `${trls[TrlKeys.title]}. ${trls[TrlKeys.description]}`;

    const handleClick = useCallback(() => {
        if (isSudo) {
            return;
        }
        setWebcallShown(true);
        webCallBtnClick({
            resumeId: resumeIdNumber,
            hhtmSource: 'resume',
            topicId,
        });
        if (isInfotipVisible) {
            Notices.markAsViewed(INFO_TIP_NAME);
            dispatch(infoTipAction({}));
        }
        onContactsClick?.('call');
    }, [dispatch, isInfotipVisible, resumeIdNumber, topicId, isSudo, onContactsClick]);

    if (isWebcallAvailable) {
        return (
            <>
                <MagritteInfotip
                    name={INFO_TIP_NAME}
                    render={({ activatorRef }) => (
                        <div ref={hoverTipRef}>
                            <span ref={activatorRef}>
                                <Button
                                    onClick={handleClick}
                                    appearance={ButtonAppearance.Outlined}
                                    kind={ButtonKind.Primary}
                                    icon={
                                        <span className={styles.iconWrapper}>
                                            {isWebcallLocked ? (
                                                <LockScaleSmallKindLocked />
                                            ) : (
                                                <PhoneScaleSmallKindDefault />
                                            )}
                                        </span>
                                    }
                                    iconPosition={ButtonIconPosition.Right}
                                    disabled={isWebcallLocked}
                                >
                                    {trls[TrlKeys.call]}
                                </Button>
                            </span>
                        </div>
                    )}
                    bannerStyle="contrast"
                    showBannerOnMobile={false}
                    placement="bottom-center"
                    showClose
                    closeByClickOutside={false}
                    visible={isInfotipVisible}
                    markAsViewedEvent={MarkAsViewedEvent.OnHide}
                    useDefaultHost
                    guardDistanceToActivator={25}
                >
                    <ElementShownAnchor fn={webCallTipShown} resumeId={resumeIdNumber} hhtmSource="resume">
                        {tooltipText}
                    </ElementShownAnchor>
                </MagritteInfotip>
                {isWebcallLocked && (
                    <TooltipHover placement="bottom-center" activatorRef={hoverTipRef} maxWidth={300}>
                        {trls[TrlKeys.disabledStatus]}
                    </TooltipHover>
                )}
                {webcallShown && (
                    <WebcallWrapper
                        place={widgetId}
                        onClose={() => setWebcallShown(false)}
                        hhtmSource="resume"
                        resumeId={resumeIdNumber}
                        topicId={topicId}
                    />
                )}
            </>
        );
    }

    return null;
};

export default translation(WebcallButton);
