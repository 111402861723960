import { DefaultRootState } from 'react-redux';

import { PreferredContact, PersonalSite } from 'src/models/applicant/resume/blocks/contacts/types';
import { selectResumeEditorOpenedType } from 'src/models/applicant/resume/editor/store/selectors';

export const selectResumeEditorOpenedContacts = selectResumeEditorOpenedType('contacts');

export const selectResumeEditorEmail = ({ resumeEditor }: DefaultRootState): string => resumeEditor.fields.email || '';
export const selectResumeEditorPreferredContact = ({ resumeEditor }: DefaultRootState): PreferredContact =>
    resumeEditor.fields.preferredContact ?? PreferredContact.CellPhone;
export const selectResumeEditorPersonalSite = ({ resumeEditor }: DefaultRootState): PersonalSite[] =>
    resumeEditor.fields.personalSite ?? [];
