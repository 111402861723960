import { useMemo } from 'react';

import { Cell, CellText, VSpacingContainer } from '@hh.ru/magritte-ui';
import { DriverLicenseType } from '@hh.ru/types-hh-microcore';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { capitalized } from 'src/models/applicant/resume/lib/common/string';
import { EmploymentType, Gender, TravelTime, TripsReadiness, WorkSchedule } from 'src/models/resume/resumeCommon.types';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';

const TrlKeys = {
    title: 'resume.block.additionalInfo.title',
    professionalRoleTitle: 'resume.professionalRoles.specializations',
    employmentTitle: 'resume.additionalInfo.employment.title',
    employment: {
        [EmploymentType.Probation]: 'vacancy.employment.probation',
        [EmploymentType.Full]: 'vacancy.employment.full',
        [EmploymentType.Part]: 'vacancy.employment.part',
        [EmploymentType.Volunteer]: 'vacancy.employment.volunteer',
        [EmploymentType.Project]: 'vacancy.employment.project',
    },
    workScheduleTitle: 'resume.additionalInfo.workSchedule.title',
    workSchedule: {
        [WorkSchedule.FullDay]: 'schedule.lowercase.full_day',
        [WorkSchedule.Shift]: 'schedule.lowercase.shift',
        [WorkSchedule.Flexible]: 'schedule.lowercase.flexible',
        [WorkSchedule.Remote]: 'schedule.lowercase.remote',
        [WorkSchedule.FlyInFlyOut]: 'schedule.lowercase.fly_in_fly_out',
    },
    travelTimeTitle: 'rb.title.travelTime',
    travelTime: {
        [TravelTime.Any]: 'traveltime.any',
        [TravelTime.LessThanHour]: 'traveltime.less_than_hour',
        [TravelTime.FromHourToOneAndHalf]: 'traveltime.from_hour_to_one_and_half',
    },
    businessTripsReadinessTitle: 'resume.additionalInfo.businessTripsReadiness.title',
    businessTripsReadiness: {
        [Gender.Male]: {
            [TripsReadiness.Ready]: 'rb.businessTripReadiness.ready.male',
            [TripsReadiness.Sometimes]: 'rb.businessTripReadiness.sometimes.male',
            [TripsReadiness.Never]: 'rb.businessTripReadiness.never.male',
        },
        [Gender.Female]: {
            [TripsReadiness.Ready]: 'rb.businessTripReadiness.never.female',
            [TripsReadiness.Sometimes]: 'rb.businessTripReadiness.sometimes.female',
            [TripsReadiness.Never]: 'rb.businessTripReadiness.never.female',
        },
        [Gender.Unknown]: {
            [TripsReadiness.Ready]: 'rb.businessTripReadiness.ready.unknown',
            [TripsReadiness.Sometimes]: 'rb.businessTripReadiness.sometimes.unknown',
            [TripsReadiness.Never]: 'rb.businessTripReadiness.never.unknown',
        },
    },
    citizenshipTitle: 'rb.title.citizenship',
    workTicketTitle: 'rb.title.workTicket',
    driverLicenseTitle: 'resume.additionalInfo.driverLicense.title',
    driverLicense: {
        [DriverLicenseType.A]: 'driverLicenseTypes.A',
        [DriverLicenseType.B]: 'driverLicenseTypes.B',
        [DriverLicenseType.C]: 'driverLicenseTypes.C',
        [DriverLicenseType.D]: 'driverLicenseTypes.D',
        [DriverLicenseType.E]: 'driverLicenseTypes.E',
        [DriverLicenseType.BE]: 'driverLicenseTypes.BE',
        [DriverLicenseType.CE]: 'driverLicenseTypes.CE',
        [DriverLicenseType.DE]: 'driverLicenseTypes.DE',
        [DriverLicenseType.TM]: 'driverLicenseTypes.TM',
        [DriverLicenseType.TB]: 'driverLicenseTypes.TB',
    },
    hasVehicle: {
        true: 'resume.additionalInfo.hasVehicle.true',
        false: 'resume.additionalInfo.hasVehicle.false',
    },
};

const ResumeAdditionalInfo: TranslatedComponent = ({ trls }) => {
    const professionalRole = useSelector((state) => state.resume?.professionalRole.value);
    const employment = useSelector((state) => state.resume?.employment.value);
    const workSchedule = useSelector((state) => state.resume?.workSchedule.value);
    const travelTime = useSelector((state) => state.resume?.travelTime.value);
    const gender = useSelector((state) => state.resume?.gender.value);
    const businessTripsReadiness = useSelector((state) => state.resume?.businessTripReadiness.value);
    const citizenship = useSelector((state) => state.resume?.citizenship.value);
    const workTicket = useSelector((state) => state.resume?.workTicket.value);
    const driverLicenseTypes = useSelector((state) => state.resume?.driverLicenseTypes.value);
    const hasVehicle = useSelector((state) => state.resume?.hasVehicle.value);

    const items = useMemo(
        () =>
            [
                {
                    title: trls[TrlKeys.professionalRoleTitle],
                    value: professionalRole?.length && professionalRole.map(({ trl }) => trl).join(', '),
                },
                {
                    title: trls[TrlKeys.employmentTitle],
                    value:
                        employment?.length &&
                        employment.map(({ string }) => trls[TrlKeys.employment[string]]).join(', '),
                },
                {
                    title: trls[TrlKeys.workScheduleTitle],
                    value:
                        workSchedule?.length &&
                        workSchedule.map(({ string }) => capitalized(trls[TrlKeys.workSchedule[string]])).join(', '),
                },
                {
                    title: trls[TrlKeys.travelTimeTitle],
                    value: travelTime && capitalized(trls[TrlKeys.travelTime[travelTime]]),
                },
                {
                    title: trls[TrlKeys.businessTripsReadinessTitle],
                    value:
                        !!businessTripsReadiness &&
                        capitalized(
                            trls[TrlKeys.businessTripsReadiness[gender || Gender.Unknown][businessTripsReadiness]]
                        ),
                },
                {
                    title: trls[TrlKeys.citizenshipTitle],
                    value: citizenship?.length && citizenship.map(({ title }) => title).join(', '),
                },
                {
                    title: trls[TrlKeys.workTicketTitle],
                    value: workTicket?.length && workTicket.map(({ title }) => title).join(', '),
                },
                {
                    title: trls[TrlKeys.driverLicenseTitle],
                    value:
                        driverLicenseTypes?.length &&
                        format(trls[TrlKeys.hasVehicle[hasVehicle ? 'true' : 'false']], {
                            '{0}': driverLicenseTypes.map((type) => trls[TrlKeys.driverLicense[type]]).join(', '),
                        }),
                },
            ].filter(({ value }) => !!value),
        [
            businessTripsReadiness,
            citizenship,
            driverLicenseTypes,
            employment,
            gender,
            hasVehicle,
            professionalRole,
            travelTime,
            trls,
            workSchedule,
            workTicket,
        ]
    );

    return (
        <ResumeBlock title={trls[TrlKeys.title]}>
            <VSpacingContainer default={16}>
                {items.map(({ title, value }) => (
                    <Cell key={title}>
                        <CellText type="subtitle">{title}</CellText>
                        <CellText type="title">{value}</CellText>
                    </Cell>
                ))}
            </VSpacingContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeAdditionalInfo);
