import { selectResumeEditorEmployment } from 'src/models/applicant/resume/blocks/additional/edit/selectors';
import { selectResumeEmployment } from 'src/models/applicant/resume/blocks/additional/selectors';
import { isArrayChangedByFn } from 'src/models/applicant/resume/editor/form/change/lib';
import { validateEditorFieldByKey } from 'src/models/applicant/resume/editor/form/validate/index';
import { ApplicantResumeEditorFields } from 'src/models/applicant/resume/editor/types';
import { EditorConfig } from 'src/models/applicant/resume/editor/types/config';

type EmploymentBlock = EditorConfig<Pick<ApplicantResumeEditorFields, 'employment'>>;

export const employmentConfig: EmploymentBlock = {
    init: (store) => ({
        employment: selectResumeEmployment(store),
    }),
    changed: (store) => {
        const editorEmployment = selectResumeEditorEmployment(store);
        const resumeEmployment = selectResumeEmployment(store);

        return isArrayChangedByFn(editorEmployment, resumeEmployment, (a, b) => a.string === b.string);
    },
    prepare: ({ employment }) => ({
        employment,
    }),
    validate: ({ employment }, conditions) => ({
        ...validateEditorFieldByKey('employment', employment, conditions),
    }),
};
