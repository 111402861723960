import { FC, PropsWithChildren } from 'react';

import { ButtonProps } from '@hh.ru/magritte-ui';

import styles from './styles.less';

export const defaultResumeBlockButtonProps: Pick<ButtonProps, 'mode' | 'style' | 'size'> = {
    mode: 'secondary',
    style: 'neutral',
    size: 'small',
};

const ResumeBlockButtonWrapper: FC<PropsWithChildren> = ({ children }) => {
    return <div className={styles.wrapper}>{children}</div>;
};

export default ResumeBlockButtonWrapper;
