import { useDispatch } from 'react-redux';

import { useSelector, translation } from '@hh.ru/front-static-app';

import { getStringListTrl } from 'src/models/applicant/resume/blocks/additional/lib';
import { selectResumeWorkSchedule } from 'src/models/applicant/resume/blocks/additional/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import Card from 'src/pages/ResumeView/redesign/additional/BottomSheet/Card';

const TrlKeys = {
    title: 'resume.editor.workSchedule.title',
    more: 'resume.editor.additional.more',
    type: {
        full_day: 'resume.editor.workSchedule.full_day', // eslint-disable-line camelcase
        fly_in_fly_out: 'resume.editor.workSchedule.fly_in_fly_out', // eslint-disable-line camelcase
        flexible: 'resume.editor.workSchedule.flexible',
        shift: 'resume.editor.workSchedule.shift',
        remote: 'resume.editor.workSchedule.remote',
    },
};

const WorkSchedule = translation(({ trls }) => {
    const dispatch = useDispatch();
    const workSchedule = useSelector(selectResumeWorkSchedule);

    const handleEdit = () => dispatch(openResumeEditorModal('workSchedule'));

    return (
        <Card
            title={trls[TrlKeys.title]}
            text={getStringListTrl(workSchedule, trls, TrlKeys.type, trls[TrlKeys.more])}
            onClick={handleEdit}
        />
    );
});

export default WorkSchedule;
