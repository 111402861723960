import { ComponentType, useMemo } from 'react';

import { useSelector } from '@hh.ru/front-static-app';
import {
    Action,
    GridRow,
    VSpacing,
    GridLayout,
    GridColumn,
    Breadcrumbs,
    NavigationBar,
    useBreakpoint,
    BreadcrumbsItem,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import {
    ChevronLeftOutlinedSize24,
    ArrowDownLineOutlinedSize24,
    EllipsisHorizontalOutlinedSize24,
} from '@hh.ru/magritte-ui/icon';
import { format } from 'bloko/common/trl';

import PageLayout, { EmptyLayout } from 'src/app/layouts/PageLayout';
import translation from 'src/components/translation';
import { selectApplicantResumeTitle } from 'src/models/applicant/resume/blocks/position/selectors';

import ApplicantResumeAbout from 'src/pages/ResumeView/redesign/about/Card';
import ApplicantResumeAdditional from 'src/pages/ResumeView/redesign/additional/Card';
import ApplicantResumeAdditionalEducation from 'src/pages/ResumeView/redesign/additionalEducation/Card';
import ApplicantResumeAttestationEducation from 'src/pages/ResumeView/redesign/attestationEducation/Card';
import EditorCloseConfirm from 'src/pages/ResumeView/redesign/common/Confirm/EditorCloseConfirm';
import EditorDeleteConfirm from 'src/pages/ResumeView/redesign/common/Confirm/EditorDeleteConfirm';
import Notifications from 'src/pages/ResumeView/redesign/common/Notifications';
import ApplicantResumeContacts from 'src/pages/ResumeView/redesign/contacts/Card';
import ApplicantResumePosition from 'src/pages/ResumeView/redesign/position/Card';
import ApplicantResumeRecommendation from 'src/pages/ResumeView/redesign/recommendation/Card';

const TrlKeys = {
    newResume: 'rb.view.newresume',
    pageTitle: 'applicant.resume.page.title',
    breadcrumbs: {
        resumes: 'resumeList.title',
    },
};
const RESUMES_PAGE_URL = '/applicant/resumes';

const ResumeView: ComponentType = translation(({ trls }) => {
    const { isMobile } = useBreakpoint();
    const title = useSelector(selectApplicantResumeTitle);

    const trlTitle = useMemo(() => {
        if (title !== '') {
            return format(trls[TrlKeys.pageTitle], { '{0}': title });
        }

        return trls[TrlKeys.newResume];
    }, [title, trls]);

    const onClose = () => {};

    return (
        <PageLayout title={trlTitle} layout={EmptyLayout}>
            {isMobile && (
                <NavigationBar
                    size="large"
                    left={<Action mode="secondary" onClick={onClose} icon={ChevronLeftOutlinedSize24} />}
                    right={[
                        <Action key="0" mode="secondary" onClick={onClose} icon={ArrowDownLineOutlinedSize24} />,
                        <Action key="1" mode="secondary" onClick={onClose} icon={EllipsisHorizontalOutlinedSize24} />,
                    ]}
                    showDivider="always"
                    // title={<ApplicantResumePosition />} как обновиться магритт, добавить сокрытия
                />
            )}
            <GridLayout>
                <GridRow>
                    <GridColumn xs={4} s={8} m={7} l={7} xl={7} xxl={7}>
                        {!isMobile && (
                            <>
                                <Breadcrumbs>
                                    <BreadcrumbsItem href={RESUMES_PAGE_URL}>
                                        {trls[TrlKeys.breadcrumbs.resumes]}
                                    </BreadcrumbsItem>
                                    <BreadcrumbsItem>{trlTitle}</BreadcrumbsItem>
                                </Breadcrumbs>
                                <VSpacing default={40} />
                            </>
                        )}

                        <VSpacingContainer default={28}>
                            <ApplicantResumePosition />
                            <ApplicantResumeContacts />
                            <ApplicantResumeAbout />
                            <ApplicantResumeRecommendation />
                            <ApplicantResumeAdditionalEducation />
                            <ApplicantResumeAttestationEducation />
                            <ApplicantResumeAdditional />
                            <VSpacing default={32} />
                        </VSpacingContainer>
                    </GridColumn>
                    <GridColumn xs={0} s={0} m={1} l={1} xl={1} xxl={1} />
                    <GridColumn xs={4} s={8} m={4} l={4} xl={4} xxl={4}>
                        {/* добавить сайдбар */}
                    </GridColumn>
                </GridRow>
            </GridLayout>

            <EditorCloseConfirm />
            <EditorDeleteConfirm />
            <Notifications />
        </PageLayout>
    );
});

export default ResumeView;
