import { FC } from 'react';

import { Link, Cell, CellText } from '@hh.ru/magritte-ui';

interface ResumeEducationItemProps {
    name: string;
    year: number;
    organization?: string;
    result?: string;
    link?: string;
}

const ResumeEducationItem: FC<ResumeEducationItemProps> = ({ name, year, organization, result, link }) => {
    return (
        <Cell>
            <CellText type="title">
                {link ? (
                    <Link style="neutral" target="_blank" href={link}>
                        {name}
                    </Link>
                ) : (
                    <>{name}</>
                )}
            </CellText>
            {(organization || result) && (
                <CellText type="subtitle">{[organization, result].filter(Boolean).join(', ')}</CellText>
            )}
            <CellText type="subtitle">{year}</CellText>
        </Cell>
    );
};

export default ResumeEducationItem;
