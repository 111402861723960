import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Button, TextArea } from '@hh.ru/magritte-ui';

import { selectResumeEditorOpenedAbout } from 'src/models/applicant/resume/blocks/about/edit/selectors';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

import styles from './styles.less';

const TrlKeys = {
    title: 'resume.editor.about.title',
    description: 'resume.editor.about.description',
    clear: 'resume.editor.about.clear',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        DUPLICATE: 'resume.editor.field.error.title.not-in',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        SIZE_LESS_THAN_MINIMUM: 'resume.editor.field.error.required',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
    },
};

const ResumeAboutEditor = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedAbout);

    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleSave = () => dispatch(submitResumeEditorData());

    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    const { value, onChange, invalid, errorMessage } = useResumeEditorField('skills', errorsMap);

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            extraSecondaryAction={
                <Button
                    mode="secondary"
                    style="accent"
                    size="medium"
                    onClick={() => onChange('')}
                    data-qa="resume-modal-about-clear"
                >
                    {trls[TrlKeys.clear]}
                </Button>
            }
        >
            <div className={styles.textAreaWrapper}>
                <TextArea
                    description={trls[TrlKeys.description]}
                    invalid={invalid}
                    layout="fill-both"
                    errorMessage={errorMessage}
                    value={value}
                    onChange={(e) => onChange(e.target.value)}
                />
            </div>
        </EditorModal>
    );
});

export default ResumeAboutEditor;
