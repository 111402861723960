import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { useBreakpoint } from '@hh.ru/magritte-ui';

import { selectApplicantResumeAttestationEducation } from 'src/models/applicant/resume/blocks/attestationEducation/selectors';
import { selectApplicantResumeProftest } from 'src/models/applicant/resume/blocks/proftest/selector';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import ResumeAttestationEducationEditor from 'src/pages/ResumeView/redesign/attestationEducation/Editor';
import DesktopCard from 'src/pages/ResumeView/redesign/common/ListCard/DesktopCard';
import MobileCard from 'src/pages/ResumeView/redesign/common/ListCard/MobileCard';
import ProftestCard from 'src/pages/ResumeView/redesign/proftest/Card';
import MobileCell from 'src/pages/ResumeView/redesign/proftest/Card/MobileCell';

const TrlKeys = {
    add: 'applicant.resume.attestationEducation.btn.mobile.add',
    label: 'applicant.resume.attestationEducation.label',
    listTitle: 'applicant.resume.attestationEducation.mobile.list.title',
};

const ApplicantResumeAttestationEducation = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();

    const proftest = useSelector(selectApplicantResumeProftest);
    const attestationEducation = useSelector(selectApplicantResumeAttestationEducation);

    const handleAdd = () => dispatch(openResumeEditorModal('attestationEducation'));
    const handleEdit = (index: number) => dispatch(openResumeEditorModal('attestationEducation', { index }));
    const items = useMemo(
        () =>
            attestationEducation.map(({ id, name, organization, year }) => ({
                id,
                title: name,
                subtitle: organization,
                description: year ? `${year}` : '',
            })),
        [attestationEducation]
    );

    if (isEmpty(attestationEducation) && isEmpty(proftest)) {
        return null;
    }

    if (isMobile) {
        return (
            <>
                {!isEmpty(attestationEducation) && (
                    <MobileCard
                        onAdd={handleAdd}
                        onEdit={handleEdit}
                        items={items}
                        label={trls[TrlKeys.label]}
                        addText={trls[TrlKeys.add]}
                        listTitle={trls[TrlKeys.listTitle]}
                        banner={<MobileCell proftest={proftest} />}
                        additionalCard={<ProftestCard proftest={proftest} />}
                    />
                )}

                <ResumeAttestationEducationEditor />
            </>
        );
    }

    return (
        <>
            <DesktopCard
                onAdd={handleAdd}
                onEdit={handleEdit}
                items={items}
                label={trls[TrlKeys.label]}
                banner={<ProftestCard proftest={proftest} />}
            />

            <ResumeAttestationEducationEditor />
        </>
    );
});

export default ApplicantResumeAttestationEducation;
