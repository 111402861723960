import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { Checkbox, CheckableCard, VSpacingContainer, Cell, CellText, Text, useBreakpoint } from '@hh.ru/magritte-ui';

import { defaultEmploymentOrder } from 'src/models/applicant/resume/blocks/additional/const';
import { selectResumeEditorOpenedEmployment } from 'src/models/applicant/resume/blocks/additional/edit/selectors';
import { Employment } from 'src/models/applicant/resume/blocks/additional/types';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

const FIELD_NAME = 'employment';

const TrlKeys = {
    title: 'resume.editor.employment.title',
    type: {
        full: 'resume.editor.employment.full',
        part: 'resume.editor.employment.part',
        project: 'resume.editor.employment.project',
        volunteer: 'resume.editor.employment.volunteer',
        probation: 'resume.editor.employment.probation',
    },
    commonErrors: {
        SIZE_LESS_THAN_MINIMUM: 'resume.editor.employment.error.required',
    },
};

const EmploymentEditor = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedEmployment);
    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);
    const { value, onChange, errorMessage } = useResumeEditorField(FIELD_NAME, errorsMap);

    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleSave = () => dispatch(submitResumeEditorData());

    const handleChangedEmployment = (event: ChangeEvent<HTMLInputElement>) => {
        const newType = event.target.value as Employment;

        const checked = (value || []).find((item) => item.string === newType);

        if (checked) {
            onChange(value.filter((item) => item.string !== newType));
        } else {
            onChange([...value, { string: newType }]);
        }
    };

    const { isMobile } = useBreakpoint();

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            size="standard"
            showDivider={false}
        >
            <VSpacingContainer default={12}>
                {defaultEmploymentOrder.map((employmentTypeItem) => {
                    const checked = !!(value || []).find((item) => item.string === employmentTypeItem);

                    const checkboxElement = <Checkbox checked={checked} onChange={() => undefined} />;

                    return (
                        <CheckableCard
                            key={employmentTypeItem}
                            flexible
                            borderRadius={12}
                            padding={16}
                            type="checkbox"
                            name={FIELD_NAME}
                            checked={checked}
                            value={employmentTypeItem}
                            onChange={handleChangedEmployment}
                        >
                            <Cell
                                right={!isMobile ? checkboxElement : undefined}
                                left={isMobile ? checkboxElement : undefined}
                            >
                                <CellText>{trls[TrlKeys.type[employmentTypeItem]]}</CellText>
                            </Cell>
                        </CheckableCard>
                    );
                })}
                {errorMessage && <Text style="negative">{errorMessage}</Text>}
            </VSpacingContainer>
        </EditorModal>
    );
});

export default EmploymentEditor;
