import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { selectResumeEditorOpenedPosition } from 'src/models/applicant/resume/blocks/position/edit/selectors';
import { getAvatarLetters } from 'src/models/applicant/resume/blocks/position/lib';
import {
    selectApplicantResumeFirstName,
    selectApplicantResumeGender,
    selectApplicantResumeLastName,
    selectApplicantResumePhotoUrl,
} from 'src/models/applicant/resume/blocks/position/selectors';
import { useTranslatedErrorMap } from 'src/models/applicant/resume/editor/form/errors';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';
import Avatar from 'src/pages/ResumeView/redesign/position/Card/Avatar';

import ProfessionalRoleField from 'src/pages/ResumeView/redesign/position/Editor/fields/ProfessionalRole';
import SalaryField from 'src/pages/ResumeView/redesign/position/Editor/fields/Salary';
import TitleField from 'src/pages/ResumeView/redesign/position/Editor/fields/Title';

import styles from './styles.less';

const TrlKeys = {
    title: 'resume.editor.postion.title',
    commonErrors: {
        UNKNOWN: 'resume.editor.field.error.unknown',
        REQUIRED: 'resume.editor.field.error.required',
        DUPLICATE: 'resume.editor.field.error.title.not-in',
        NOT_MATCH_REGEXP: 'resume.editor.field.error.regexp',
        SIZE_LESS_THAN_MINIMUM: 'resume.editor.field.error.required',
        LENGTH_GREATER_THAN_MAXIMUM: {
            one: 'resume.editor.field.error.max-length.one',
            some: 'resume.editor.field.error.max-length.some',
            many: 'resume.editor.field.error.max-length.many',
        },
    },
};

const ResumePositionEditor = translation(({ trls }) => {
    const dispatch = useDispatch();

    const gender = useSelector(selectApplicantResumeGender);
    const visible = useSelector(selectResumeEditorOpenedPosition);
    const photoUrl = useSelector(selectApplicantResumePhotoUrl);
    const lastName = useSelector(selectApplicantResumeLastName);
    const firstName = useSelector(selectApplicantResumeFirstName);

    const letters = getAvatarLetters(lastName, firstName);

    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleSave = () => dispatch(submitResumeEditorData());

    const errorsMap = useTranslatedErrorMap(TrlKeys.commonErrors, trls);

    return (
        <EditorModal title={trls[TrlKeys.title]} visible={visible} onSave={handleSave} onClose={handleClose}>
            <VSpacingContainer default={24}>
                <div className={styles.avatar}>
                    <Avatar path={photoUrl} letters={letters} gender={gender} />
                </div>
                <TitleField commonErrors={errorsMap} />
                <SalaryField commonErrors={errorsMap} />
                <ProfessionalRoleField commonErrors={errorsMap} />
            </VSpacingContainer>
        </EditorModal>
    );
});

export default ResumePositionEditor;
