import { translation } from '@hh.ru/front-static-app';
import { Cell, CellText } from '@hh.ru/magritte-ui';

import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import { Proftest } from 'src/models/resume/resumeCommon.types';
import { EM_DASH, NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const TrlKeys = {
    title: 'applicant.resume.proftest.title',
    headhunter: 'applicant.resume.proftest.headhunter',
    result: {
        hidden: 'applicant.resume.proftest.result.hidden',
        visible: 'applicant.resume.proftest.result.visible',
    },
};

const MobileCell = translation<{ proftest: Proftest }>(({ trls, proftest }) => {
    if (isEmpty(proftest)) {
        return null;
    }
    if (!proftest.resultsReceived) {
        return null;
    }

    const result = proftest.attached ? trls[TrlKeys.result.visible] : trls[TrlKeys.result.hidden];

    return (
        <Cell>
            <CellText>{`${trls[TrlKeys.title]}${NON_BREAKING_SPACE}${EM_DASH}${NON_BREAKING_SPACE}${result}`}</CellText>
            <CellText type="subtitle">{trls[TrlKeys.headhunter]}</CellText>
        </Cell>
    );
});

export default MobileCell;
