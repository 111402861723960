import { FC, PropsWithChildren } from 'react';
import classnames from 'classnames';

import VSpacing from 'bloko/blocks/vSpacing';

export enum InfoBlockSubstrateKind {
    Warning = 'warning',
    Primary = 'primary',
    Secondary = 'secondary',
    Tertiary = 'tertiary',
}

interface InfoBlockSubstrateProps {
    /** Тип информационного блока */
    kind?: InfoBlockSubstrateKind;
    noTopIndent?: boolean;
    slim?: boolean;
}

const InfoBlockSubstrate: FC<InfoBlockSubstrateProps & PropsWithChildren> = ({
    kind = InfoBlockSubstrateKind.Primary,
    noTopIndent = false,
    slim = false,
    children,
    ...props
}) => (
    <div {...props} className="info-block">
        {!noTopIndent && <VSpacing base={6} />}
        <div
            className={classnames('info-block-substrate', {
                'info-block-substrate_warning': kind === InfoBlockSubstrateKind.Warning,
                'info-block-substrate_primary': kind === InfoBlockSubstrateKind.Primary,
                'info-block-substrate_secondary': kind === InfoBlockSubstrateKind.Secondary,
                'info-block-substrate_tertiary': kind === InfoBlockSubstrateKind.Tertiary,
            })}
        >
            <div className={classnames('info-block-content', { 'info-block-content_slim': slim })}>{children}</div>
        </div>
    </div>
);

export default InfoBlockSubstrate;
