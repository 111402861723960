import { useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { translation } from '@hh.ru/front-static-app';
import { useBreakpoint } from '@hh.ru/magritte-ui';

import { selectApplicantResumeAdditionalEducation } from 'src/models/applicant/resume/blocks/additionalEducation/selectors';
import { openResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import { isEmpty } from 'src/models/applicant/resume/lib/common/empty';
import ResumeAdditionalEducationEditor from 'src/pages/ResumeView/redesign/additionalEducation/Editor';
import DesktopCard from 'src/pages/ResumeView/redesign/common/ListCard/DesktopCard';
import MobileCard from 'src/pages/ResumeView/redesign/common/ListCard/MobileCard';

const TrlKeys = {
    label: 'applicant.resume.additionalEducation.label',
    add: 'applicant.resume.additionalEducation.btn.mobile.add',
    listTitle: 'applicant.resume.additionalEducation.mobile.list.title',
};

const ApplicantResumeAdditionalEducation = translation(({ trls }) => {
    const dispatch = useDispatch();
    const { isMobile } = useBreakpoint();

    const additionalEducation = useSelector(selectApplicantResumeAdditionalEducation);

    const handleAdd = () => dispatch(openResumeEditorModal('additionalEducation'));
    const handleEdit = (index: number) => dispatch(openResumeEditorModal('additionalEducation', { index }));
    const items = useMemo(
        () =>
            additionalEducation.map(({ id, name, organization, year }) => ({
                id,
                title: name,
                subtitle: organization,
                description: year ? `${year}` : '',
            })),
        [additionalEducation]
    );

    if (isEmpty(additionalEducation)) {
        return null;
    }

    return (
        <>
            {isMobile ? (
                <MobileCard
                    onAdd={handleAdd}
                    onEdit={handleEdit}
                    items={items}
                    label={trls[TrlKeys.label]}
                    addText={trls[TrlKeys.add]}
                    listTitle={trls[TrlKeys.listTitle]}
                />
            ) : (
                <DesktopCard onAdd={handleAdd} onEdit={handleEdit} items={items} label={trls[TrlKeys.label]} />
            )}
            <ResumeAdditionalEducationEditor />
        </>
    );
});

export default ApplicantResumeAdditionalEducation;
