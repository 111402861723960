import { LangTrls } from '@hh.ru/front-static-app';

import { StringFieldType } from 'src/models/applicant/resume/types/common';

export const getStringListTrl = (
    list: StringFieldType[],
    trls: LangTrls,
    TrlKeys: Record<string, string>,
    moreText: string
): string => {
    if (list.length > 3) {
        return list
            .slice(0, 3)
            .map((item) => trls[TrlKeys[item.string]])
            .join(', ')
            .concat(' ', moreText, ' ', list.slice(3).length.toString());
    }

    return list.map((item) => trls[TrlKeys[item.string]]).join(', ');
};
