import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import isTomorrow from 'date-fns/isTomorrow';
import { MOVED_TEMPORARILY } from 'http-status-codes';
import PropTypes from 'prop-types';

import hhproCloseClick from '@hh.ru/analytics-js-events/build/xhh/applicant/hhpro/hhpro_resume_renewal_close_button_click';
import readMoreClick from '@hh.ru/analytics-js-events/build/xhh/applicant/hhpro/hhpro_resume_renewal_read_more_button_click';
import hhproShown from '@hh.ru/analytics-js-events/build/xhh/applicant/hhpro/hhpro_resume_renewal_screen_shown';
import { Alert, Button } from '@hh.ru/magritte-ui';
import { CrownSize24 } from '@hh.ru/magritte-ui/product';
import { usePush, Link } from '@hh.ru/redux-spa-middleware';
import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import { IntervalMessages } from 'Utils/Dates';
import translation from 'src/components/translation';
import useOnOffState from 'src/hooks/useOnOffState';
import { useSelector } from 'src/hooks/useSelector';
import { Recommendations } from 'src/models/resume/resumeCommon.types';
import { HH_PRO_LINK } from 'src/utils/constants/applicantServices';
import { humanDatesRulesType } from 'src/utils/humanDatesRules';

import TouchModule from 'src/components/ResumeUpdate/TouchModule';

const HHPRO_ALERT_SOURCE = 'hhpro_resume_renewal';

const ResumeUpdate = ({
    hash,
    humanDatesRules,
    onUpdate,
    onTimeChanged,
    RenderComponent,
    timeLeft,
    trls,
    updateInterval,
    renewalTime,
    isRenewalAvailable,
    resumeId,
    ...renderComponentProps
}) => {
    const touch = useRef();
    const dispatch = useDispatch();
    const push = usePush();
    const intervalMessages = useMemo(() => IntervalMessages(humanDatesRules), [humanDatesRules]);
    const [error, setError] = useState(false);
    const [pending, setPending] = useState(false);
    const [currentTimeLeft, setCurrentTimeLeft] = useState(timeLeft);
    const hhtmSource = useSelector((state) => state.analyticsParams.hhtmSource);
    const isAvailableHhPro = useSelector((state) => state.isAvailableHhPro);
    const [isShowAlert, openAlert, closeAlert] = useOnOffState(false);
    const recommendation = useSelector(({ applicantResumesStatistics }) =>
        resumeId ? applicantResumesStatistics?.resumes?.[resumeId]?.recommendation : null
    );

    useEffect(() => {
        setCurrentTimeLeft(timeLeft);
    }, [timeLeft]);

    const disabled = currentTimeLeft > 0;
    const message = disabled ? intervalMessages.get(currentTimeLeft) : null;
    let displayMessage = message ? format(trls[ResumeUpdate.trls.nextTouchWithRenewal], { '{0}': message }) : '';
    if (!isRenewalAvailable) {
        displayMessage = format(trls[ResumeUpdate.trls.nextTouchWithoutRenewal], { '{0}': message });
    }
    if (renewalTime?.nearestIntervalStartTime && renewalTime?.nearestIntervalEndTime) {
        let dateTrl = trls[ResumeUpdate.trls.renewalToday];
        if (isTomorrow(renewalTime.nearestIntervalStartTime)) {
            dateTrl = trls[ResumeUpdate.trls.renewalTomorrow];
        }
        displayMessage = format(trls[ResumeUpdate.trls.renewal], {
            '{0}': dateTrl,
            '{1}': formatDate(renewalTime.nearestIntervalStartTime, 'HH:mm'),
            '{2}': formatDate(renewalTime.nearestIntervalEndTime, 'HH:mm'),
        });
    }

    const doUpdate = useCallback(() => {
        if (pending) {
            return;
        }
        setError(false);
        if (!disabled && touch.current) {
            setPending(true);
            touch.current.send();
        } else if (touch.current) {
            if (isAvailableHhPro) {
                openAlert();
                hhproShown({ hhtmSource: HHPRO_ALERT_SOURCE });
            } else {
                window.open(
                    `/applicant/services/findjob?from=resume_actions&hhtmFrom=${hhtmSource}&hhtmFromLabel=resume_actions&resumeHash=${hash}`,
                    '_blank'
                );
            }
        }
    }, [disabled, hash, hhtmSource, isAvailableHhPro, openAlert, pending]);

    useEffect(() => {
        touch.current = TouchModule({
            hash,
            timeLeft,
            updateInterval,
            onError: (error) => {
                if (error.response) {
                    const redirectUrl = error.response.data?.url || null;
                    if (error.response.status === MOVED_TEMPORARILY && redirectUrl) {
                        push(redirectUrl);
                        return;
                    }
                }
                setError(true);
                setPending(false);
            },
            onSuccess: () => {
                setError(false);
                setPending(false);
                onUpdate(Date.now());
            },
            onTimeLeftChange: (timeLeft) => {
                setError(false);
                setCurrentTimeLeft(timeLeft);
                onTimeChanged(timeLeft);
            },
        });
        return touch.current.destroyHandlers;
    }, [dispatch, hash, onTimeChanged, onUpdate, push, timeLeft, updateInterval]);

    if ([Recommendations.UpdateResume, Recommendations.Respond].includes(recommendation)) {
        return null;
    }

    return (
        <>
            <RenderComponent
                disabled={disabled}
                doUpdate={doUpdate}
                error={error}
                hash={hash}
                message={displayMessage}
                renewalTime={renewalTime}
                {...renderComponentProps}
            />
            {isAvailableHhPro && (
                <Alert
                    visible={isShowAlert}
                    title={trls[ResumeUpdate.trls.alertTitle]}
                    description={trls[ResumeUpdate.trls.alertDescription]}
                    layout="vertical"
                    icon={<CrownSize24 />}
                    iconStyle="neutral"
                    buttons={
                        <>
                            <Button
                                Element={Link}
                                to={HH_PRO_LINK}
                                mode="primary"
                                onClick={() => {
                                    closeAlert();
                                    readMoreClick({ hhtmSource: HHPRO_ALERT_SOURCE });
                                }}
                            >
                                {trls[ResumeUpdate.trls.alertLink]}
                            </Button>
                            <Button
                                mode="secondary"
                                onClick={() => {
                                    closeAlert();
                                    hhproCloseClick({ hhtmSource: HHPRO_ALERT_SOURCE });
                                }}
                            >
                                {trls[ResumeUpdate.trls.alertClose]}
                            </Button>
                        </>
                    }
                    onClose={() => {
                        closeAlert();
                        hhproCloseClick({ hhtmSource: HHPRO_ALERT_SOURCE });
                    }}
                />
            )}
        </>
    );
};

ResumeUpdate.propTypes = {
    /** Хеш резюме */
    hash: PropTypes.string.isRequired,
    /** Правила человеческих дат, что бы это ни значило %) */
    humanDatesRules: humanDatesRulesType.isRequired,
    /** Коллбек, вызываемый после обновления резюме с параметром timestamp */
    onUpdate: PropTypes.func,
    /** Коллбек, вызываемый после смены времени до ближайшей возможности поднятия резюме */
    onTimeChanged: PropTypes.func,
    /** Время до следующего обновления, мс */
    timeLeft: PropTypes.number.isRequired,
    /** Переводы */
    trls: PropTypes.object.isRequired,
    /** Минимальный интервал обновления, мс */
    updateInterval: PropTypes.number.isRequired,
    /** Диапазон времени ближайшего автообновления */
    renewalTime: PropTypes.shape({
        nearestIntervalStartTime: PropTypes.number,
        nearestIntervalEndTime: PropTypes.number,
    }),
    /** Компонент для рендеринга */
    RenderComponent: PropTypes.oneOfType([PropTypes.func, PropTypes.element, PropTypes.elementType]),
    /** Пропсы компонента для рендеринга */
    renderComponentProps: PropTypes.object,
    /** Доступно ли автоподнятие в данном регионе */
    isRenewalAvailable: PropTypes.bool,
    /** id резюме для которого рисуется кнопка. Если указан и уже есть рекомендация на поднятие и продвижение - кнопка не будет рисоваться */
    resumeId: PropTypes.number,
};

ResumeUpdate.defaultProps = {
    onUpdate: () => {},
    onTimeChanged: () => {},
};

ResumeUpdate.trls = {
    nextTouch: 'myresume.publish.nextTouch',
    renewal: 'myresume.publish.renewal',
    nextTouchWithoutRenewal: 'rb.publish.nextTouch',
    nextTouchWithRenewal: 'myresume.publish.nextTouchWithRenewal',
    renewalToday: 'myresume.publish.renewal.today',
    renewalTomorrow: 'myresume.publish.renewal.tomorrow',
    alertTitle: 'myresume.publish.renewal.alert.hhPro.title',
    alertDescription: 'myresume.publish.renewal.alert.hhPro.description',
    alertLink: 'myresume.publish.renewal.alert.hhPro.link',
    alertClose: 'myresume.publish.renewal.alert.hhPro.close',
};

export default translation(ResumeUpdate);
