import { useRef } from 'react';

import employerResumeViewReportsItemClick from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-32152/employer_resume_view_reports_item_click';
import { employerResumeViewReportsItemTooltipElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/employer/experiments/PORTFOLIO-32152/employer_resume_view_reports_item_tooltip_element_shown';
import { Card, Text, TooltipHover } from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24 } from '@hh.ru/magritte-ui/icon';
import { usePush } from '@hh.ru/redux-spa-middleware';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';
import { format } from 'bloko/common/trl';

import formatDate from 'Modules/formatDate';
import { updateUrl } from 'Modules/url';
import translation from 'src/components/translation';
import { useSelectorNonNullable } from 'src/hooks/useSelector';

import styles from './styles.less';

interface ReportCardProps {
    resumeId: string;
    resumeHash: string;
    skillLevel: string;
    skillName: string;
    skillDate: string;
    skillId: number;
    index: number;
    hasVerificationReport: boolean;
}

const TrlKeys = {
    reportDate: 'skill.verification.date',
    reportTooltip: 'skill.verification.tooltip',
};

const ReportCard: TranslatedComponent<ReportCardProps> = ({
    resumeId,
    resumeHash,
    skillName,
    skillDate,
    skillLevel,
    skillId,
    index,
    hasVerificationReport,
    trls,
}) => {
    const employerId = useSelectorNonNullable((state) => state.employerId);
    const push = usePush();
    const activatorRef = useRef<HTMLDivElement>(null);
    const onAppear = () => {
        employerResumeViewReportsItemTooltipElementShownRaw({
            resumeId,
            employerId,
            position: index,
            skillName,
            skillDate,
        });
    };
    const handleClick = () => {
        employerResumeViewReportsItemClick({
            employerId,
            resumeId,
            skillName,
            skillDate,
            position: index,
        });
        // eslint-disable-next-line camelcase
        const url = updateUrl(`/resume/${resumeHash}/verification`, { skill_id: skillId, level_id: skillLevel });
        push(url);
    };

    return (
        <>
            <Card
                ref={activatorRef}
                onClick={handleClick}
                disabled={!hasVerificationReport}
                showBorder
                padding={16}
                borderRadius={16}
                actionCard
            >
                <div className={styles.card}>
                    <div>
                        <Text typography={'label-2-regular'} style={'primary'}>
                            {skillName}
                        </Text>
                        <Text typography={'label-3-regular'} style={'secondary'}>
                            {format(trls[TrlKeys.reportDate], {
                                '{date}': formatDate(skillDate, 'dd MMMM yyyy'),
                            })}
                        </Text>
                    </div>
                    <ChevronRightOutlinedSize24
                        highlighted={'secondary'}
                        disabled={'secondary'}
                        initial={hasVerificationReport ? 'primary' : 'secondary'}
                    />
                </div>
            </Card>
            {!hasVerificationReport && (
                <TooltipHover placement={'right-center'} activatorRef={activatorRef} onAppear={onAppear}>
                    {trls[TrlKeys.reportTooltip]}
                </TooltipHover>
            )}
        </>
    );
};

export default translation(ReportCard);
