import { ReactNode, useState } from 'react';

import {
    Action,
    BottomSheet,
    Button,
    Card,
    Cell,
    CellText,
    FormLabel,
    NavigationBar,
    VSpacing,
    VSpacingContainer,
} from '@hh.ru/magritte-ui';
import { ChevronRightOutlinedSize24, CrossOutlinedSize24, PlusOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

import translation from 'src/components/translation';

import styles from './styles.less';

const TrlKeys = {
    add: 'applicant.resume.block.btn.mobile.add',
    label: 'applicant.resume.recommendation.label',
    showAll: 'applicant.resume.block.btn.showAll',
    listTitle: 'applicant.resume.recommendation.mobile.list.title',
    listDescription: 'applicant.resume.recommendation.mobile.list.description',
};

type Item = { id: number; title: string; subtitle: string; description: string };
type Props = {
    label: string;
    addText: string;
    listTitle: string;
    listDescription?: string;

    items: Item[];
    onAdd: () => void;
    onEdit: (index: number) => void;
    banner?: ReactNode;
    additionalCard?: ReactNode;
};

const MAX_ITEMS = 2;

const MobileCard = translation<Props>(
    ({ trls, onAdd, onEdit, items, label, addText, listTitle, listDescription, banner, additionalCard }) => {
        const [visibility, setVisibility] = useState(false);

        const handleClose = () => setVisibility(false);
        const handleShowAll = () => setVisibility(true);

        return (
            <div>
                <div className={styles.header}>
                    <FormLabel>{label}</FormLabel>
                </div>

                <Card stretched padding={12} borderRadius={24} showBorder>
                    <div className={styles.card}>
                        {banner && banner}
                        {items.slice(0, MAX_ITEMS).map(({ id, title, subtitle, description }) => (
                            <Cell key={id}>
                                <CellText>{title}</CellText>
                                <CellText type="subtitle">{subtitle}</CellText>
                                <CellText type="description">{description}</CellText>
                            </Cell>
                        ))}
                    </div>

                    <VSpacing default={12} />
                    <Button stretched size="medium" style="neutral" mode="secondary" onClick={handleShowAll}>
                        {trls[TrlKeys.showAll]}
                    </Button>
                </Card>

                <BottomSheet
                    header={
                        <NavigationBar
                            title={listTitle}
                            right={<Action icon={CrossOutlinedSize24} onClick={handleClose} />}
                            size="large"
                            subtitle={listDescription}
                            showDivider="always"
                        />
                    }
                    visible={visibility}
                    onClose={handleClose}
                    height="full-screen"
                    showDivider="with-scroll"
                >
                    <>
                        {additionalCard && (
                            <>
                                {additionalCard}
                                <VSpacing default={12} />
                            </>
                        )}
                        <VSpacingContainer default={16}>
                            {items.map(({ id, title, subtitle, description }, index) => (
                                <Card
                                    stretched
                                    showBorder
                                    actionCard
                                    key={id}
                                    padding={16}
                                    borderRadius={16}
                                    onClick={() => onEdit(index)}
                                >
                                    <Cell align="top" right={<ChevronRightOutlinedSize24 />}>
                                        <CellText>{title}</CellText>
                                        <CellText type="subtitle">{subtitle}</CellText>
                                    </Cell>
                                    <Cell>
                                        <CellText type="description">{description}</CellText>
                                    </Cell>
                                </Card>
                            ))}
                            <Button
                                stretched
                                size="medium"
                                style="accent"
                                mode="secondary"
                                onClick={onAdd}
                                icon={<PlusOutlinedSize24 />}
                            >
                                {addText}
                            </Button>
                            <VSpacing default={24} />
                        </VSpacingContainer>
                    </>
                </BottomSheet>
            </div>
        );
    }
);

export default MobileCard;
