import { ChangeEvent } from 'react';
import { useDispatch } from 'react-redux';

import { translation, useSelector } from '@hh.ru/front-static-app';
import { VSpacingContainer, Radio, CheckableCard, Cell, CellText, useBreakpoint } from '@hh.ru/magritte-ui';

import { defaultBusinessTripReadinessOrder } from 'src/models/applicant/resume/blocks/additional/const';
import { selectResumeEditorOpenedBusinessTripReadiness } from 'src/models/applicant/resume/blocks/additional/edit/selectors';
import { BusinessTripReadiness } from 'src/models/applicant/resume/blocks/additional/types';
import { useResumeEditorField } from 'src/models/applicant/resume/editor/form/change';
import { submitResumeEditorData } from 'src/models/applicant/resume/editor/store/actions/submitEditor';
import { closeResumeEditorModal } from 'src/models/applicant/resume/editor/store/actions/toggleModal';
import EditorModal from 'src/pages/ResumeView/redesign/common/EditorModal';

const FIELD_NAME = 'businessTripReadiness';

const TrlKeys = {
    title: 'resume.editor.businessTripReadiness.title',
    type: {
        never: 'resume.editor.businessTripReadiness.never',
        ready: 'resume.editor.businessTripReadiness.ready',
        sometimes: 'resume.editor.businessTripReadiness.sometimes',
    },
};

const BusinessTripReadinessEditor = translation(({ trls }) => {
    const dispatch = useDispatch();

    const visible = useSelector(selectResumeEditorOpenedBusinessTripReadiness);
    const { value, onChange } = useResumeEditorField(FIELD_NAME);

    const handleClose = () => dispatch(closeResumeEditorModal());
    const handleSave = () => dispatch(submitResumeEditorData());

    const handleChangedBusinessTripReadiness = (event: ChangeEvent<HTMLInputElement>) => {
        onChange(event.target.value as BusinessTripReadiness);
    };

    const { isMobile } = useBreakpoint();

    return (
        <EditorModal
            title={trls[TrlKeys.title]}
            visible={visible}
            onSave={handleSave}
            onClose={handleClose}
            size="standard"
            showDivider={false}
        >
            <VSpacingContainer default={12}>
                {defaultBusinessTripReadinessOrder.map((businessTripReadinessTypeItem) => {
                    const checked = businessTripReadinessTypeItem === value;

                    const radioElement = <Radio checked={checked} onChange={() => undefined} />;

                    return (
                        <CheckableCard
                            key={businessTripReadinessTypeItem}
                            flexible
                            borderRadius={12}
                            padding={16}
                            type="radio"
                            name={FIELD_NAME}
                            checked={checked}
                            value={businessTripReadinessTypeItem}
                            onChange={handleChangedBusinessTripReadiness}
                        >
                            <Cell
                                left={!isMobile ? radioElement : undefined}
                                right={isMobile ? radioElement : undefined}
                            >
                                <CellText>{trls[TrlKeys.type[businessTripReadinessTypeItem]]}</CellText>
                            </Cell>
                        </CheckableCard>
                    );
                })}
            </VSpacingContainer>
        </EditorModal>
    );
});

export default BusinessTripReadinessEditor;
