import format from 'date-fns/format';
import AZ from 'date-fns/locale/az';
import EN from 'date-fns/locale/en-US';
import KZ from 'date-fns/locale/kk';
import RU from 'date-fns/locale/ru';
import UA from 'date-fns/locale/uk';
import UZ from 'date-fns/locale/uz';
import parseISO from 'date-fns/parseISO';

import { DateType } from '@hh.ru/magritte-ui';

export const DEFAULT_LOCALE = (typeof window !== 'undefined' && window.globalVars?.lang) || 'RU';
const LOCALES = { AZ, EN, KZ, RU, UA, UZ, KG: RU };

export const getLocale = (): Locale => LOCALES[DEFAULT_LOCALE];

export const formatDate = (date: string | Date | number, formatString: string, lang = DEFAULT_LOCALE): string =>
    format(typeof date === 'string' ? parseISO(date) : date, formatString, {
        locale: LOCALES[lang],
    });

export const dateFromDateTypeToFormFormat = (dateType: DateType): string => {
    const newDate = new Date(dateType);
    const day = `0${newDate.getDate()}`.slice(-2);
    const month = `0${newDate.getMonth() + 1}`.slice(-2);
    const year = `000${newDate.getFullYear()}`.slice(-4);
    return `${day}.${month}.${year}`;
};
