import { useDispatch } from 'react-redux';

import { useSelector } from '@hh.ru/front-static-app';

import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';
import { makeErrorMessage } from 'src/models/applicant/resume/editor/form/errors/lib';
import { selectResumeEditorErrors } from 'src/models/applicant/resume/editor/store/selectors';
import {
    setResumeEditorFieldValue,
    clearResumeEditorErrorByNamePath,
} from 'src/models/applicant/resume/editor/store/slice';

type FieldData = {
    value: string;
    invalid: boolean;
    onChange: (value: string, resetError?: boolean) => void;
    errorMessage: string | null | undefined;
};

export const useResumeEditorPersonalSite = (key: 'personalSite', index: number, errorsMap: ErrorsMap): FieldData => {
    const dispatch = useDispatch();

    const formErrors = useSelector(selectResumeEditorErrors);
    const namePath = `/${key}/${index}/url`;

    const fields = useSelector(({ resumeEditor }) => resumeEditor.fields[key]);
    const field = fields?.[index];
    const fieldFormErrors = formErrors?.[key] || [];

    const errors = makeErrorMessage(fieldFormErrors, errorsMap);
    const error = errors.find(({ fieldNamePath }) => fieldNamePath === namePath);

    const onChange = (value: string, resetError = true) => {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        dispatch(
            setResumeEditorFieldValue({
                [key]: fields?.map((field, idx) => {
                    if (idx === index) {
                        return {
                            ...field,
                            url: value,
                        };
                    }

                    return field;
                }),
            })
        );
        if (resetError) {
            dispatch(clearResumeEditorErrorByNamePath({ key, namePath }));
        }
    };

    const value = field?.url as string;

    return {
        value,
        onChange,
        invalid: !!error,
        errorMessage: error?.message,
    };
};
