import { ComponentType } from 'react';

import { VSpacingContainer } from '@hh.ru/magritte-ui';

import { ErrorsMap } from 'src/models/applicant/resume/editor/form/errors';

import PersonalSiteAdd from 'src/pages/ResumeView/redesign/contacts/Editor/PersonalSite/Add';
import PersonalSiteFieldList from 'src/pages/ResumeView/redesign/contacts/Editor/PersonalSite/FieldList';

const PersonalSite: ComponentType<{ errorsMap: ErrorsMap }> = ({ errorsMap }) => {
    return (
        <VSpacingContainer default={24}>
            <PersonalSiteFieldList errorsMap={errorsMap} />
            <PersonalSiteAdd />
        </VSpacingContainer>
    );
};

export default PersonalSite;
