import { createRef, PureComponent } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import pfpHintIconElementShown from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/pfp_hint_icon_element_shown';
import { pfpHintTipElementShownRaw } from '@hh.ru/analytics-js-events/build/xhh/employer/pfp/pfp_hint_tip_element_shown';
import { HoverTip } from 'bloko/blocks/drop';
import { TipLayer, TipPlacement } from 'bloko/blocks/drop/Tip';
import { H1 } from 'bloko/blocks/header';
import { QuestionMarkScaleMedium } from 'bloko/blocks/icon';
import Text, { TextImportance } from 'bloko/blocks/text';
import VSpacing from 'bloko/blocks/vSpacing';

import ElementShownAnchor from 'src/components/ElementShownAnchor';
import AsyncHighlighter from 'src/components/Markup/AsyncHighlighter';
import PfpVacancyTip from 'src/components/PfpVacancyTip';
import translation from 'src/components/translation';
import { NAMES_AND_PHOTO } from 'src/utils/constants/resumeHiddenFields';
import { EDIT, VIEW_WITHOUT_CONTACTS } from 'src/utils/constants/resumePermission';

import styles from './resume-name.less';

const renderTip = () => <PfpVacancyTip />;

class ResumeName extends PureComponent {
    activatorRef = createRef();

    renderCandidateHeader() {
        const { trls, isAllowed, hasPfpTopics, id, vacancyId, t } = this.props;
        if (isAllowed) {
            return null;
        }
        return (
            <>
                <H1 Element="h2">
                    {trls[ResumeName.trls[hasPfpTopics ? 'pfpCandidate' : 'candidate']]}
                    {hasPfpTopics && (
                        <HoverTip
                            onHover={() => pfpHintTipElementShownRaw({ resumeId: id, topicId: t, vacancyId })}
                            flexible
                            host={!process.env.SSR ? document.body : null}
                            placement={TipPlacement.Bottom}
                            layer={TipLayer.Overlay}
                            render={renderTip}
                            activatorRef={this.activatorRef}
                        >
                            <span className={styles.icon} ref={this.activatorRef}>
                                <ElementShownAnchor
                                    fn={pfpHintIconElementShown}
                                    Element="span"
                                    resumeId={id}
                                    vacancyId={vacancyId}
                                    topicId={t}
                                >
                                    <QuestionMarkScaleMedium />
                                </ElementShownAnchor>
                            </span>
                        </HoverTip>
                    )}
                </H1>
                <VSpacing base={4} />
            </>
        );
    }

    renderName() {
        const { isAllowed, firstName, middleName, lastName } = this.props;
        if (isAllowed && (firstName || middleName || lastName)) {
            const name = [lastName, firstName, middleName].filter((item) => !!item).join(' ');
            return (
                <div className="resume-header-name">
                    <H1 Element="h2" data-qa="resume-personal-name">
                        <AsyncHighlighter>{name}</AsyncHighlighter>
                    </H1>
                    <VSpacing base={4} />
                </div>
            );
        }
        return this.renderCandidateHeader();
    }

    renderHidden() {
        const { trls } = this.props;
        return (
            <div className="resume-hidden-field resume-hidden-field_name">
                <Text Element="span" importance={TextImportance.Tertiary}>
                    {trls[ResumeName.trls.isHidden]}
                </Text>
            </div>
        );
    }

    render() {
        const { isHidden, canEdit, isEmpty } = this.props;
        if (isEmpty) {
            return this.renderCandidateHeader();
        }
        if (isHidden && !canEdit) {
            return this.renderHidden();
        }
        return this.renderName();
    }
}

ResumeName.trls = {
    isHidden: 'resume.hiddenFields.name',
    candidate: 'resume.stub.candidate',
    pfpCandidate: 'resume.stub.candidatePfp',
    pfpVacancyHint: 'resume.stub.pfpVacancyHint',
};

ResumeName.propTypes = {
    trls: PropTypes.object.isRequired,
    isHidden: PropTypes.bool.isRequired,
    isAllowed: PropTypes.bool.isRequired,
    isEmpty: PropTypes.bool,
    firstName: PropTypes.string,
    middleName: PropTypes.string,
    lastName: PropTypes.string,
    canEdit: PropTypes.bool,
    hasPfpTopics: PropTypes.bool,
    id: PropTypes.string,
    vacancyId: PropTypes.string,
    t: PropTypes.string,
};

export default connect((state) => ({
    isHidden: state.resume?.hiddenFields.includes(NAMES_AND_PHOTO),
    isAllowed: state.resume?.permission !== VIEW_WITHOUT_CONTACTS,
    isEmpty: state.resume?.emptyBlocks.includes('personal'),
    canEdit: state.resume?.permission === EDIT,
    firstName: state.resume?.firstName.value,
    middleName: state.resume?.middleName.value,
    lastName: state.resume?.lastName.value,
    hasPfpTopics: state.resume?.hasPfpTopics,
    id: state.resume?.id,
    vacancyId: state.router.location.query.vacancyId,
    t: state.router.location.query.t,
}))(translation(ResumeName));
