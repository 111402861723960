import { ComponentType } from 'react';

import { Cell, CellText } from '@hh.ru/magritte-ui';

import EditIcon from 'src/pages/ResumeView/redesign/common/EditIcon';

type Props = {
    title: string;
    text: string;
    onClick: () => void;
};

const CardCell: ComponentType<Props> = ({ title, text, onClick }) => {
    return (
        <Cell right={<EditIcon onClick={onClick} />}>
            <CellText type="subtitle">{title}</CellText>
            <CellText>{text}</CellText>
        </Cell>
    );
};

export default CardCell;
