import { useRef } from 'react';
import PropTypes from 'prop-types';

import ClickMenu, { MenuItem, MenuPlacement } from 'bloko/blocks/drop/Menu/Click';
import { FormSpacer } from 'bloko/blocks/form';
import Link, { LinkAppearance } from 'bloko/blocks/link';

import { MarkAsViewedEvent } from 'Modules/Notices';
import Infotip, { InfoPlacement } from 'src/components/Infotip';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { NON_BREAKING_SPACE } from 'src/utils/constants/symbols';

const DivisionSelector = ({ currentDivisionId, setDivisionId, trls }) => {
    const employerDivisions = useSelector((state) => state.employerDivisions);
    const activatorRef = useRef(null);

    return (
        <FormSpacer>
            {trls[DivisionSelector.trls.division]}
            {NON_BREAKING_SPACE}
            <Infotip
                show
                name="resumeDivisionCiv"
                render={() => trls[DivisionSelector.trls.description]}
                placement={InfoPlacement.TopStart}
                markAsViewedEvent={MarkAsViewedEvent.OnHide}
                activatorRef={activatorRef}
            >
                <ClickMenu
                    flexible
                    placement={MenuPlacement.BottomStart}
                    render={(handleClose) =>
                        Object.values(employerDivisions).map(({ divisionId, divisionName, servicesInfo }) => (
                            <MenuItem
                                key={divisionId}
                                selectable
                                selected={divisionId === currentDivisionId}
                                onClick={() => {
                                    setDivisionId(divisionId);
                                    handleClose();
                                }}
                            >
                                <div className="resume-contacts-division-name">{divisionName}</div>
                                <div className="resume-contacts-division-civ">
                                    {servicesInfo.availableCiv}
                                    {NON_BREAKING_SPACE}
                                    {trls[DivisionSelector.trls.civ]}
                                </div>
                            </MenuItem>
                        ))
                    }
                    activatorRef={activatorRef}
                >
                    <Link appearance={LinkAppearance.Pseudo} ref={activatorRef}>
                        {employerDivisions[currentDivisionId].divisionName}
                    </Link>
                </ClickMenu>
            </Infotip>
        </FormSpacer>
    );
};

DivisionSelector.propTypes = {
    currentDivisionId: PropTypes.number,
    setDivisionId: PropTypes.func,
    trls: PropTypes.object,
};

DivisionSelector.trls = {
    division: 'employer.resume.showContacts.division',
    civ: 'employer.resume.showContacts.division.civ',
    description: 'employer.resume.showContacts.division.description',
};

export default translation(DivisionSelector);
