import { ComponentType } from 'react';

import { Action, useBreakpoint } from '@hh.ru/magritte-ui';
import { PenOutlinedSize24 } from '@hh.ru/magritte-ui/icon';

interface Props {
    onClick: VoidFunction;
}

const EditIcon: ComponentType<Props> = ({ onClick }) => {
    const { isMobile } = useBreakpoint();
    if (isMobile) {
        return null;
    }

    return (
        <div>
            <Action
                mode="secondary"
                style="neutral"
                disablePadding
                showBackground={false}
                onClick={onClick}
                icon={PenOutlinedSize24}
            />
        </div>
    );
};

export default EditIcon;
