import { useRef, useState } from 'react';
import { Field, Form as FinalForm } from 'react-final-form';

import { Button, Link, TooltipHover, VSpacing } from '@hh.ru/magritte-ui';
import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import CheckablePlate from 'src/components/CheckablePlate/CheckablePlate';
import Form from 'src/components/Form';
import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';

import TextareaField from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment/TextareaField';
import { useSendCommentSubmit } from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment/api/useSendCommentSubmit';
import { HiringManagerCommentVerdict } from 'src/pages/ResumeView/components/View/ResumeSidebar/Comments/HiringManagerComment/types';

import styles from './index.less';

const TrlKeys = {
    add: 'resume.comments.hiringManager.add',
    submit: 'resume.comments.hiringManager.submit',
    cancel: 'resume.comments.hiringManager.cancel',
    accept: 'resume.comments.hiringManager.accept',
    reject: 'resume.comments.hiringManager.reject',
    candidateAccepted: 'resume.comments.hiringManager.candidateAccepted',
    candidateRejected: 'resume.comments.hiringManager.candidateRejected',
    tooltip: 'resume.comments.hiringManager.tooltip',
};

const VERDICTS = ['accept', 'reject'];

const HiringManagerComment: TranslatedComponent = ({ trls }) => {
    const activatorRef = useRef<HTMLDivElement>(null);
    const [isExpanded, setIsExpanded] = useState(true);
    const { vacancyId, t: topicId } = useSelector((state) => state.router.location.query);
    const resumeHash = useSelector((state) => state.resume?.hash);

    const handleSubmit = useSendCommentSubmit({ trls, setIsExpanded, vacancyId, topicId, resumeHash });

    if (!isExpanded) {
        return (
            <Link
                onClick={(e) => {
                    e.preventDefault();
                    setIsExpanded(true);
                }}
                typography="label-2-regular"
                style="accent"
            >
                {trls[TrlKeys.add]}
            </Link>
        );
    }

    return (
        <FinalForm onSubmit={handleSubmit}>
            {({ handleSubmit, form, submitting, values }) => (
                <Form onSubmit={handleSubmit}>
                    <Field<HiringManagerCommentVerdict> name="verdict">
                        {({ input }) => {
                            return (
                                <>
                                    <div className={styles.radiosWrapper} ref={activatorRef}>
                                        {VERDICTS.map((verdict) => (
                                            <CheckablePlate
                                                key={verdict}
                                                type="checkbox"
                                                name={input.name}
                                                value={verdict}
                                                checked={input.value === verdict}
                                                onChange={(event) => {
                                                    const val = event.target.value;
                                                    return input.onChange(val === input.value ? null : val);
                                                }}
                                            >
                                                {trls[TrlKeys[verdict as 'accept' | 'reject']]}
                                            </CheckablePlate>
                                        ))}
                                    </div>
                                    <TooltipHover placement="left-bottom" activatorRef={activatorRef}>
                                        {trls[TrlKeys.tooltip]}
                                    </TooltipHover>
                                </>
                            );
                        }}
                    </Field>
                    <VSpacing default={16} />
                    <TextareaField verdict={values.verdict} />
                    <VSpacing default={16} />
                    <div className={styles.buttonsWrapper}>
                        <Button mode="primary" size="small" style="accent" type="submit" loading={submitting}>
                            {trls[TrlKeys.submit]}
                        </Button>
                        <Button
                            mode="tertiary"
                            size="small"
                            style="neutral"
                            onClick={() => {
                                form.restart();
                                setIsExpanded(false);
                            }}
                            loading={submitting}
                        >
                            {trls[TrlKeys.cancel]}
                        </Button>
                    </div>
                </Form>
            )}
        </FinalForm>
    );
};

export default translation(HiringManagerComment);
