import getYmapsPromise from 'bloko/common/getYmapsPromise';

const COUNTRY_UA = '2';
const defaultLocale = 'ru_RU';
// Locales supported by Yandex.Maps API
const supportedLocales = ['ru_RU', 'en_US', 'en_RU', 'ru_UA', 'uk_UA', 'tr_TR'];
const country = window.globalVars.country;
let locale = window.globalVars.locale;
// Workaround for en_RU locale on hh.ua
if (country === COUNTRY_UA && locale === 'en_RU') {
    locale = 'uk_UA';
}
export default () =>
    getYmapsPromise({
        lang: supportedLocales.indexOf(locale) > -1 ? locale : defaultLocale,
        apiKey: window.globalVars?.features?.ymaps_api_key || 'bd2ce8bf-561f-4940-be08-6e87317d767b',
        suggestApiKey: window.globalVars?.features?.ymaps_suggest_api_key || 'b9e3758b-e8d8-4f3b-ae7e-92c27aea7f3a',
    }).catch((error) => Promise.reject(error));
