import { TranslatedComponent } from 'bloko/common/hooks/useTranslations';

import translation from 'src/components/translation';
import { useSelector } from 'src/hooks/useSelector';
import { isQuestionRendered, isAnyQuestionRendered } from 'src/pages/ResumeView/components/CredsView/utils';
import ResumeBlock from 'src/pages/ResumeView/employerRedesign/components/ResumeBlock';
import ResumeTagsContainer from 'src/pages/ResumeView/employerRedesign/components/ResumeTagsContainer';

import ResumeCredsItem from 'src/pages/ResumeView/employerRedesign/components/ResumeCreds/ResumeCredsItem';

const TrlKeys = {
    title: 'rb.title.creds',
};

const ResumeCreds: TranslatedComponent = ({ trls }) => {
    const creds = useSelector(({ resume }) => resume?.creds);

    if (!creds || !isAnyQuestionRendered(creds)) {
        return null;
    }

    return (
        <ResumeBlock title={trls[TrlKeys.title]} data-qa="resume-block-creds">
            <ResumeTagsContainer>
                {Object.entries(creds.questionToAnswerMap).map(([questionId, answers]) => {
                    if (!isQuestionRendered(answers, creds)) {
                        return null;
                    }

                    return <ResumeCredsItem key={questionId} creds={creds} questionId={questionId} answers={answers} />;
                })}
            </ResumeTagsContainer>
        </ResumeBlock>
    );
};

export default translation(ResumeCreds);
