export function isArrayChangedByFn<A, B>(
    arrA: Array<A> = [],
    arrB: Array<B> = [],
    comporator: (a: A, b: B) => boolean
): boolean {
    if (arrA.length !== arrB.length) {
        return true;
    }
    return arrA.some((a) => arrB.findIndex((b) => comporator(a, b)) < 0);
}
