export enum ApplicantServices {
    CompleteResume = 'COMPLETE_RESUME',
    CompleteResumeConsultOnly = 'COMPLETE_RESUME_CONSULT_ONLY',
    CompleteResumeInterviewPractice = 'COMPLETE_RESUME_INTERVIEW_PRACTICE',
    CareerGuidance = 'CAREER_GUIDANCE',
    ResumeRenewal = 'RESUME_RENEWAL',
    ResumeMark = 'RESUME_MARK',
    ResumeFastVacancies = 'RESUME_FAST_VACANCIES',
    TargetAgencies = 'TARGET_AGENCIES',
    ResumeTargetEmployer = 'RESUME_TARGET_EMPLOYER',
    VacancyResponsesSummary = 'VACANCY_RESPONSES_SUMMARY',
}
